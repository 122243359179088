import ADD_AVATAR from '../../../public/assets/icons/add-avatar.svg'
import ADD_CIRCLE from '../../../public/assets/icons/add-circle.svg'
import ADD from '../../../public/assets/icons/add.svg'
import ARROW_DOWN from '../../../public/assets/icons/arrow-down.svg'
import ARROW_LEFT from '../../../public/assets/icons/arrow-left.svg'
import ARROW_RIGHT from '../../../public/assets/icons/arrow-right.svg'
import ARROW_UP from '../../../public/assets/icons/arrow-up.svg'
import AUDIO from '../../../public/assets/icons/audio.svg'
import BOOK from '../../../public/assets/icons/book.svg'
import BOOK2 from '../../../public/assets/icons/book2.svg'
import BOOKMARK from '../../../public/assets/icons/bookmark.svg'
import CALENDAR from '../../../public/assets/icons/calendar.svg'
import CALENDAR2 from '../../../public/assets/icons/calendar2.svg'
import CARD from '../../../public/assets/icons/card.svg'
import CATEGORY from '../../../public/assets/icons/category.svg'
import CIRCLE_X from '../../../public/assets/icons/circle-x.svg'
import CLIPBOARD from '../../../public/assets/icons/clipboard.svg'
import CLOSE from '../../../public/assets/icons/close.svg'
import CODE from '../../../public/assets/icons/code.svg'
import COURSE_BOOK from '../../../public/assets/icons/course-book.svg'
import COURSE_CARD from '../../../public/assets/icons/course-card.svg'
import COURSE_CHECK from '../../../public/assets/icons/course-check.svg'
import COURSE_FREE from '../../../public/assets/icons/course-free.svg'
import CROWN from '../../../public/assets/icons/crown.svg'
import DOCUMENT_TEXT from '../../../public/assets/icons/document-text.svg'
import DOWNLOAD from '../../../public/assets/icons/download.svg'
import ERROR_CROSS from '../../../public/assets/icons/error-cross.svg'
import FACEBOOK from '../../../public/assets/icons/facebook.svg'
import FIRE from '../../../public/assets/icons/fire.svg'
import INFO_CIRCLE_GREEN from '../../../public/assets/icons/info-circle-green.svg'
import INFO_CIRCLE_WARN from '../../../public/assets/icons/info-circle-warn.svg'
import INFO_CIRCLE from '../../../public/assets/icons/info-circle.svg'
import INFORMATION from '../../../public/assets/icons/information.svg'
import INSTAGRAM from '../../../public/assets/icons/instagram.svg'
import LEFT from '../../../public/assets/icons/left.svg'
import LOGO from '../../../public/assets/icons/logo.svg'
import LOGO_PRO from '../../../public/assets/icons/logo_pro.svg'
import LOGO2 from '../../../public/assets/icons/logo2.svg'
import LOGO3 from '../../../public/assets/icons/logo3.svg'
import LOGO4 from '../../../public/assets/icons/logo4.svg'
import LONG_RIGHT_ARROW from '../../../public/assets/icons/long-right-arrow.svg'
import MC from '../../../public/assets/icons/mc.svg'
import MIR from '../../../public/assets/icons/mir.svg'
import MS from '../../../public/assets/icons/ms.svg'
import MUTE from '../../../public/assets/icons/mute.svg'
import NOTE from '../../../public/assets/icons/note.svg'
import ORDER_MAESTRO from '../../../public/assets/icons/order-maestro.svg'
import ORDER_MASTER from '../../../public/assets/icons/order-master.svg'
import ORDER_MIR from '../../../public/assets/icons/order-mir.svg'
import ORDER_VISA from '../../../public/assets/icons/order-visa.svg'
import ORDER_INSTALLMENT from '../../../public/assets/icons/order-installment.svg'
import PAYMENT_CARD_AMERICANEXPRESS from '../../../public/assets/icons/payment-card-americanExpress.svg'
import PAYMENT_CARD_MAESTRO from '../../../public/assets/icons/payment-card-maestro.svg'
import PAYMENT_CARD_MASTERCARD from '../../../public/assets/icons/payment-card-mastercard.svg'
import PAYMENT_CARD_MIR from '../../../public/assets/icons/payment-card-mir.svg'
import PAYMENT_CARD_UNIONPAY from '../../../public/assets/icons/payment-card-unionPay.svg'
import PAYMENT_CARD_VISA from '../../../public/assets/icons/payment-card-visa.svg'
import PLAY_RENEW from '../../../public/assets/icons/play-renew.svg'
import PLAY from '../../../public/assets/icons/play.svg'
import RADIO from '../../../public/assets/icons/radio.svg'
import RIGHT from '../../../public/assets/icons/right.svg'
import SEARCH from '../../../public/assets/icons/search.svg'
import SEC_MC from '../../../public/assets/icons/sec-mc.svg'
import SEC_MIR from '../../../public/assets/icons/sec-mir.svg'
import SEC_VS from '../../../public/assets/icons/sec-vs.svg'
import SEC_VSE from '../../../public/assets/icons/sec-vse.svg'
import SHOW from '../../../public/assets/icons/show.svg'
import SUCCESS_TICK_COLOR from '../../../public/assets/icons/success-tick-color.svg'
import SUCCESS_TICK from '../../../public/assets/icons/success-tick.svg'
import TEACHER from '../../../public/assets/icons/teacher.svg'
import TELEGRAM from '../../../public/assets/icons/telegram.svg'
import TOAST_ICON_ERROR from '../../../public/assets/icons/toast-icon-error.svg'
import TOAST_ICON_SUCCESS from '../../../public/assets/icons/toast-icon-success.svg'
import TOAST_ICON_WARN from '../../../public/assets/icons/toast-icon-warn.svg'
import TRASH from '../../../public/assets/icons/trash.svg'
import TYPE_AMERICANEXPRESS from '../../../public/assets/icons/type-americanExpress.svg'
import TYPE_MAESTRO from '../../../public/assets/icons/type-maestro.svg'
import TYPE_MASTERCARD from '../../../public/assets/icons/type-mastercard.svg'
import TYPE_MIR from '../../../public/assets/icons/type-mir.svg'
import TYPE_UNIONPAY from '../../../public/assets/icons/type-unionPay.svg'
import TYPE_VISA from '../../../public/assets/icons/type-visa.svg'
import VK from '../../../public/assets/icons/vk.svg'
import VS from '../../../public/assets/icons/vs.svg'
import YOUTUBE from '../../../public/assets/icons/youtube.svg'

export const IconPack = {
  'add-avatar': ADD_AVATAR,
  'add-circle': ADD_CIRCLE,
  add: ADD,
  'arrow-down': ARROW_DOWN,
  'arrow-left': ARROW_LEFT,
  'arrow-right': ARROW_RIGHT,
  'arrow-up': ARROW_UP,
  audio: AUDIO,
  book: BOOK,
  book2: BOOK2,
  bookmark: BOOKMARK,
  calendar: CALENDAR,
  calendar2: CALENDAR2,
  card: CARD,
  category: CATEGORY,
  'circle-x': CIRCLE_X,
  clipboard: CLIPBOARD,
  close: CLOSE,
  code: CODE,
  'course-book': COURSE_BOOK,
  'course-card': COURSE_CARD,
  'course-check': COURSE_CHECK,
  'course-free': COURSE_FREE,
  crown: CROWN,
  'document-text': DOCUMENT_TEXT,
  download: DOWNLOAD,
  'error-cross': ERROR_CROSS,
  facebook: FACEBOOK,
  fire: FIRE,
  'info-circle-green': INFO_CIRCLE_GREEN,
  'info-circle-warn': INFO_CIRCLE_WARN,
  'info-circle': INFO_CIRCLE,
  information: INFORMATION,
  instagram: INSTAGRAM,
  left: LEFT,
  logo: LOGO,
  logo_pro: LOGO_PRO,
  logo2: LOGO2,
  logo3: LOGO3,
  logo4: LOGO4,
  'long-right-arrow': LONG_RIGHT_ARROW,
  mc: MC,
  mir: MIR,
  ms: MS,
  mute: MUTE,
  note: NOTE,
  'order-maestro': ORDER_MAESTRO,
  'order-master': ORDER_MASTER,
  'order-mir': ORDER_MIR,
  'order-visa': ORDER_VISA,
  'order-installment': ORDER_INSTALLMENT,
  'payment-card-americanExpress': PAYMENT_CARD_AMERICANEXPRESS,
  'payment-card-maestro': PAYMENT_CARD_MAESTRO,
  'payment-card-mastercard': PAYMENT_CARD_MASTERCARD,
  'payment-card-mir': PAYMENT_CARD_MIR,
  'payment-card-unionPay': PAYMENT_CARD_UNIONPAY,
  'payment-card-visa': PAYMENT_CARD_VISA,
  'play-renew': PLAY_RENEW,
  play: PLAY,
  radio: RADIO,
  right: RIGHT,
  search: SEARCH,
  'sec-mc': SEC_MC,
  'sec-mir': SEC_MIR,
  'sec-vs': SEC_VS,
  'sec-vse': SEC_VSE,
  show: SHOW,
  'success-tick-color': SUCCESS_TICK_COLOR,
  'success-tick': SUCCESS_TICK,
  teacher: TEACHER,
  telegram: TELEGRAM,
  'toast-icon-error': TOAST_ICON_ERROR,
  'toast-icon-success': TOAST_ICON_SUCCESS,
  'toast-icon-warn': TOAST_ICON_WARN,
  trash: TRASH,
  'type-americanExpress': TYPE_AMERICANEXPRESS,
  'type-maestro': TYPE_MAESTRO,
  'type-mastercard': TYPE_MASTERCARD,
  'type-mir': TYPE_MIR,
  'type-unionPay': TYPE_UNIONPAY,
  'type-visa': TYPE_VISA,
  vk: VK,
  vs: VS,
  youtube: YOUTUBE,
}

export type IconPackType =
  | 'add-avatar'
  | 'add-circle'
  | 'add'
  | 'arrow-down'
  | 'arrow-left'
  | 'arrow-right'
  | 'arrow-up'
  | 'audio'
  | 'book'
  | 'book2'
  | 'bookmark'
  | 'calendar'
  | 'calendar2'
  | 'card'
  | 'category'
  | 'circle-x'
  | 'clipboard'
  | 'close'
  | 'code'
  | 'course-book'
  | 'course-card'
  | 'course-check'
  | 'course-free'
  | 'crown'
  | 'document-text'
  | 'download'
  | 'error-cross'
  | 'facebook'
  | 'fire'
  | 'info-circle-green'
  | 'info-circle-warn'
  | 'info-circle'
  | 'information'
  | 'instagram'
  | 'left'
  | 'logo'
  | 'logo_pro'
  | 'logo2'
  | 'logo3'
  | 'logo4'
  | 'long-right-arrow'
  | 'mc'
  | 'mir'
  | 'ms'
  | 'mute'
  | 'note'
  | 'order-maestro'
  | 'order-master'
  | 'order-mir'
  | 'order-visa'
  | 'order-installment'
  | 'payment-card-americanExpress'
  | 'payment-card-maestro'
  | 'payment-card-mastercard'
  | 'payment-card-mir'
  | 'payment-card-unionPay'
  | 'payment-card-visa'
  | 'play-renew'
  | 'play'
  | 'radio'
  | 'right'
  | 'search'
  | 'sec-mc'
  | 'sec-mir'
  | 'sec-vs'
  | 'sec-vse'
  | 'show'
  | 'success-tick-color'
  | 'success-tick'
  | 'teacher'
  | 'telegram'
  | 'toast-icon-error'
  | 'toast-icon-success'
  | 'toast-icon-warn'
  | 'trash'
  | 'type-americanExpress'
  | 'type-maestro'
  | 'type-mastercard'
  | 'type-mir'
  | 'type-unionPay'
  | 'type-visa'
  | 'vk'
  | 'vs'
  | 'youtube'
