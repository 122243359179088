import { AxiosError, AxiosResponse } from 'axios'
import { useMutation, useQuery } from 'react-query'
import { useTranslation } from 'next-i18next'
import { api } from '../utils/http'
import { IError } from './error'
import { notify, ToastIcon } from '../components/Toast'
import { ALERT_CLOSE_WAIT } from '../constants'

export type TProfile = {
  isMailListConsented: boolean
  customer: {
    uuid?: string // временно
    firstName: string | null
    lastName: string | null
    premium: {
      isActive: boolean
      isSubscribed: boolean
      expiringAt: string | null
      canUnsubscribe: boolean
      unsubscribeReason: number | null
      currentPayment: {
        code: string
        status: number
      } | null
    }
  }
  phone: number | null
  isPhoneConfirmed: boolean
  email: string
}

export type TSubscribe = {
  success: boolean
}

export type TProfileBody = {
  headers: { [key: string]: string }
  body: {
    firstName: string | null
    lastName: string | null
    phone: string | null
  }
}

const profileApi = {
  getProfile: (headers: { [key: string]: string }): Promise<TProfile> => {
    return api.get('/api/profile', { headers }).then(res => res.data)
  },
  postSubscribeMailing: (
    headers: { [key: string]: string },
    check: boolean,
  ): Promise<AxiosResponse<TSubscribe>> => {
    return api.post(
      '/api/profile/subscribe/mailing',
      { value: check },
      { headers },
    )
  },
  postProfile: (payload: TProfileBody): Promise<AxiosResponse<TProfile>> => {
    return api.post(
      `/api/profile`,
      {
        ...payload.body,
      },
      { headers: payload.headers },
    )
  },
}

export const useProfileQuery = {
  useGetProfile: (headers: { [key: string]: string }) => {
    return useQuery<TProfile, AxiosError<IError>>(
      ['getProfile'],
      () => profileApi.getProfile(headers),
      { enabled: !!headers['X-Auth-Token'] },
    )
  },
}

export type TSubscribeBody = {
  headers: { [key: string]: string }
  check: boolean
}

export const useProfileMutation = {
  usePostSubscribeMailing: () =>
    useMutation<
      AxiosResponse<{ success: boolean }>,
      AxiosError<IError>,
      TSubscribeBody
    >(formData =>
      profileApi.postSubscribeMailing(formData.headers, formData.check),
    ),
  usePostProfile: () => {
    const { t } = useTranslation()
    return useMutation<
      AxiosResponse<TProfile>,
      AxiosError<IError>,
      TProfileBody
    >(formData => profileApi.postProfile(formData), {
      onSuccess: async () => {
        notify({
          icon: ToastIcon.SUCCESS,
          autoClose: ALERT_CLOSE_WAIT,
          label: t('validations.changeProfile.success'),
        })
      },
    })
  },
}

export default profileApi
