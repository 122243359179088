/**
 * vendor
 */
import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { useSession, signOut } from 'next-auth/react'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import getConfig from 'next/config'
import dynamic from 'next/dynamic'
import type {
  StylesConfig,
  CSSObjectWithLabel,
  Props as SelectProps,
} from 'react-select'

import useLocales from '../../hooks/useLocales'
import Hide from '../../components/Hide'

const {
  publicRuntimeConfig: { INTERNALIZATION },
} = getConfig()

const CATALOGUE_LINK = '/catalogue'
const CONTACTS_LINK = '/contacts'
const FAQ_LINK = '/faq'
const SETTINGS_LINK = '/settings'
const CERTIFICATE_LINK = '/certificate'
const COURSES_LINK = '/courses?filter=in_progress'

interface Style {
  clicked: boolean
}

type TList = {
  link: string
  title: string
  scroll: boolean
}

type TOption = {
  label: string
  value: string
}

interface Props extends Style {}

const Select = dynamic<SelectProps<TOption, false>>(import('react-select'), {
  ssr: false,
})

const List = React.forwardRef<HTMLDivElement, Props>(({ clicked }, ref) => {
  const { t, i18n } = useTranslation('components.header')
  const { data: session } = useSession()
  const { onToggleLanguageClick } = useLocales()
  const [options] = useState<TOption[]>([
    { value: 'hy', label: 'Հայ' },
    { value: 'en', label: 'Eng' },
    { value: 'ru', label: 'Рус' },
  ])

  const unAuthorizedList: TList[] = [
    { title: t('list.catalogue'), link: CATALOGUE_LINK, scroll: true },
    { title: t('list.contacts'), link: CONTACTS_LINK, scroll: true },
  ]
  const authorizedList: TList[] = JSON.parse(INTERNALIZATION)
    ? [
        {
          title: t('list.courses'),
          link: COURSES_LINK,
          scroll: true,
        },
        { title: t('list.settings'), link: SETTINGS_LINK, scroll: true },
        { title: t('list.help'), link: FAQ_LINK, scroll: true },
        { title: t('list.contacts'), link: CONTACTS_LINK, scroll: true },
      ]
    : [
        {
          title: t('list.courses'),
          link: COURSES_LINK,
          scroll: true,
        },
        { title: t('list.settings'), link: SETTINGS_LINK, scroll: true },
        { title: t('list.certificates'), link: CERTIFICATE_LINK, scroll: true },
        { title: t('list.help'), link: FAQ_LINK, scroll: true },
        { title: t('list.contacts'), link: CONTACTS_LINK, scroll: true },
      ]
  const handleSignOut = React.useCallback(
    () => signOut({ callbackUrl: '/' }),
    [],
  )
  const customStyles: StylesConfig<TOption, false> = {
    control: () => ({
      display: 'flex',
      justifyContent: 'space-between',
    }),
    valueContainer: () => ({
      display: 'flex',
      alignItems: 'center',
    }),
    indicatorsContainer: () => ({}),
    indicatorSeparator: () => ({}),
    dropdownIndicator: (styled, state) =>
      ({
        display: 'flex',
        alignItems: 'center',
        color: 'white',
        transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
      } as CSSObjectWithLabel),
    menu: () => ({
      marginTop: '10px',
    }),
    menuList: () => ({}),
    singleValue: () => ({
      color: 'white',
    }),
    option: () => ({
      color: 'white',
      textAlign: 'left',
      padding: '7px 0',
      margin: 0,
    }),
  }
  return (
    <StyledWrapper clicked={clicked} ref={ref}>
      <StyledLinkList>
        {!session &&
          unAuthorizedList.map((list, idx) => (
            <StyledListItem key={idx.toString()}>
              <Link href={list.link} scroll={list.scroll} passHref>
                <StyledAnchor> {list.title}</StyledAnchor>
              </Link>
            </StyledListItem>
          ))}
        {session &&
          authorizedList.map((list, idx) => (
            <StyledListItem key={idx.toString()}>
              <Link href={list.link} scroll={list.scroll} passHref>
                <StyledAnchor>{list.title}</StyledAnchor>
              </Link>
            </StyledListItem>
          ))}

        <HLine />

        <Hide hide={!JSON.parse(INTERNALIZATION) as boolean}>
          <StyledListItemSelect>
            <Select
              styles={customStyles}
              isSearchable={false}
              options={options.filter(op => op.value !== i18n.language)}
              value={options.find(op => {
                return op.value === i18n.language
              })}
              onChange={e => {
                e && onToggleLanguageClick(e.value)
              }}
            />
          </StyledListItemSelect>
        </Hide>
        {session && (
          <StyledListItemLogOut onClick={handleSignOut}>
            {t('list.logout')}
          </StyledListItemLogOut>
        )}
      </StyledLinkList>
    </StyledWrapper>
  )
})

List.displayName = 'List'
export default React.memo(List)

const StyledWrapper = styled.div<Style>`
  ${({ clicked }) => {
    if (clicked) {
      return css`
        ${({ theme }) => theme.mixins.flexbox()}
        background: ${({ theme }) => theme.colors.black};
        backdrop-filter: blur(3px);
        border-radius: 10px 0px 10px 10px;
        padding-top: 16px;
        padding-bottom: 16px;
        position: absolute;
        right: 0;
        top: 70px;
      `
    }
    return css`
      display: none;
    `
  }}
`

const StyledLinkList = styled.ul`
  width: 100%;
`

const StyledListItem = styled.li`
  width: 100%;
  cursor: pointer;
  margin: 5px 0;
  ${({ theme }) => theme.mixins.boxSizing()}
  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.alignItems('center')}
  ${({ theme }) => theme.mixins.transition('0.1s')}
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
  }
  &:active {
    background-color: ${({ theme }) => theme.colors.primaryDark};
  }
  ${({ theme }) => theme.mixins.textSmall};
  color: white;
`
const StyledAnchor = styled.a`
  padding: 3px 26px;
  color: white;
  ${({ theme }) => theme.mixins.textSmall};
  text-decoration: none;
  width: 100%;
  &:visited {
    text-decoration: none;
  }
`

const StyledListItemSelect = styled(StyledListItem)`
  &:hover {
    background-color: transparent;
  }
`
const HLine = styled.div`
  margin: 12px 25px;
  background-color: white;
  height: 1px;
  width: calc(100% - 50px);
  opacity: 0.2;
`

const StyledListItemLogOut = styled(StyledListItem)`
  padding: 3px 26px;
`
