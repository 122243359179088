import axios from 'axios'
import getConfig from 'next/config'
import checkCookieName from './checkCookieName'

const {
  publicRuntimeConfig: { API_URL, CMS, NOTIFICATION_API },
} = getConfig()

// export const dynamicDomain = function (instance: AxiosInstance) {
//   if (typeof window !== 'undefined') {
//     instance.defaults.baseURL = window.location.origin
//   }
//   return instance
// }

export const secure = axios.create({
  baseURL: API_URL,
})

export const api = axios.create({
  baseURL: API_URL,
})
api.interceptors.request.use(config => {
  if (typeof window !== 'undefined') {
    if (config.headers) {
      // NEXT_LOCALE записывает когда меняет через селетора язык. (useLocale)
      const match = checkCookieName('NEXT_LOCALE')
      if (match) {
        config.headers['X-Lang'] = match
      }
    }
  }
  return config
})
export const apiPaymentChecker = axios.create({ baseURL: API_URL })
export const cms = axios.create({ baseURL: CMS })
export const notification = axios.create({ baseURL: NOTIFICATION_API })
