import React from 'react'
import styled from 'styled-components'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useRouter } from 'next/router'
import { useQueryClient } from 'react-query'
import { useTranslation } from 'next-i18next'
/**
 * context
 */
import { useAppDispatch } from '../../context/app'
/**
 * ui
 */
import Input, { InputStyle } from '../Input'
import Button, { ButtonSize, ButtonStyle } from '../Button'
import { TModal } from './Modal'
import { notify, ToastIcon } from '../Toast'
/**
 * api
 */
import { useSecureMutation } from '../../api/secure'
/**
 * constants
 */
import { ALERT_CLOSE_WAIT } from '../../constants'

type TFormValue = {
  first: string
  second: string
}

type Props = {
  blockClickOutside?: boolean
}

const ModalNewPassword = React.forwardRef<HTMLFormElement, Props>(
  ({ blockClickOutside = false }, ref) => {
    const { t: tc } = useTranslation()
    const { t } = useTranslation('components.modal')
    const {
      register,
      handleSubmit,
      reset,
      getValues,
      setError,
      formState: { isValid, errors, isDirty, isSubmitting },
    } = useForm<TFormValue>({
      mode: 'onChange',
      reValidateMode: 'onChange',
      defaultValues: {
        first: '',
        second: '',
      },
    })
    const queryClient = useQueryClient()
    const { query, asPath, push } = useRouter()
    const dispatch = useAppDispatch()
    const resetPasswordMutation = useSecureMutation.usePostResetPasswordCode()

    const onSubmit: SubmitHandler<TFormValue> = data => {
      if (query.code) {
        return resetPasswordMutation
          .mutateAsync({
            code: query.code as string,
            first: data.first,
            second: data.second,
          })
          .then(async () => {
            await queryClient.invalidateQueries(['getProfile'])
            reset({
              first: '',
              second: '',
            })
            dispatch({
              type: 'SET_MODAL',
              modalShown: false,
              modalType: TModal.BLANK,
            })
            dispatch({
              type: 'SET_RESET_PASSWORD_CODE',
              code: '',
            })
            notify(
              {
                icon: ToastIcon.SUCCESS,
                autoClose: ALERT_CLOSE_WAIT,
                label: tc('validations.changePassword.success'),
              },
              async () => {
                await push({ pathname: asPath.split('?')[0] }, undefined, {
                  shallow: true,
                })
              },
            )
          })
          .catch(err => {
            if (err.response.data.formValidationErrors.new) {
              if (err.response.data.formValidationErrors.new.first) {
                setError('first', {
                  type: 'manual',
                  message:
                    err.response.data.formValidationErrors.new.first[0]
                      .message ?? '',
                })
              }
              if (err.response.data.formValidationErrors.new.second) {
                setError('second', {
                  type: 'manual',
                  message:
                    err.response.data.formValidationErrors.new.second[0]
                      .message ?? '',
                })
              }
            }
            if (!err.response.data.formValidationErrors) {
              notify({
                icon: ToastIcon.ERROR,
                autoClose: ALERT_CLOSE_WAIT,
                label: tc('validations.changePassword.error'),
              })
            }
          })
      }
    }
    return (
      <StyledContainer
        onSubmit={handleSubmit(onSubmit)}
        ref={blockClickOutside ? null : ref}
      >
        <StyledTitle>{t('ModalNewPassword.title')}</StyledTitle>
        <StyledInputWrapper>
          <Input
            errorMessage={errors?.first?.message}
            inputStyle={InputStyle.DEFAULT}
            type='password'
            label={t('ModalNewPassword.setNewPassword')}
            {...register('first', {
              required: {
                value: true,
                message: tc('validations.common.required'),
              },
              minLength: {
                value: 6,
                message: tc('validations.common.minLength', { num: 6 }),
              },
              validate: {
                same: v => {
                  if (!getValues('second')) {
                    return true
                  }
                  return (
                    v === getValues('second') ||
                    tc('validations.validate.changePassword')
                  )
                },
              },
            })}
          />
        </StyledInputWrapper>
        <StyledInputWrapper>
          <Input
            errorMessage={errors?.second?.message}
            inputStyle={InputStyle.DEFAULT}
            type='password'
            label={t('ModalNewPassword.setNewPasswordOneMore')}
            {...register('second', {
              required: {
                value: true,
                message: tc('validations.common.required'),
              },
              minLength: {
                value: 6,
                message: tc('validations.common.minLength', { num: 6 }),
              },
              validate: {
                same: v =>
                  v === getValues('first') ||
                  tc('validations.validate.changePassword'),
              },
            })}
          />
        </StyledInputWrapper>
        <StyledButtonWrapper>
          <Button
            buttonStyle={ButtonStyle.FILLED}
            size={ButtonSize.BLOCK}
            disabled={!isValid || !isDirty || isSubmitting}
            type='submit'
          >
            {t('ModalNewPassword.changePassword')}
          </Button>
        </StyledButtonWrapper>
      </StyledContainer>
    )
  },
)

ModalNewPassword.displayName = 'ModalNewPassword'
export default ModalNewPassword

const StyledContainer = styled.form`
  background: #ffffff;
  box-shadow: 0 4px 54px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  width: 100%;
  padding: 35px 24px;
  max-width: 100%;
  overflow: hidden;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    padding: 68px 97px;
    width: 555px;
  }
`

const StyledTitle = styled.h4`
  ${({ theme }) => theme.mixins.H4};
  text-align: center;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    ${({ theme }) => theme.mixins.H3};
  }
`

const StyledInputWrapper = styled.div`
  margin-top: 24px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    margin-bottom: 25px;
  }
`

const StyledButtonWrapper = styled.div`
  width: 100%;
  margin-top: 25px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    width: 203px;
    margin: 0 auto;
  }
`
