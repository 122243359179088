import { api } from '../utils/http'
import { IPay } from './subscription'
import { useMutation } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { IError } from './error'

export type TPremium = {
  premium: {
    isActive: boolean
    isSubscribed: boolean
    expiringAt: string | null
    canUnsubscribe: boolean
  }
}

const premiumApi = {
  postUnsubscribe: (headers: {
    [key: string]: string
  }): Promise<AxiosResponse<TPremium>> => {
    return api
      .post('/api/premium/unsubscribe', null, { headers })
      .then(res => res.data)
  },
  postSubscribe: (headers: {
    [key: string]: string
  }): Promise<AxiosResponse<TPremium>> => {
    return api
      .post('/api/premium/subscribe', null, {
        headers,
      })
      .then(res => res)
  },
  postPay: (headers: {
    [key: string]: string
  }): Promise<AxiosResponse<IPay>> => {
    return api.post('/api/premium/pay', null, { headers })
  },
}

export const usePremiumMutation = {
  usePostPay: () =>
    useMutation<
      AxiosResponse<IPay>,
      AxiosError<IError>,
      {
        headers: { [key: string]: string }
      }
    >(formData => premiumApi.postPay(formData.headers)),
}
export default premiumApi
