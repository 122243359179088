import { useQuery, useMutation } from 'react-query'
import { AxiosResponse, AxiosError } from 'axios'
import { api } from '../utils/http'
import { IPay } from './subscription'
import { notify, ToastIcon } from '../components/Toast'
import { ALERT_CLOSE_WAIT } from '../constants'
import { IError } from './error'

export type TCardType =
  | 'mastercard'
  | 'visa'
  | 'mir'
  | 'maestro'
  | 'americanExpress'
  | 'unionPay'

export type TCard = {
  pan4: string | null
  status: number
  uuid: string
  type: TCardType
}

export type TCardBody = {
  headers: { [key: string]: string }
  uuid: string
}

export type TCardCaptureBody = {
  headers: { [key: string]: string }
}

const cardApi = {
  getCards: (headers: { [key: string]: string }): Promise<TCard[]> => {
    return api.get<TCard[]>('/api/cards', { headers }).then(res => res.data)
  },
  postDisableCard: (
    headers: { [key: string]: string },
    uuid: string,
  ): Promise<TCard> => {
    return api
      .post(`/api/cards/${uuid}/disable`, null, {
        headers,
      })
      .then(res => res.data)
  },
  postCardCapture: (headers: { [key: string]: string }) => {
    return api.post<IPay>(`/api/cards/capture`, null, {
      headers,
    })
  },
}

export const useCardQuery = {
  useGetCards: (headers: { [key: string]: string }) => {
    return useQuery<TCard[], AxiosError<IError>>(
      ['getCards'],
      () => cardApi.getCards(headers),
      { enabled: !!headers['X-Auth-Token'] },
    )
  },
}

export const useCardMutation = {
  usePostDisableCard: () =>
    useMutation<TCard, AxiosError<IError>, TCardBody>(formData =>
      cardApi.postDisableCard(formData.headers, formData.uuid),
    ),
  usePostCardCapture: () => {
    return useMutation<
      AxiosResponse<IPay>,
      AxiosError<IError>,
      TCardCaptureBody
    >(formData => cardApi.postCardCapture(formData.headers), {
      onError: err => {
        notify({
          icon: ToastIcon.ERROR,
          autoClose: ALERT_CLOSE_WAIT,
          label: err.response?.data?.error?.message ?? '',
        })
      },
    })
  },
}
export default cardApi
