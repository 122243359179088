import React from 'react'
import styled from 'styled-components'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation, Trans } from 'next-i18next'
/**
 * context
 */
import { useAppDispatch, useAppState } from '../../context/app'
/**
 * ui
 */
import Input, { InputStyle } from '../Input'
import Button, { ButtonSize, ButtonStyle } from '../Button'
import { TModal } from './Modal'
import Checkbox from '../Checkbox'
import { notify, ToastIcon } from '../Toast'
/**
 * api
 */
import { useSecureMutation } from '../../api/secure'
/**
 * constants
 */
import { ALERT_CLOSE_WAIT } from '../../constants'

/**
 * hook
 * */
import useRecaptcha from '../../hooks/useRecaptcha'

type TFormValue = {
  email: string
  privacyPolicy: boolean
  promotionalMailings: boolean
  personalData: boolean
}

const ModalRegistration: React.FC = () => {
  const { recaptcha } = useAppState()
  const { t: tc } = useTranslation()
  const { t } = useTranslation('components.modal')
  const { handleRecaptcha } = useRecaptcha()
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { isValid, errors, isSubmitting, isDirty },
  } = useForm<TFormValue>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      email: '',
      privacyPolicy: true,
      promotionalMailings: true,
      personalData: true,
    },
  })
  const dispatch = useAppDispatch()
  // mutations
  const registrationMutation = useSecureMutation.usePostRegistration()

  const setLoginModal = (): void =>
    dispatch({
      type: 'SET_MODAL',
      modalShown: true,
      modalType: TModal.LOGIN,
    })
  const handleRegistration = (body: any) => {
    setValue('email', '')

    return registrationMutation
      .mutateAsync(body)
      .then(() => {
        notify({
          icon: ToastIcon.SUCCESS,
          autoClose: ALERT_CLOSE_WAIT,
          label: tc('notify.registrationSuccess'),
        })
        dispatch({
          type: 'SET_MODAL',
          modalShown: false,
          modalType: TModal.BLANK,
        })
      })
      .catch(err => {
        setValue('email', body.email)
        if (err.response?.data?.formValidationErrors?.email) {
          setError('email', {
            type: 'manual',
            message:
              err.response?.data?.formValidationErrors?.email[0].message ?? '',
          })
        }
        if (err.response?.data?.formValidationErrors?.recaptcha) {
          setError('email', {
            type: 'manual',
            //@ts-ignore
            message:
              err.response.data.formValidationErrors.recaptcha[0].message ?? '',
          })
        }
      })
  }
  const onSubmit: SubmitHandler<TFormValue> = async data => {
    if (!recaptcha) {
      return handleRegistration({
        ...data,
        mailConsent: data.personalData,
        recaptcha: '',
      })
    }
    if (recaptcha) {
      const token = await handleRecaptcha()
      if (!token) {
        throw new Error('Recaptcha is not valid yet')
      }

      return handleRegistration({
        ...data,
        mailConsent: data.personalData,
        recaptcha: token,
      })
    }
  }

  return (
    <StyledContainer onSubmit={handleSubmit(onSubmit)}>
      <StyledTitle>{t('ModalRegistration.title')}</StyledTitle>
      <StyledInputWrapper>
        <Input
          errorMessage={errors?.email?.message}
          inputStyle={InputStyle.DEFAULT}
          type='email'
          label={t('ModalRegistration.email')}
          {...register('email', {
            required: {
              value: true,
              message: tc('validations.common.required'),
            },
          })}
        />
      </StyledInputWrapper>
      <StyledButtonWrapper>
        <Button
          buttonStyle={ButtonStyle.FILLED}
          size={ButtonSize.BLOCK}
          disabled={!isValid || isSubmitting || !isDirty}
          type='submit'
        >
          {t('ModalRegistration.register')}
        </Button>
      </StyledButtonWrapper>
      <StyledCheckboxWrapper>
        <Checkbox
          labels={[
            {
              label: t('ModalRegistration.personalPolicy'),
              href: '/static/common/personal_data.pdf',
            },
            {
              label: t('ModalRegistration.privacyPolicy'),
              href: '/static/common/privacy_policy.pdf',
            },
          ]}
          id='personal-data'
          {...register('personalData', {
            required: true,
          })}
        />
      </StyledCheckboxWrapper>
      <StyledCheckboxWrapper>
        <Checkbox
          labels={[{ label: t('ModalRegistration.receiveMailings') }]}
          id='promotional-mailings'
          {...register('promotionalMailings')}
        />
      </StyledCheckboxWrapper>
      <StyledHr />
      <StyledLogin>
        <Trans
          t={t}
          i18nKey='ModalRegistration.alreadyHaveAccount'
          components={{
            '1': <StyledLoginLink onClick={setLoginModal} />,
          }}
        />
      </StyledLogin>
    </StyledContainer>
  )
}
export default ModalRegistration

const StyledContainer = styled.form`
  background: #ffffff;
  box-shadow: 0 4px 54px rgba(0, 0, 0, 0.05);
  width: 100%;
  border-radius: 5px;
  padding: 35px 24px;
  max-width: 100%;
  overflow: hidden;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    padding: 68px 97px;
    max-width: 555px;
  }
`

const StyledTitle = styled.h4`
  ${({ theme }) => theme.mixins.H4};
  text-align: center;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    ${({ theme }) => theme.mixins.H3};
  }
`

const StyledInputWrapper = styled.div`
  margin-top: 24px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    margin-bottom: 25px;
  }
`

const StyledButtonWrapper = styled.div`
  width: 100%;
  margin-top: 25px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    width: 203px;
    margin: 0 auto;
  }
`

const StyledCheckboxWrapper = styled.div`
  margin-top: 35px;
  &:not(:first-child) {
    margin-top: 20px;
  }
`

const StyledHr = styled.hr`
  border-top: 1px solid ${({ theme }) => theme.colors.greySecondary};
  margin: 25px 0 35px 0;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    margin: 64px 0 35px 0;
  }
`

const StyledLogin = styled.div`
  text-align: center;
  font-weight: 400;
  line-height: 16.07px;
  font-size: 0.813rem;
`

const StyledLoginLink = styled.span`
  cursor: pointer;
  ${({ theme }) => theme.mixins.textButton};
  color: ${({ theme }) => theme.colors.primary};
`
