export const serialize = function (obj) {
  let str = []

  for (let p in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(p)) {
      str.push(p + '=' + obj[p])
    }
  }

  return str.join('&')
}
