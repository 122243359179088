import React from 'react'
import styled from 'styled-components'
/**
 * context
 */
import { useAppState, useAppDispatch } from '../../context/app'
/**
 * hooks
 */
import useClickOutside from '../../hooks/useClickOutside'

const ModalYkassa = () => {
  const dispatch = useAppDispatch()
  const { ykassaModal } = useAppState()
  const modalRef = React.useRef<HTMLDivElement>(null)
  useClickOutside([modalRef], () => {
    setModal()
  })
  const setModal = () => {
    dispatch({
      type: 'SET_YKASSA_MODAL',
      ykassaModal: false,
    })
  }
  if (!ykassaModal) {
    return null
  }

  return (
    <StyledWrapper>
      <StyledModalBody id='payment-form' ref={modalRef} />
    </StyledWrapper>
  )
}

export default ModalYkassa

const StyledWrapper = styled.div`
  top: 0;
  left: 0;
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgba(237, 242, 237, 0.6);
  backdrop-filter: blur(3px);
  display: flex;
  z-index: 9990;
`

const StyledModalBody = styled.div`
  margin: auto;
  width: 100%;
  max-width: 100%;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    max-width: 555px;
  }
`
