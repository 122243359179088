import React from 'react'
import styled, { css } from 'styled-components'
import { IconPackType } from '../Icon/icons'
import Icon from '../Icon'

export enum ButtonStyle {
  FILLED = 'filled',
  OUTLINED = 'outlined',
  OUTLINED_TAG = 'outlinedTag',
  ICON = 'icon',
  OUTLINED_PHONE = 'outlinedPhone',
  FILTER = 'filter',
  OUTLINED__GRAY = 'OUTLINED__GRAY',
}

export enum ButtonSize {
  BLOCK = 'block',
  PADDING = 'padding',
  PADDINGSMALL = 'paddingSmall',
  PADDING_XSMALL = 'paddingXSmall',
  PADDING_CIRCLE = 'paddingCircle',
  PADDING_PHONE = 'paddingPhone',
  PADDING_FILTER = 'paddingFilter',
  PADDINGCUSTOM = 'PADDINGCUSTOM',
}

interface Style {
  buttonStyle: ButtonStyle
  size: ButtonSize
  shadow?: string
  disabled?: boolean
  black?: boolean
  clicked?: boolean
  style?: React.CSSProperties
  as?: React.ElementType | undefined
}

interface Props extends Style {
  onClick?: (
    e:
      | React.MouseEvent<HTMLButtonElement>
      | React.TouchEvent<HTMLButtonElement>,
  ) => void
  onClickCapture?: (
    e:
      | React.MouseEvent<HTMLButtonElement>
      | React.TouchEvent<HTMLButtonElement>,
  ) => void
  beforeIcon?: IconPackType
  afterIcon?: IconPackType
  iconSize?: string
  type?: 'button' | 'submit' | 'reset'
  value?: string
  children?: React.ReactNode
}

const Button: React.FC<Props> = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      buttonStyle,
      size,
      disabled,
      children,
      onClick,
      onClickCapture,
      black,
      beforeIcon,
      afterIcon,
      iconSize,
      clicked,
      type = 'button',
      shadow,
      value,
      style,
      as,
    },
    ref,
  ) => {
    return (
      <StyledContainer
        buttonStyle={buttonStyle}
        size={size}
        onClick={onClick}
        onClickCapture={onClickCapture}
        disabled={disabled}
        black={black}
        clicked={clicked}
        type={type}
        shadow={shadow}
        ref={ref}
        value={value}
        style={style}
        as={as}
      >
        {beforeIcon && (
          <StyledIcon>
            <Icon name={beforeIcon} width={iconSize} height={iconSize} />
          </StyledIcon>
        )}

        {children}

        {afterIcon && (
          <StyledIconAfter>
            <Icon name={afterIcon} width={iconSize} height={iconSize} />
          </StyledIconAfter>
        )}
      </StyledContainer>
    )
  },
)

Button.displayName = 'Button'
export default Button

const StyledContainer = styled.button<Style>`
  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.alignItems('center')}
  ${({ theme }) => theme.mixins.justifyContent('center')}
  text-align: center;
  position: relative;
  border-radius: 50px;
  ${({ theme }) => theme.mixins.textButton};
  transition: all 0.2s linear;
  cursor: pointer;
  ${({ shadow }) => {
    if (shadow) {
      return css`
        box-shadow: ${shadow};
      `
    }
  }}

  ${({ size }) => {
    if (size === ButtonSize.BLOCK) {
      return css`
        padding: 13px 15px;
        display: block;
        width: 100%;
      `
    } else if (size === ButtonSize.PADDING) {
      return css`
        padding: 14px 30px;
        width: fit-content;
      `
    } else if (size === ButtonSize.PADDINGSMALL) {
      return css`
        padding: 7.89px 18px;

        @media ${({ theme }) => theme.devices.mobileLgUp} {
          padding: 14px 30px;
        }
      `
    } else if (size === ButtonSize.PADDINGCUSTOM) {
      return css`
        padding: 10px 15px;
      `
    } else if (size === ButtonSize.PADDING_XSMALL) {
      return css`
        padding: 10px 28px;
      `
    } else if (size === ButtonSize.PADDING_PHONE) {
      return css`
        padding: 14px 20px;
        width: fit-content;
      `
    } else if (size === ButtonSize.PADDING_CIRCLE) {
      return css`
        padding: 11px;

        @media ${({ theme }) => theme.devices.mobileLgUp} {
          padding: 14px 30px;
        }
      `
    } else if (size === ButtonSize.PADDING_FILTER) {
      return css`
        padding: 6px 10px;
        @media ${({ theme }) => theme.devices.mobileLgUp} {
          padding: 11.5px 16px;
        }
        @media ${({ theme }) => theme.devices.noteUp} {
          padding: 12.5px 24px;
        }
      `
    } else {
      css``
    }
  }}

  ${({ buttonStyle, black }) => {
    switch (buttonStyle) {
      case ButtonStyle.FILLED:
        return css`
          background-color: ${({ theme }) => theme.colors.primary};

          color: #ffffff;
          border: 1px solid ${({ theme }) => theme.colors.primary};
          text-align: center;

          &:hover {
            border: 1px solid ${({ theme }) => theme.colors.primary};
            background-color: #ffffff;
            color: ${({ theme }) => theme.colors.primary};
            & path {
              transition: all 0.2s linear;
              stroke: ${({ theme }) => theme.colors.primary};
            }
          }

          &:disabled {
            border: 1px solid ${({ theme }) => theme.colors.grey};
            background-color: #ffffff;
            color: ${({ theme }) => theme.colors.grey};
          }
        `

      case ButtonStyle.OUTLINED:
        return css`
          color: ${({ theme }) =>
            black ? theme.colors.black : theme.colors.primary};
          border: 1px solid
            ${({ theme }) =>
              black ? theme.colors.black : theme.colors.primary};
          text-align: center;

          &:hover {
            border: solid 1px ${({ theme }) => theme.colors.primary};
            color: #ffffff;
            background-color: ${({ theme }) => theme.colors.primary};
          }

          &:active {
            border: solid 1px ${({ theme }) => theme.colors.primary};
            color: #ffffff;
            background-color: ${({ theme }) => theme.colors.primary};
          }

          &:disabled {
            border: 1px solid ${({ theme }) => theme.colors.grey};
            background-color: ${({ theme }) => theme.colors.grey};
            color: #ffffff;
          }
        `

      case ButtonStyle.OUTLINED__GRAY:
        return css`
          border-radius: 20px;
          color: #191919;
          ${({ theme }) => theme.mixins.text3};
          border: solid 1px #f9fafb;

          &:hover {
            border: solid 1px ${({ theme }) => theme.colors.black};
          }

          &:active {
            border: solid 1px ${({ theme }) => theme.colors.black};
          }

          &:disabled {
            opacity: 0.6;
          }

          @media ${({ theme }) => theme.devices.mobileLgUp} {
            ${({ theme }) => theme.mixins.text1};
          }
        `

      case ButtonStyle.OUTLINED_PHONE:
        return css`
          color: ${({ theme }) =>
            black ? theme.colors.black : theme.colors.primary};
          border: 1px solid
            ${({ theme }) =>
              black ? theme.colors.black : theme.colors.primary};
          text-align: center;

          &:active {
            border: solid 1px ${({ theme }) => theme.colors.primary};
            color: #ffffff;
            background-color: ${({ theme }) => theme.colors.primary};

            svg {
              path {
                fill: #fff;
              }
            }
          }
        `

      case ButtonStyle.OUTLINED_TAG:
        return css`
          display: flex;
          align-items: center;
          justify-content: center;
          ${({ theme }) => theme.mixins.textXSmall};
          @media ${({ theme }) => theme.devices.mobileLgUp} {
            ${({ theme }) => theme.mixins.textSmall};
          }
          color: ${({ theme }) =>
            black ? theme.colors.black : theme.colors.primary};
          border: 1px solid
            ${({ theme }) =>
              black ? theme.colors.black : theme.colors.primary};
          text-align: center;
          &:hover {
            border: solid 1px ${({ theme }) => theme.colors.primary};
            color: #ffffff;
            background-color: ${({ theme }) => theme.colors.primary};
          }

          &:active {
            border: solid 1px ${({ theme }) => theme.colors.primary};
            color: #ffffff;
            background-color: ${({ theme }) => theme.colors.primary};
          }

          &:disabled {
            border: 1px solid ${({ theme }) => theme.colors.grey};
            background-color: ${({ theme }) => theme.colors.grey};
            color: #ffffff;
          }
        `
      case ButtonStyle.ICON:
        return css`
          color: ${({ theme }) => theme.colors.black};
          border: 1px solid ${({ theme }) => theme.colors.greySecondary};
          display: flex;
          padding: 12px 28px 13px 19px;
          align-items: center;

          &:hover {
            border: solid 1px ${({ theme }) => theme.colors.primary};
            color: ${({ theme }) => theme.colors.primary};
            background-color: #ffffff;

            & path {
              transition: all 0.2s linear;
              stroke: ${({ theme }) => theme.colors.primary};
            }
          }

          &:disabled {
            border: 1px solid ${({ theme }) => theme.colors.grey};
            background-color: #ffffff;
            color: ${({ theme }) => theme.colors.grey};
            & path {
              transition: all 0.2s linear;
              stroke: ${({ theme }) => theme.colors.grey};
            }
          }
        `
      case ButtonStyle.FILTER:
        return css`
          color: ${({ theme }) => theme.colors.black};
          border: 1px solid ${({ theme }) => theme.colors.greySecondary};
          text-align: center;
          ${({ theme }) => theme.mixins.textXSmall};
          &:hover {
            border: solid 1px ${({ theme }) => theme.colors.primary};
            color: ${({ theme }) => theme.colors.primary};
          }

          &:active {
            border: solid 1px ${({ theme }) => theme.colors.primary};
            color: ${({ theme }) => theme.colors.primary};
          }

          &:disabled {
            border: 1px solid ${({ theme }) => theme.colors.grey};
            background-color: ${({ theme }) => theme.colors.grey};
            color: #ffffff;
          }
          @media ${({ theme }) => theme.devices.mobileMdUp} {
            ${({ theme }) => theme.mixins.textSmall};
          }
          @media ${({ theme }) => theme.devices.noteUp} {
            font-size: 1.125rem;
            line-height: 140%;
          }
        `
      default:
        break
    }
  }}

  ${({ clicked, buttonStyle }) => {
    switch (buttonStyle) {
      case ButtonStyle.OUTLINED:
        if (clicked)
          return css`
            border: solid 1px ${({ theme }) => theme.colors.primary};
            color: #ffffff;
            background-color: ${({ theme }) => theme.colors.primary};
          `
        break
      case ButtonStyle.OUTLINED__GRAY:
        if (clicked)
          return css`
            border: solid 1px ${({ theme }) => theme.colors.black};
          `
        break
      case ButtonStyle.FILTER:
        if (clicked)
          return css`
            border: solid 1px ${({ theme }) => theme.colors.primary};
            color: ${({ theme }) => theme.colors.primary};
          `
        break
      default:
        break
    }
  }}
  &:disabled {
    cursor: not-allowed;
    pointer-events: none;
  }
`
const StyledIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`

const StyledIconAfter = styled(StyledIcon)`
  margin-left: 18px;
  margin-right: 0;
`
