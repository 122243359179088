import React from 'react'
import styled, { css } from 'styled-components'
import Icon from '../Icon'

export enum InputStyle {
  DEFAULT = 'default',
  ROUNDED = 'round',
}

interface Style {
  inputStyle: InputStyle
}

interface Props extends Style {
  label?: string
  errorMessage?: string
  disabled?: boolean
  name?: string
  type?: string
  placeholder?: string
  id?: string
  beforeIcon?: string
}

const Input: React.FC<Props> = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      label = '',
      errorMessage = '',
      disabled = false,
      name,
      type,
      placeholder,
      inputStyle,
      id,
      beforeIcon = '',
      ...rest
    },
    ref,
  ) => {
    return (
      <StyledContainer>
        {label && <StyledLabel htmlFor={id}>{label}</StyledLabel>}
        <StyledInput
          error={errorMessage}
          disabled={disabled}
          type={type}
          name={name}
          placeholder={placeholder}
          inputStyle={inputStyle}
          {...rest}
          ref={ref}
          id={id}
          beforeIcon={beforeIcon}
        />
        {errorMessage && (
          <StyledError>
            <Icon name='info-circle' width='10.83' height='10.83' />
            {errorMessage}
          </StyledError>
        )}
      </StyledContainer>
    )
  },
)

Input.displayName = 'Input'
export default Input

const StyledContainer = styled.div`
  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.flexDirection('column')}
  width: 100%;
`

const StyledLabel = styled.label`
  font-family: ${({ theme }) => theme.mixins.textSmall};
  color: ${({ theme }) => theme.colors.black};
  margin-bottom: 12px;
  text-align: left;
  display: inline-block;
`

const StyledInput = styled.input<{
  error: string
  disabled: boolean
  inputStyle: InputStyle
  beforeIcon: string
}>`
  height: 45px;
  ${({ theme }) => theme.mixins.boxSizing()}
  border: 1px solid ${({ theme }) => theme.colors.greySecondary};
  text-indent: 21px;
  ${({ theme }) => theme.mixins.textSmall};
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    font-size: 1rem;
  }
  ${({ beforeIcon }) => {
    if (beforeIcon) {
      return css`
        background: url(${beforeIcon}) no-repeat 28px;
        background-size: 20px;
        padding-left: 56px;
        text-indent: 0;
      `
    }
  }}
  &:focus,
  &:active {
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }
  ${({ error }) => {
    if (error) {
      return css`
        border: 1px solid ${({ theme }) => theme.colors.error};

        &:focus,
        &:active {
          border: 1px solid ${({ theme }) => theme.colors.error};
        }
      `
    }
    return css``
  }}

  ${({ disabled }) => {
    if (disabled) {
      return css`
        cursor: not-allowed;
        pointer-events: none;
      `
    }
    return css``
  }}
  
  ${({ inputStyle }) => {
    switch (inputStyle) {
      case InputStyle.DEFAULT:
        return css`
          border-radius: 10px;
        `
      case InputStyle.ROUNDED:
        return css`
          border-radius: 50px;
          border: 1px solid ${({ theme }) => theme.colors.primary};
        `
      default:
        break
    }
  }}
`

const StyledError = styled.div`
  margin-top: 14px;
  font-family: 'Averta PE', sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.black};
  display: grid;
  grid-template-columns: 10.83px 1fr;
  grid-column-gap: 8px;
`
