import React, { useEffect, MouseEvent } from 'react'
import styled from 'styled-components'
import { useTranslation, Trans } from 'next-i18next'
/**
 * context
 */
import { useAppDispatch, useAppState } from '../../context/app'
import { useLocalStorage } from 'usehooks-ts'
import useClientSide from '../../hooks/useClientSide'

const ModalCookie = React.forwardRef<HTMLDivElement>((unknown, ref) => {
  const { t } = useTranslation('components.modal')
  const dispatch = useAppDispatch()
  const { cookieModal } = useAppState()
  const linkRef = React.useRef<HTMLAnchorElement>(null)
  const isClientSide = useClientSide()
  const [, setLocal] = useLocalStorage<{
    cookieAgree: boolean
    utm: string
  }>('willskill', { cookieAgree: false, utm: '' })

  const onClickAnchor = (e: any) => {
    if (linkRef.current) {
      e.preventDefault()
      e.stopPropagation()
      window.open(linkRef.current.href, '_blank')
    }
  }

  useEffect(() => {
    if (!linkRef.current) return
    linkRef.current.addEventListener('click', onClickAnchor)
    return () => {
      if (linkRef.current) {
        linkRef.current.removeEventListener('click', onClickAnchor)
      }
    }
  }, [linkRef])

  useEffect(() => {
    if (isClientSide) {
      setLocal(prev => {
        if (!prev.cookieAgree) {
          dispatch({
            type: 'SET_COOKIE_MODAL',
            cookieModal: true,
          })
        }
        if (prev.cookieAgree) {
          dispatch({
            type: 'SET_COOKIE_MODAL',
            cookieModal: false,
          })
        }
        return { ...prev }
      })
    }
  }, [cookieModal, dispatch, isClientSide, setLocal])

  const setModal = React.useCallback(
    (e: MouseEvent) => {
      e.preventDefault()
      e.stopPropagation()
      if (isClientSide) {
        setLocal(prev => ({ ...prev, cookieAgree: true }))
        dispatch({
          type: 'SET_COOKIE_MODAL',
          cookieModal: false,
        })
      }
    },
    [dispatch, isClientSide, setLocal],
  )
  if (!cookieModal) {
    return null
  }
  return (
    <StyledWrapper ref={ref} onClick={setModal}>
      <StyledContainer>
        <StyledText>
          <Trans
            t={t}
            i18nKey='ModalCookie.content'
            components={{
              '1': (
                <StyledLink
                  target='_blank'
                  href='/static/common/privacy_policy.pdf'
                  rel='noreferrer'
                  ref={linkRef}
                />
              ),
            }}
          />
        </StyledText>
        <StyledTextAccent>{t('ModalCookie.accept')}</StyledTextAccent>
      </StyledContainer>
    </StyledWrapper>
  )
})

ModalCookie.displayName = 'ModalCookie'
export default ModalCookie

const StyledWrapper = styled.div`
  z-index: 9996;
  width: 100%;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  position: fixed;
  display: flex;
  justify-content: right;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    bottom: 29px;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    bottom: 29px;
    max-width: 1200px;
  }
`

const StyledContainer = styled.div`
  cursor: pointer;
  background: #fff;
  padding: 16px 20px;
  width: 100%;
  box-shadow: 0px 4px 21px rgba(200, 197, 188, 0.7);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-columns: auto 62px;
  grid-column-gap: 20px;
  align-items: center;
  button {
    height: 45px;
  }
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    width: 300px;
    grid-template-columns: auto 69px;
    border-radius: 5px;
    margin-right: 30px;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    margin-right: 0;
  }
`

const StyledText = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.textXXsm};
  font-weight: 400;
  line-height: 13px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    font-size: ${({ theme }) => theme.fontSizes.textXsm};
    line-height: 15px;
  }
`

const StyledLink = styled.a`
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.primary};
  &:visited {
    text-decoration: none;
  }
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`

const StyledTextAccent = styled.span`
  font-weight: 600;
  text-decoration: underline;
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.primary};
`
