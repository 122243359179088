import React from 'react'
import styled, { css } from 'styled-components'
/**
 * ui
 */
import Icon from '../Icon'
import { Props as IIconProps } from '../Icon/Icon'

export interface Style {
  position?: string
  shadow?: string
  top?: number | null
  zIndex?: number
}

export interface AlertProps {
  type: AlertType
  visible: boolean
  alertStyle?: Style
  button?: React.ReactElement
  close?: React.ReactElement<IIconProps>
  onClose?: () => void
  children?: React.ReactNode
}

export enum AlertType {
  GREEN = 'info-circle-green',
  RED = 'info-circle',
  WARN = 'info-circle-warn',
}

const Alert: React.FC<AlertProps> = ({
  type = AlertType.GREEN,
  children,
  alertStyle,
  close,
  button,
  onClose,
  visible,
}) => {
  if (!visible) {
    return null
  }
  return (
    <StyledContainer alertStyle={alertStyle}>
      <StyledAlertInner type={type} alertStyle={alertStyle}>
        {close && (
          <StyledAlertCloseWrapper onClick={onClose}>
            {close}
          </StyledAlertCloseWrapper>
        )}
        <StyledAlertIconWrapper>
          <Icon name={type} />
        </StyledAlertIconWrapper>
        <StyledAlertContent>{children}</StyledAlertContent>
        {button && (
          <StyledAlertButtonWrapper>{button}</StyledAlertButtonWrapper>
        )}
      </StyledAlertInner>
    </StyledContainer>
  )
}

export default Alert

const StyledContainer = styled.div<Pick<AlertProps, 'alertStyle'>>`
  max-width: 1170px;
  background-color: #fff;
  border-radius: 10px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    max-width: 1200px;
  }
  ${({ alertStyle }) => {
    if (alertStyle && alertStyle.position) {
      return css`
        position: ${alertStyle.position};
        height: auto;
        z-index: 1000;
        @media ${({ theme }) => theme.devices.mobileLgUp} {
          left: 50%;
          -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
        }
      `
    }
  }}
  ${({ alertStyle }) => {
    if (alertStyle && alertStyle.top) {
      return css`
        top: ${alertStyle.top + 5}px;
      `
    }
  }}
  ${({ alertStyle }) => {
    if (alertStyle && alertStyle.zIndex) {
      return css`
        z-index: ${alertStyle.zIndex};
      `
    }
  }}
`

// const StyledAlertWrapper = styled.div<Pick<AlertProps, 'alertStyle'>>`
//   background-color: #fff;
//   border-radius: 10px;
//   ${({ alertStyle }) => {
//     if (alertStyle && alertStyle.position) {
//       return css`
//         position: ${alertStyle.position};
//         height: auto;
//         z-index: 3;
//         width: 90%;
//         @media ${({ theme }) => theme.devices.mobileLgUp} {
//           left: 50%;
//           -webkit-transform: translateX(-50%);
//           transform: translateX(-50%);
//         }
//       `
//     }
//   }}
//   ${({ alertStyle }) => {
//     if (alertStyle && alertStyle.top) {
//       return css`
//         top: ${alertStyle.top + 5}px;
//       `
//     }
//   }}
//   ${({ alertStyle }) => {
//     if (alertStyle && alertStyle.zIndex) {
//       return css`
//         z-index: ${alertStyle.zIndex};
//       `
//     }
//   }}
// `

const StyledAlertInner = styled.div<Pick<AlertProps, 'type' | 'alertStyle'>>`
  border-radius: 10px;
  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.alignItems('center')}
  ${({ alertStyle }) => {
    if (alertStyle && alertStyle.shadow) {
      return css`
        box-shadow: ${alertStyle.shadow};
      `
    }
    return css`
      box-shadow: 0px 4px 21px rgba(200, 197, 188, 0.7);
    `
  }};
  background: ${({ type }) =>
    type == AlertType.GREEN
      ? '#e8f6f0'
      : type == AlertType.RED
      ? '#FB432E19'
      : '#FF9C5419'};
  padding: 18px 34.5px 17px 15px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    padding: 16px 64px 17px 20px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    padding: 10px 68px 11px 19px;
  }
`

const StyledAlertIconWrapper = styled.div`
  margin-right: 15px;
  ${({ theme }) => theme.mixins.flexShrink()}
  ${({ theme }) => theme.mixins.alignSelf('flex-start')}
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    ${({ theme }) => theme.mixins.alignSelf('initial')}
  }
`

const StyledAlertCloseWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
`

const StyledAlertContent = styled.div`
  ${({ theme }) => theme.mixins.textXSmall};
  margin-right: 34px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    ${({ theme }) => theme.mixins.textNormal};
  }
`

const StyledAlertButtonWrapper = styled.div`
  width: 59px;
  flex-shrink: 0;
  margin-left: auto;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    width: 69px;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    width: 190px;
  }
`
