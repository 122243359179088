import React from 'react'
import styled from 'styled-components'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
/**
 * context
 */
import { useAppDispatch } from '../../context/app'
import { TModal } from './Modal'
/**
 * ui
 */
import Button, { ButtonSize, ButtonStyle } from '../Button'
import { navigateCourse } from '../../constants/router'

const ModalStopMiddle: React.FC = () => {
  const { t } = useTranslation('components.modal')
  const router = useRouter()
  const dispatch = useAppDispatch()

  const setCloseModal = React.useCallback(() => {
    dispatch({
      type: 'SET_MODAL',
      modalShown: false,
      modalType: TModal.BLANK,
    })
    return router.push(navigateCourse)
  }, [dispatch, router])

  const onClickUnsubscribe = React.useCallback(() => {
    dispatch({
      type: 'SET_MODAL',
      modalShown: true,
      modalType: TModal.STOP_FINAL,
    })
  }, [dispatch])

  return (
    <StyledContainer>
      <StyledTitle>{t('ModalStopMiddle.title')}</StyledTitle>
      <StyledSubtitle>{t('ModalStopMiddle.subtitle')}</StyledSubtitle>
      <br />
      <StyledModalList>
        <StyledModalListItem>{t('ModalStopMiddle.list1')}</StyledModalListItem>
        <StyledModalListItem>{t('ModalStopMiddle.list2')}</StyledModalListItem>
        <StyledModalListItem>{t('ModalStopMiddle.list3')}</StyledModalListItem>
      </StyledModalList>
      <StyledButtonContainer>
        <Button
          buttonStyle={ButtonStyle.FILLED}
          size={ButtonSize.BLOCK}
          onClick={setCloseModal}
        >
          {t('ModalStopMiddle.leaveSubscription')}
        </Button>
      </StyledButtonContainer>
      <StyledCancel onClick={onClickUnsubscribe}>
        {t('ModalStopMiddle.stopSubscription')}
      </StyledCancel>
    </StyledContainer>
  )
}

export default ModalStopMiddle

const StyledContainer = styled.div`
  background: #ffffff;
  box-shadow: 0 4px 54px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  width: 100%;
  padding: calc(35px + 21px) calc(24px + 15px);
  max-width: 100%;
  overflow: hidden;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    padding: 68px 97px;
    max-width: 555px;
  }
`

const StyledTitle = styled.h4`
  ${({ theme }) => theme.mixins.H4};
  text-align: left;
  margin-bottom: 33px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    ${({ theme }) => theme.mixins.H3};
  }
`

const StyledSubtitle = styled.p`
  ${({ theme }) => theme.mixins.textButton};
  text-align: left;
`

const StyledModalList = styled.ul`
  margin-left: 20px;
  margin-bottom: 33px;
`

const StyledModalListItem = styled.li`
  ${({ theme }) => theme.mixins.textSmall};
  list-style: disc;
`

const StyledButtonContainer = styled.div`
  width: 100%;
  margin-bottom: 22px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    width: 203px;
  }
`

const StyledCancel = styled.p`
  color: ${({ theme }) => theme.colors.primary};
  ${({ theme }) => theme.mixins.textLink};
  text-decoration: underline;
  cursor: pointer;
`
