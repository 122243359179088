/**
 * vendor
 */
import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { useTranslation, Trans } from 'next-i18next'
import { useRouter } from 'next/router'
/**
 * ui
 */
import Header from '../Header'
import { IHeader } from '../Header/Header'
import Footer, { FooterStyle } from '../Footer'
import Modal from '../Modal'
import ModalCookie from '../Modal/ModalCookie'
import ModalYkassa from '../Modal/ModalYkassa'
import ModalCommentVideo from '../Modal/ModalCommentVideo'
import Hide from '../Hide'
import Alert, { AlertType } from '../Alert'
import Icon from '../Icon'
import * as Navigation from 'src/components/Navigation/Navigation'
/**
 * utils
 */
import useLinkCard from '../../hooks/useLinkCard'
import useAffrid from '../../hooks/useAffrid'
import useClientSide from '../../hooks/useClientSide'
/**
 * context
 */
import { useAppDispatch, useAppState } from '../../context/app'

export enum MainStyle {
  MAIN = 'main',
  MAINPTLG = 'main--pt-lg',
  MAINPTS = 'main--pt-s',
  MAIN_WITHOUT_HEADER_PADDING = 'main--without-header-padding',
  MAIN_COURSE = 'main_course',
}

interface Style {
  mainStyle?: MainStyle
  style?: React.CSSProperties
}

interface Props extends Style {
  footerStyle: FooterStyle
  headerStyle?: IHeader
  logo?: 'logo' | 'logo_pro'
  cookieModalStyle?: {
    hide?: boolean
  }
  children?: React.ReactNode
}

const Layout: React.FC<Props> = ({
  children,
  mainStyle,
  footerStyle,
  headerStyle,
  cookieModalStyle,
  style,
  logo,
}) => {
  const isClientSide = useClientSide()
  const router = useRouter()
  const { t } = useTranslation()
  const { t: tNavigation } = useTranslation('components.navigation')
  const dispatch = useAppDispatch()
  const { cookieModal, cookieModalHeight } = useAppState()
  const { visible, setVisible, subscription, handleSubmit } = useLinkCard({
    blockPathName: [
      '/product/[productSlug]',
      '/product/[productSlug]/order',
      '/product/[productSlug]/order/course',
      '/[category]/[productSlug]',
      '/[category]/[productSlug]/order',
      '/[category]/[productSlug]/order/course',
      '/[category]/[productSlug]/orderb',
      '/[category]/[productSlug]/ordersecond',
      '/[category]/[productSlug]/webinar',
      '/payments/check',
      '/payments/form',
    ],
  })
  const headerRef = useRef<HTMLHeadElement>(null)
  const [headerHeight, setHeaderHeight] = useState<number | null>(null)
  // cookieModalRef
  const cookieModalRef = React.useRef<HTMLDivElement>(null)
  // aff_rid
  useAffrid()
  // save cookieModal height position in context
  useEffect(() => {
    if (!cookieModalRef.current) return
    const { height } = cookieModalRef.current.getBoundingClientRect()
    if (cookieModal && cookieModalRef.current) {
      dispatch({
        type: 'SET_COOKIE_MODAL_HEIGHT',
        cookieModalHeight: height,
      })
    }
  }, [cookieModalRef.current, cookieModal, dispatch])
  // save cookieModal height position in context when resize
  useEffect(() => {
    if (!cookieModalRef.current) return
    window.addEventListener('resize', resizeEventCookieModal)
    return () => {
      window.removeEventListener('resize', resizeEventCookieModal)
    }
  }, [cookieModalRef.current])

  const resizeEventCookieModal = () => {
    if (!cookieModalRef.current) return
    const { height } = cookieModalRef.current.getBoundingClientRect()
    if (cookieModalHeight !== height) {
      dispatch({
        type: 'SET_COOKIE_MODAL_HEIGHT',
        cookieModalHeight: height,
      })
    }
  }
  // setHeader height
  useEffect(() => {
    if (!headerRef.current) return
    const { height } = headerRef.current.getBoundingClientRect()
    if (headerRef.current) {
      setHeaderHeight(height)
    }
  }, [headerRef.current])

  // Notification
  // useNotification()
  const handleNavigationItemClick = (name: string) => {
    router.replace(
      {
        query: { ...router.query, navigation: name },
      },
      undefined,
      { shallow: true },
    )
  }
  return (
    <StyledContainer>
      <Header
        hideCatalog={headerStyle?.hideCatalog}
        hideRightSide={headerStyle?.hideRightSide}
        style={headerStyle?.style}
        ref={headerRef}
        logo={logo}
      />

      <Alert
        visible={visible}
        type={AlertType.WARN}
        alertStyle={{ position: 'fixed', top: headerHeight }}
        close={<Icon name='close' />}
        onClose={() => setVisible()}
      >
        {subscription && subscription.status === 1 && (
          <Trans
            t={t}
            i18nKey='alert.created'
            components={{
              StyledAlertAccents: <StyledAlertAccents onClick={handleSubmit} />,
            }}
            values={{ title: subscription.title }}
          />
        )}
        {subscription && subscription.status === 3 && (
          <Trans
            t={t}
            i18nKey='alert.suspended'
            components={{
              StyledAlertAccents: <StyledAlertAccents onClick={handleSubmit} />,
            }}
            values={{ title: subscription.title }}
          />
        )}
        {subscription && subscription.status === 4 && (
          <Trans
            t={t}
            i18nKey='alert.stopped'
            components={{
              StyledAlertAccents: <StyledAlertAccents onClick={handleSubmit} />,
            }}
            values={{ title: subscription.title }}
          />
        )}
      </Alert>

      <StyledMain mainStyle={mainStyle} style={style}>
        {children}
      </StyledMain>
      <Footer footerStyle={footerStyle} />
      {/* Navigation для урока и теста */}
      {isClientSide ? (
        <Navigation.Base initial={Navigation.Type.PROGRAMS}>
          <Navigation.Menu>
            {/* programs */}
            <Navigation.Item
              name={Navigation.Type.PROGRAMS}
              onClick={props => {
                handleNavigationItemClick(props.name)
              }}
            >
              <Icon width='26' height='25' name='note' />
              <Navigation.Text className='navigation_text'>
                {tNavigation('programs')}
              </Navigation.Text>
            </Navigation.Item>
            {/* lessons */}
            <Navigation.Item
              name={Navigation.Type.LESSONS}
              onClick={props => {
                handleNavigationItemClick(props.name)
              }}
            >
              <Icon width='25' height='24' name='information' />
              <Navigation.Text className='navigation_text'>
                {tNavigation('lessons')}
              </Navigation.Text>
            </Navigation.Item>
            {/* materials */}
            <Navigation.Item
              name={Navigation.Type.MATERIALS}
              onClick={props => {
                handleNavigationItemClick(props.name)
              }}
            >
              <Icon width='24' height='24' name='clipboard' />
              <Navigation.Text className='navigation_text'>
                {tNavigation('materials')}
              </Navigation.Text>
            </Navigation.Item>
          </Navigation.Menu>
        </Navigation.Base>
      ) : null}
      {/* Modal */}
      <Modal />
      <Hide hide={!!cookieModalStyle?.hide}>
        <ModalCookie ref={cookieModalRef} />
      </Hide>
      <ModalYkassa />
      <ModalCommentVideo />
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.flexDirection('column')}
  height: 100vh;
`

const StyledMain = styled.main<Style>`
  ${({ theme }) => theme.mixins.flex('1', '0', 'auto')}
  padding-top: 108px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    padding-top: 138px;
  }
  ${({ mainStyle }) => {
    switch (mainStyle) {
      case MainStyle.MAIN:
        return css`
          ${({ theme }) => theme.mixins.flex('1', '0', 'auto')}
          padding-top: 108px;
          @media ${({ theme }) => theme.devices.mobileLgUp} {
            padding-top: 138px;
          }
        `
      case MainStyle.MAIN_WITHOUT_HEADER_PADDING:
        return css`
          ${({ theme }) => theme.mixins.flex('1', '0', 'auto')}
          padding-top: 77px;
          @media ${({ theme }) => theme.devices.mobileLgUp} {
            padding-top: 79px;
          }
        `
      case MainStyle.MAINPTLG:
        return css`
          padding-top: 118px;
          @media ${({ theme }) => theme.devices.mobileLgUp} {
            padding-top: 186px;
          }
          @media ${({ theme }) => theme.devices.noteUp} {
            padding-top: 206px;
          }
        `
      case MainStyle.MAINPTS:
        return css`
          padding-top: 83px;
          @media ${({ theme }) => theme.devices.mobileMdUp} {
            padding-top: 90px;
          }
        `
      case MainStyle.MAIN_COURSE:
        return css`
          @media ${({ theme }) => theme.devices.mobileMdUp} {
            padding-top: calc(50px + 65px);
          }
          @media ${({ theme }) => theme.devices.noteUp} {
            padding-top: calc(90px + 65px);
          }
        `
      default:
        break
    }
  }}
`

const StyledAlertAccents = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: underline;
  cursor: pointer;
`

export default Layout
