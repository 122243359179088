import React from 'react'
import styled from 'styled-components'
/**
 * ui
 */
import Button, { ButtonSize, ButtonStyle } from '../Button'
import { TModal } from './Modal'
/**
 * hooks
 */
import usePayments from '../../hooks/usePayments'
import useHeaders from '../../hooks/useHeaders'
/**
 * api
 */
import { TInitialPayType, useSubscriptions } from '../../api/subscription'
/**
 * context
 */
import { useAppState, useAppDispatch } from '../../context/app'

const ModalSelectContinueOrInstallment: React.FC = () => {
  const dispatch = useAppDispatch()
  const { handleAccessType } = usePayments()
  const { headers, status } = useHeaders()
  const { slug } = useAppState()

  // react-queries
  const { data, isLoading } = useSubscriptions.useGetSubscriptionBySlug(
    headers,
    slug,
  )
  const handleContinue = () => {
    if (data) {
      handleAccessType({
        accessType: data.accessType,
        slug: data.slug,
        currentPayment: data.currentPayment,
        token: headers['X-Auth-Token'],
      })
    }
  }

  const handleInstallmentModal = React.useCallback(async () => {
    dispatch({
      type: 'SET_SLUG',
      slug,
    })
    dispatch({
      type: 'SET_MODAL',
      modalShown: true,
      modalType: TModal.INSTALLMENT,
    })
  }, [])

  if (!data || isLoading || status === 'unauthenticated') {
    return null
  }
  const getText = (type: TInitialPayType) => {
    if (type === 'course') {
      return 'оплата курса полностью'
    }
    if (type === 'firstFree') {
      return `оплату картой 1Р`
    }
    if (type === 'trial') {
      return 'оплата курса триалом'
    }
    return ''
  }
  return (
    <StyledContainer>
      <StyledTitle>
        Ранее Вы хотели совершить первый платеж {getText(data.initialPayType)}.
        <br />
        Для продолжения курса завершите платеж
        {data.installment ? ', или выберите оплату в рассрочку?' : ''}
      </StyledTitle>

      <StyledButtonContainer>
        <Button
          buttonStyle={ButtonStyle.FILLED}
          size={ButtonSize.BLOCK}
          onClick={() => handleContinue()}
        >
          Продолжить
        </Button>
      </StyledButtonContainer>

      {/* проверка что позволяет ли рассрочку */}
      {data.installment && (
        <Button
          buttonStyle={ButtonStyle.OUTLINED}
          size={ButtonSize.BLOCK}
          onClick={handleInstallmentModal}
          type='button'
        >
          Платить с рассрочкой
        </Button>
      )}
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  background: #ffffff;
  box-shadow: 0 4px 54px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  width: 100%;
  padding: calc(35px + 21px) 24px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    padding: 68px 97px;
    max-width: 555px;
  }
`

const StyledTitle = styled.h4`
  font-weight: 600;
  font-size: 24px;
  line-height: 140%;
  text-align: center;
  margin-bottom: 16px;
`

const StyledButtonContainer = styled.div`
  margin-bottom: 10px;
`

export default ModalSelectContinueOrInstallment
