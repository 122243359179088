import { useState } from 'react'
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query'
import { useTranslation } from 'next-i18next'
import { AxiosResponse, AxiosError } from 'axios'
import { api, apiPaymentChecker } from '../utils/http'
import { ILessonView2 } from './test'
import { TCard } from './card'
import { notify, ToastIcon } from '../components/Toast'
import { ALERT_CLOSE_WAIT } from '../constants'
import { IError } from './error'
import { useLocalStorage } from 'usehooks-ts'
import { ILocalStorage } from '../hooks/useLocalStorage/useLocalStorage'
import Metrika from '../utils/Metrika'
// interceptor payment checker
apiPaymentChecker.interceptors.response.use(
  res => {
    const originalRequest = res.config
    if (res.data.payment && !res.data.payment.isFinished) {
      return new Promise(resolve => {
        setTimeout(() => resolve(apiPaymentChecker(originalRequest)), 3000)
      })
    }
    return res
  },
  error => {
    return Promise.reject(error)
  },
)
export type TInitialPayType =
  | 'trial'
  | 'module'
  | 'course'
  | 'firstFree'
  | 'firstDelivered'
/**
 * status - STATUS_CREATED = 1; STATUS_ACTIVE = 2;STATUS_SUSPENDED = 3;STATUS_STOPPED = 4;
 * stopReason -
 * STOP_REASON_PAID = 1;
 * STOP_REASON_FREE = 2;
 * STOP_REASON_CARD_INVALID = 3;
 * STOP_REASON_CARD_REMOVED = 4;
 * STOP_REASON_PAY_ERROR = 5;
 * STOP_REASON_PAY_SCHEDULE_TIMEOUT = 6;
 * STOP_REASON_PAY_GIFT = 7
 */

export interface ISubscriptions {
  slug: string
  title: string
  initialPayType: TInitialPayType
  isAvailable: boolean
  isCompletelyAvailable: boolean
  accessType: number
  activeFrom: string | null
  currency: string | null
  totalPrice: number
  fullPayDiscountPercent: number
  paidAmount: number | null
  remainingAmount: number
  status: number
  stopReason: number | null
  canBeSurcharged: boolean
  modulesCount: number
  lessonsCount: number
  viewedLessonsCount: number
  viewProgress: number
  canBeSurchargedWithDiscount: boolean
  surchargeAmount: number | null
  card: TCard | null
  schedule: {
    at: string | null
    inDays: number | null
    action: string
    moduleNumbers: number[] | null
    lessonNumber: number | null
    amount: number | null
  } | null
  currentPayment: ICurrentPayment | null
  canBeSuspended: boolean
  canBeResumed: boolean
  canCardBeAttached: boolean
  initialAmounts: {
    [type: string]: number
  } | null
  error?: {
    message: string
  }
  canCertBeGenerated: boolean | null
  isGraduated: boolean
  installment?: {
    amount: number
    systems: string[]
  }
}

export type TPaymentsSystem =
  | 'dumb'
  | 'cloudPayments'
  | 'yookassa'
  | 'cloudSoft'
  | 'tinkoff'

export interface ICurrentPayment {
  type: number
  system: TPaymentsSystem
  code: string
  status: number
  isFinished: boolean
  amount: number
  currency: string
  createdAt: string
  paidAt: string | null
  canBeCancelled: boolean
  card: TCard | null
}

export interface IPay {
  payment: {
    type: 1 | 2 | 3
    system: TPaymentsSystem
    code: string
    status: number
    isFinished: boolean
    createdAt: string
    paidAt: string | null
    card: TCard | null
  } | null
  redirectTo: string | null
  widgetData?: { confirmationToken: string; data?: any; method?: string } | any
  success: boolean | null
  message: string | null
  error?: {
    message: string
  }
}

export interface IModuleView {
  title: string
  number: number
  days: number
  lessonCount: number
  isAvailable: boolean | null
  isViewed: boolean
  payAmount: number | null
  payAt: string | null
  availableAt: string | null
  lessons: {
    type: number
    title: string
    imagePath: string | null
    number: number
    days: number
    isAvailable: boolean
    isViewed: boolean
    payAmount: number | null
    payAt: string | null
    availableAt: string | null
  }[]
}

/*
 * type - TYPE_LESSON = 1, TYPE_TEST = 2
 */
export interface ILessonView {
  type: number
  title: string
  imagePath: string | null
  number: number
  moduleNumber: number
  days: number
  isAvailable: boolean
  isViewed: boolean
  payAmount: number | null
  payAt: string | null
  availableAt: string | null
}

export interface ICertificateView {
  success: boolean
  url: string
}

const subscriptions = {
  getSubscriptions: (
    headers: {
      [key: string]: string
    },
    predefined: string = '',
  ): Promise<ISubscriptions[]> => {
    return api
      .get('/api/subscriptions', { headers, params: { predefined } })
      .then(res => res.data)
  },
  getSubscriptionBySlug: async (
    headers: { [key: string]: string },
    slug: string,
  ): Promise<ISubscriptions> => {
    return api
      .get(`/api/subscriptions/${slug}`, { headers })
      .then(res => res.data)
  },
  getSubscriptionBySlugModules: async (
    headers: { [key: string]: string },
    slug: string,
  ): Promise<IModuleView[]> => {
    return api
      .get(`/api/subscriptions/${slug}/modules`, { headers })
      .then(res => res.data)
  },
  getSubscriptionBySlugModulesLessons: (
    headers: { [key: string]: string },
    slug: string,
    moduleNumber: number | undefined,
  ): Promise<ILessonView[]> => {
    return api
      .get(`/api/subscriptions/${slug}/modules/${moduleNumber}/lessons`, {
        headers,
      })
      .then(res => res.data)
  },
  getSubscriptionBySlugLessons: (
    headers: { [key: string]: string } | null,
    slug: string,
    moduleNumber: string,
    lessonNumber: string,
  ): Promise<ILessonView2> => {
    return api
      .get(
        `/api/subscriptions/${slug}/modules/${moduleNumber}/lessons/${lessonNumber}`,
        {
          headers: headers ?? {},
        },
      )
      .then(res => res.data)
  },
  getCertification: (body: ICertificateBody): Promise<ICertificateView> => {
    return api
      .get(`/api/subscriptions/${body.slug}/cert`, {
        headers: body.headers,
        params: {
          variant: body.variant,
          download: body.download,
        },
      })
      .then(res => res.data)
  },
  postSubscriptionsBySlugPay: (
    headers: {
      [key: string]: string
    },
    slug: string,
    body: {
      card: string | null
      capture: boolean
      cancelCurrent?: boolean
      type?: TInitialPayType | null
    },
    utm?: string,
  ): Promise<AxiosResponse<IPay>> => {
    const url = utm
      ? `/api/subscriptions/${slug}/pay` + `${utm}`
      : `/api/subscriptions/${slug}/pay`
    return api.post(url, body, {
      headers,
    })
  },
  postSubscriptionsInstallment: (
    headers: {
      [key: string]: string
    },
    slug: string,
    body: {
      system: string
      cancelCurrent?: boolean
    },
    utm?: string,
  ): Promise<AxiosResponse<IPay>> => {
    const url = utm
      ? `/api/subscriptions/${slug}/pay/installment` + `${utm}`
      : `/api/subscriptions/${slug}/pay/installment`
    return api.post(url, body, {
      headers,
    })
  },
  postSubscriptionsBySlugSuspend: (
    headers: { [key: string]: string },
    slug: string,
    days?: number,
    utm?: string,
  ): Promise<AxiosResponse<ISubscriptions>> => {
    const url = utm
      ? `/api/subscriptions/${slug}/suspend` + `${utm}`
      : `/api/subscriptions/${slug}/suspend`
    return api.post(
      url,
      days
        ? {
            days,
          }
        : null,
      {
        headers,
      },
    )
  },
  postSubscriptionsBySlugResume: (
    headers: { [key: string]: string },
    slug: string,
    utm?: string,
  ): Promise<AxiosResponse<ISubscriptions>> => {
    const url = utm
      ? `/api/subscriptions/${slug}/resume` + `${utm}`
      : `/api/subscriptions/${slug}/resume`
    return api.post(url, null, {
      headers,
    })
  },
  postSubscriptionsCardsCapture: (
    headers: { [key: string]: string },
    slug: string,
    utm?: string,
  ) => {
    const url = utm
      ? `/api/subscriptions/${slug}/cards/capture` + `${utm}`
      : `/api/subscriptions/${slug}/cards/capture`
    return api.post(url, null, {
      headers,
    })
  },
  postSubscriptionsCardsAttach: (
    headers: { [key: string]: string },
    slug: string,
    cardUuid: string,
    utm?: string,
  ) => {
    const url = utm
      ? `/api/subscriptions/${slug}/cards/${cardUuid}/attach` + `${utm}`
      : `/api/subscriptions/${slug}/cards/${cardUuid}/attach`
    return api.post(url, null, {
      headers,
    })
  },
}
export type TSubscriptionFilters = '' | 'in_progress' | 'completed'

export const useSubscriptions = {
  useGetSubscriptions: (
    headers: {
      [key: string]: string
    },
    predefined: string = '',
  ) => {
    return useQuery<ISubscriptions[], AxiosError>(
      ['getSubscriptions', predefined],
      () => subscriptions.getSubscriptions(headers, predefined),
      {
        enabled: !!headers['X-Auth-Token'],
      },
    )
  },
  useGetSubscriptionBySlug: (
    headers: { [key: string]: string },
    slug: string,
  ) => {
    return useQuery<ISubscriptions, AxiosError>(
      ['getSubscriptionBySlug'],
      () => subscriptions.getSubscriptionBySlug(headers, slug),
      {
        enabled: !!headers['X-Auth-Token'] && !!slug,
      },
    )
  },
  useGetSubscriptionBySlugModules: (
    headers: { [key: string]: string },
    slug: string,
    options?: Omit<
      UseQueryOptions<unknown, AxiosError, IModuleView[], string[]>,
      'queryKey' | 'queryFn'
    >,
  ): UseQueryResult<IModuleView[], AxiosError> => {
    return useQuery(
      ['getSubscriptionBySlugModules', slug],
      () => subscriptions.getSubscriptionBySlugModules(headers, slug),
      {
        enabled: !!headers['X-Auth-Token'],
        ...options,
      },
    )
  },
  useGetSubscriptionBySlugModulesLessons: (
    headers: { [key: string]: string },
    slug: string,
    moduleNumber: number | undefined,
    modulesData?: boolean,
  ) => {
    return useQuery<ILessonView[], AxiosError>(
      ['useGetSubscriptionBySlugModulesLessons', moduleNumber],
      () =>
        subscriptions.getSubscriptionBySlugModulesLessons(
          headers,
          slug,
          moduleNumber,
        ),
      {
        enabled: modulesData,
      },
    )
  },
  useGetSubscriptionBySlugLessons: (
    headers: { [key: string]: string },
    slug: string,
    moduleNumber: string,
    lessonNumber: string,
  ) => {
    return useQuery<ILessonView2, AxiosError>(
      ['useGetSubscriptionBySlugLessons', moduleNumber, lessonNumber, slug],
      () =>
        subscriptions.getSubscriptionBySlugLessons(
          headers,
          slug,
          moduleNumber,
          lessonNumber,
        ),
      {
        enabled: !!headers['X-Auth-Token'],
      },
    )
  },
  useGetCertification: (body: ICertificateBody) => {
    return useQuery<ICertificateView, AxiosError>(
      ['getCertification', body.slug, body.download, body.variant],
      () => subscriptions.getCertification(body),
      {
        enabled:
          !!body.headers['X-Auth-Token'] &&
          !!body.slug &&
          !!body.variant &&
          body.download === 1,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        refetchInterval: 0,
        staleTime: 0,
        cacheTime: 0,
      },
    )
  },
}
export interface TSubscriptionsBySlugPayBody {
  headers: {
    [key: string]: string
  }
  slug: string
  isAuth?: boolean
  body: {
    card: string | null
    capture: boolean
    cancelCurrent?: boolean
    type?: TInitialPayType | null
  }
  utm?: string
}

export interface ISubscriptionsInstallmentBody {
  headers: {
    [key: string]: string
  }
  slug: string
  body: {
    system: string
    cancelCurrent?: boolean
  }
  utm?: string
}

export interface ISubscriptionsBySlugSuspendBody {
  headers: {
    [key: string]: string
  }
  slug: string
  days?: number
  utm?: string
}

interface ISubscriptionsCardAttachBody extends ISubscriptionsBySlugSuspendBody {
  cardUuid: string
}

export type TCardAdd = {
  redirectTo?: string
  widgetData?: {
    confirmationToken?: string
  }
  success: boolean
  system?: string
  error?: {
    message: string
  }
}

export interface ICertificateBody {
  headers: {
    [key: string]: string
  }
  variant: 'white' | 'black' | 'green'
  download: number
  slug: string
}

export const useSubscriptionsMutation = {
  usePostSubscriptionsBySlugPay: () => {
    const [local] = useLocalStorage<ILocalStorage>('willskill', {
      cookieAgree: false,
      utm: '',
    })
    return useMutation<
      AxiosResponse<IPay>,
      AxiosError<IError>,
      TSubscriptionsBySlugPayBody
    >(
      formData => {
        if (local.utm) {
          formData.utm = decodeURIComponent(local.utm)
        }
        return subscriptions.postSubscriptionsBySlugPay(
          formData.headers,
          formData.slug,
          formData.body,
          formData.utm,
        )
      },
      {
        onError: ({ response }) => {
          notify({
            icon: ToastIcon.ERROR,
            autoClose: ALERT_CLOSE_WAIT,
            label: response?.data?.error?.message ?? '',
          })
        },
      },
    )
  },
  usePostSubscriptionsInstallment: () => {
    const [local] = useLocalStorage<ILocalStorage>('willskill', {
      cookieAgree: false,
      utm: '',
    })
    return useMutation<
      AxiosResponse<IPay>,
      AxiosError<IError>,
      ISubscriptionsInstallmentBody
    >(
      formData => {
        if (local.utm) {
          formData.utm = decodeURIComponent(local.utm)
        }
        return subscriptions.postSubscriptionsInstallment(
          formData.headers,
          formData.slug,
          formData.body,
          formData.utm,
        )
      },
      {
        onError: ({ response }) => {
          notify({
            icon: ToastIcon.ERROR,
            autoClose: ALERT_CLOSE_WAIT,
            label: response?.data?.error?.message ?? '',
          })
        },
      },
    )
  },
  usePostSubscriptionsBySlugResume: () => {
    const { t } = useTranslation()
    const [metrika] = useState(() => new Metrika())
    const [local] = useLocalStorage<ILocalStorage>('willskill', {
      cookieAgree: false,
      utm: '',
    })
    return useMutation<
      AxiosResponse<ISubscriptions>,
      AxiosError,
      Omit<ISubscriptionsBySlugSuspendBody, 'days'>
    >(
      formData => {
        if (local.utm) {
          formData.utm = decodeURIComponent(local.utm)
        }
        return subscriptions.postSubscriptionsBySlugResume(
          formData.headers,
          formData.slug,
          formData.utm,
        )
      },
      {
        onError: () => {
          notify({
            icon: ToastIcon.ERROR,
            autoClose: ALERT_CLOSE_WAIT,
            label: t('notify.subscriptionsBySlugResume.failed'),
          })
        },
        onSuccess: async () => {
          metrika
            .willskillYandex({
              methos: 'reachGoal',
              targetNames: 'sub_start',
            })
            .offerrmYandex({
              methos: 'reachGoal',
              targetNames: 'sub_start',
            })
          notify({
            icon: ToastIcon.SUCCESS,
            autoClose: ALERT_CLOSE_WAIT,
            label: t('notify.subscriptionsBySlugResume.success'),
          })
        },
      },
    )
  },
  usePostSubscriptionsBySlugSuspend: () => {
    const { t } = useTranslation()
    const [metrika] = useState(() => new Metrika())
    const [local] = useLocalStorage<ILocalStorage>('willskill', {
      cookieAgree: false,
      utm: '',
    })
    return useMutation<
      AxiosResponse<ISubscriptions>,
      AxiosError,
      ISubscriptionsBySlugSuspendBody
    >(
      formData => {
        if (local.utm) {
          formData.utm = decodeURIComponent(local.utm)
        }
        return subscriptions.postSubscriptionsBySlugSuspend(
          formData.headers,
          formData.slug,
          formData.days,
          formData.utm,
        )
      },
      {
        onSuccess: async () => {
          metrika
            .willskillYandex({
              methos: 'reachGoal',
              targetNames: 'sub_stop',
            })
            .offerrmYandex({
              methos: 'reachGoal',
              targetNames: 'sub_stop',
            })
          notify({
            icon: ToastIcon.SUCCESS,
            autoClose: ALERT_CLOSE_WAIT,
            label: t('notify.subscriptionsBySlugSuspend.success'),
          })
        },
      },
    )
  },
  usePostSubscriptionsCardsCapture: () => {
    const { t } = useTranslation()
    const [local] = useLocalStorage<ILocalStorage>('willskill', {
      cookieAgree: false,
      utm: '',
    })
    return useMutation<
      AxiosResponse<IPay>,
      AxiosError,
      Omit<ISubscriptionsBySlugSuspendBody, 'days'>
    >(
      formData => {
        if (local.utm) {
          formData.utm = decodeURIComponent(local.utm)
        }
        return subscriptions.postSubscriptionsCardsCapture(
          formData.headers,
          formData.slug,
          formData.utm,
        )
      },
      {
        onError: () => {
          notify({
            icon: ToastIcon.ERROR,
            autoClose: ALERT_CLOSE_WAIT,
            label: t('notify.subscriptionsCardsCapture.failed'),
          })
        },
      },
    )
  },
  usePostSubscriptionsCardsAttach: () => {
    const [local] = useLocalStorage<ILocalStorage>('willskill', {
      cookieAgree: false,
      utm: '',
    })
    return useMutation<
      AxiosResponse<TCardAdd>,
      AxiosError<IError>,
      Omit<ISubscriptionsCardAttachBody, 'days'>
    >(
      formData => {
        if (local.utm) {
          formData.utm = decodeURIComponent(local.utm)
        }
        return subscriptions.postSubscriptionsCardsAttach(
          formData.headers,
          formData.slug,
          formData.cardUuid,
          formData.utm,
        )
      },
      {
        onError: ({ response }) => {
          notify({
            icon: ToastIcon.ERROR,
            autoClose: ALERT_CLOSE_WAIT,
            label: response?.data?.error?.message ?? '',
          })
        },
      },
    )
  },
}
export default subscriptions
