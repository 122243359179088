import React from 'react'
import styled from 'styled-components'
/**
 * api
 */
import { useProductQuery } from '../../api/product'
import { useProfileQuery } from '../../api/profile'
import { useSubscriptions } from '../../api/subscription'
/**
 * hooks
 */
import useHeaders from '../../hooks/useHeaders'
/**
 * context
 */
import { useAppState } from '../../context/app'
/**
 * ui
 */
import Form, { FormType } from '../../components/Form'

const ModalForm: React.FC = () => {
  const { slug } = useAppState()
  const { headers, isLoggedIn, status } = useHeaders()
  const { data } = useProductQuery.useGetProductsSlug(
    slug,
    isLoggedIn, // query key "isLoggedIn"
    headers,
  )
  const { data: profileData } = useProfileQuery.useGetProfile(headers)
  const { data: subscriptionData } = useSubscriptions.useGetSubscriptionBySlug(
    headers,
    slug,
  )
  if (
    !data ||
    !profileData ||
    !slug ||
    status === 'unauthenticated' ||
    !subscriptionData
  ) {
    return null
  }

  return (
    <StyledContinaer>
      <Form
        slug={data.slug}
        category={data.category.title}
        productName='Оформить заказ'
        modulesCount={data.modulesCount}
        lessonsCount={data.lessonsCount}
        fullPrice={data.fullPrice}
        initialAmounts={data.initialAmounts}
        fullPayDiscountPercent={data.fullPayDiscountPercent}
        formType={FormType.MODAL}
        subscription={subscriptionData}
        accessType={data.accessType}
        nextBlock={data.nextBlock}
        subscriptionType='installment'
        installment={data.installment}
        currency={data.currency}
        title={data.title}
      />
    </StyledContinaer>
  )
}

export default ModalForm

const StyledContinaer = styled.div`
  background: #ffffff;
  box-shadow: 0 4px 54px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
`
