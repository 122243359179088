const Hide = ({
  children,
  hide = false,
}: {
  children: any
  hide?: boolean
}) => {
  return !hide ? children : null
}

export default Hide
