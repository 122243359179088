const checkCookieName = (name: string): string | void => {
  const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'))
  if (match) {
    return match[2]
  } else {
    console.log('Not matched same cookie name in /src/utils/checkCookieName')
  }
}

export default checkCookieName
