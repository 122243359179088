import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'next-i18next'
/**
 * context
 */
import { useAppDispatch, useAppState } from '../../context/app'
import { TModal } from './Modal'
/**
 * ui
 */
import Button, { ButtonSize, ButtonStyle } from '../Button'
import Tag, { TagType, TagSize } from '../Tag'
/**
 * api
 */
import { useProductQuery } from '../../api/product'
import { useSubscriptions } from '../../api/subscription'
/**
 * hooks
 */
import useHeaders from '../../hooks/useHeaders'
import usePayments from '../../hooks/usePayments'

const ModalSelectPaymentsType: React.FC = () => {
  const { t } = useTranslation('components.modal')
  const { headers, isLoggedIn } = useHeaders()
  const dispatch = useAppDispatch()
  const { slug } = useAppState()
  const { handleCurrentPayment } = usePayments()
  // queries
  const { data, isLoading } = useProductQuery.useGetProductsSlug(
    slug,
    isLoggedIn,
    headers,
  )
  const { data: subscriptionData, isLoading: subscriptionIsLoading } =
    useSubscriptions.useGetSubscriptionBySlug(headers, slug)

  const setCloseModal = React.useCallback(
    () =>
      dispatch({
        type: 'SET_MODAL',
        modalShown: false,
        modalType: TModal.BLANK,
      }),
    [],
  )

  const setPaymentModal = React.useCallback(() => {
    dispatch({
      type: 'SET_MODAL',
      modalShown: true,
      modalType: TModal.PAYMENT,
    })
  }, [])

  const setInstallmentModal = React.useCallback(() => {
    dispatch({
      type: 'SET_MODAL',
      modalShown: true,
      modalType: TModal.INSTALLMENT,
    })
  }, [])

  if (!data || isLoading || !subscriptionData || subscriptionIsLoading) {
    return null
  }

  return (
    <StyledContainer>
      <StyledTitle>{t('ModalSelectPaymentsType.title')}</StyledTitle>
      {subscriptionData.installment && (
        <StyledButtonContainer>
          <StyledTagContainer>
            <Tag
              label={t('ModalSelectPaymentsType.tag')}
              tagType={TagType.CUSTOM_1}
              size={TagSize.PADDING}
              beforeIcon={'success-tick'}
            />
          </StyledTagContainer>

          <Button
            buttonStyle={ButtonStyle.FILLED}
            size={ButtonSize.BLOCK}
            onClick={setInstallmentModal}
          >
            {t('ModalSelectPaymentsType.secondType')}
          </Button>
        </StyledButtonContainer>
      )}

      {data.fullPayDiscountPercent && subscriptionData.canBeSurcharged && (
        <StyledDiscountText>
          {t('ModalSelectPaymentsType.discount')} {data.fullPayDiscountPercent}%
        </StyledDiscountText>
      )}
      {subscriptionData.canBeSurcharged && (
        <Button
          buttonStyle={ButtonStyle.OUTLINED}
          size={ButtonSize.BLOCK}
          onClick={setPaymentModal}
        >
          {t('ModalSelectPaymentsType.firstType')}
        </Button>
      )}
      {subscriptionData.status === 1 && !subscriptionData.canBeSurcharged && (
        <Button
          buttonStyle={ButtonStyle.OUTLINED}
          size={ButtonSize.BLOCK}
          onClick={() => {
            handleCurrentPayment({
              slug: subscriptionData.slug,
              currentPayment: subscriptionData.currentPayment,
            })
          }}
        >
          {t('ModalSelectPaymentsType.pay')}
        </Button>
      )}
      <StyledCancel onClick={setCloseModal}>
        {t('ModalSelectPaymentsType.cancel')}
      </StyledCancel>
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  background: #ffffff;
  box-shadow: 0 4px 54px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  width: 100%;
  padding: calc(35px + 21px) calc(24px + 15px);
  max-width: 100%;
  overflow: hidden;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    padding: 68px 97px;
    width: 555px;
  }
`

const StyledTitle = styled.h4`
  text-align: center;
  margin-bottom: 33px;
  font-weight: 600;
  font-size: 24px;
  line-height: 140%;
`

const StyledButtonContainer = styled.div`
  margin-bottom: 10px;
`

const StyledCancel = styled.button`
  width: 100%;
  margin-top: 10px;
  ${({ theme }) => theme.mixins.textButton};
  color: ${({ theme }) => theme.colors.primary};
  padding: 13px 15px;
  text-align: center;
`

const StyledTagContainer = styled.div`
  margin-bottom: 4px;
`

const StyledDiscountText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 4px;
`
export default ModalSelectPaymentsType
