import React from 'react'
import styled from 'styled-components'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'next-i18next'
/**
 * context
 */
import { useAppDispatch, useAppState } from '../../context/app'
/**
 * ui
 */
import Input, { InputStyle } from '../Input'
import Button, { ButtonSize, ButtonStyle } from '../Button'
import { TModal } from './Modal'
import { notify, ToastIcon } from '../Toast'
/**
 * api
 */
import { useSecureMutation } from '../../api/secure'
/**
 * constants
 */
import { ALERT_CLOSE_WAIT } from '../../constants'
/**
 * hook
 * */
import useRecaptcha from '../../hooks/useRecaptcha'

type TFormValue = {
  email: string
}

const ModalForgot: React.FC = () => {
  const { recaptcha } = useAppState()
  const { handleRecaptcha } = useRecaptcha()
  const { t: tc } = useTranslation('common')
  const { t } = useTranslation('components.modal')
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    setError,
    formState: { isValid, errors, isSubmitting, isDirty },
  } = useForm<TFormValue>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      email: '',
    },
  })
  const dispatch = useAppDispatch()
  const requestMutation = useSecureMutation.usePostRequest()
  const handleForgot = (body: any) => {
    reset({
      email: '',
    })
    requestMutation
      .mutateAsync(body)
      .then(async ({ data }) => {
        if (!data?.success) {
          setValue('email', body.email)
          notify({
            icon: ToastIcon.ERROR,
            autoClose: ALERT_CLOSE_WAIT,
            label: tc('validations.forgotPassword.error'),
          })
        }
        if (data?.success) {
          notify({
            icon: ToastIcon.SUCCESS,
            autoClose: ALERT_CLOSE_WAIT,
            label: tc('validations.forgotPassword.success'),
          })
          dispatch({
            type: 'SET_MODAL',
            modalShown: false,
            modalType: TModal.BLANK,
          })
        }
      })
      .catch(err => {
        if (
          err.response.data.formValidationErrors &&
          err.response.data.formValidationErrors.recaptcha
        ) {
          setError('email', {
            type: 'manual',
            message:
              err.response.data.formValidationErrors.recaptcha[0].message ?? '',
          })
        }
      })
  }
  const onSubmit: SubmitHandler<TFormValue> = async data => {
    if (!recaptcha) {
      return handleForgot({ ...data, recaptcha: '' })
    }
    if (recaptcha) {
      const token = await handleRecaptcha()
      if (!token) {
        throw new Error('Recaptcha is not valid yet')
      }
      return handleForgot({ ...data, recaptcha: token })
    }
  }

  return (
    <StyledContainer onSubmit={handleSubmit(onSubmit)}>
      <StyledTitle>{t('ModalForgot.title')}</StyledTitle>
      <StyledInputWrapper>
        <Input
          errorMessage={errors?.email?.message}
          inputStyle={InputStyle.DEFAULT}
          type='email'
          label={t('ModalForgot.email')}
          {...register('email', {
            required: {
              value: true,
              message: tc('validations.common.required'),
            },
            pattern: {
              value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
              message: tc('validations.pattern.email'),
            },
          })}
        />
      </StyledInputWrapper>
      <StyledButtonWrapper>
        <Button
          buttonStyle={ButtonStyle.FILLED}
          size={ButtonSize.BLOCK}
          disabled={!isValid || isSubmitting || !isDirty}
          type='submit'
        >
          {t('ModalForgot.restorePassword')}
        </Button>
      </StyledButtonWrapper>
    </StyledContainer>
  )
}
export default ModalForgot

const StyledContainer = styled.form`
  background: #ffffff;
  box-shadow: 0 4px 54px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 35px 24px;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    padding: 68px 97px;
    max-width: 555px;
  }
`

const StyledTitle = styled.h4`
  ${({ theme }) => theme.mixins.H4};
  text-align: center;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    ${({ theme }) => theme.mixins.H3};
  }
`

const StyledInputWrapper = styled.div`
  margin-top: 24px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    margin-bottom: 25px;
  }
`

const StyledButtonWrapper = styled.div`
  width: 100%;
  margin-top: 25px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    width: 203px;
    margin: 0 auto;
  }
`
