import React from 'react'
import styled from 'styled-components'
import { useQueryClient } from 'react-query'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'next-i18next'
/**
 * context
 */
import { useAppDispatch, useAppState } from '../../context/app'
import { TModal } from './Modal'
/**
 * ui
 */
import Button, { ButtonSize, ButtonStyle } from '../Button'
import Radio from '../Radio'
/**
 * utils
 */
import useHeaders from '../../hooks/useHeaders'
/**
 * api
 */
import { useSubscriptionsMutation } from '../../api/subscription'

type TFormValue = {
  days: number
}

const ModalStopFinal: React.FC = () => {
  const { t } = useTranslation('components.modal')
  const dispatch = useAppDispatch()
  const { slug } = useAppState()
  const { headers } = useHeaders()
  const queryClient = useQueryClient()

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { isSubmitting },
  } = useForm<TFormValue>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      days: 7,
    },
  })
  // watch value in react hook form
  const daysValue = watch('days')
  // to register state of useForm
  React.useEffect(() => {
    register('days')
  }, [register])

  const suspendMutation =
    useSubscriptionsMutation.usePostSubscriptionsBySlugSuspend()

  const onSubmit: SubmitHandler<TFormValue> = (data, e) => {
    return suspendMutation
      .mutateAsync(
        e && e.target.value === 'period'
          ? { headers, slug, days: data.days }
          : { headers, slug },
      )
      .then(async () => {
        await queryClient.invalidateQueries(['getSubscriptions'])
        dispatch({
          type: 'SET_MODAL',
          modalShown: false,
          modalType: TModal.BLANK,
        })
      })
      .catch(() => {
        dispatch({
          type: 'SET_MODAL',
          modalShown: false,
          modalType: TModal.BLANK,
        })
      })
  }

  const onClickWeek = React.useCallback(() => {
    setValue('days', 7)
  }, [setValue])

  const onClickTwoWeek = React.useCallback(() => {
    setValue('days', 14)
  }, [setValue])

  return (
    <StyledContainer>
      <StyledTitle>{t('ModalStopFinal.title')}</StyledTitle>
      <StyledModalList>
        <StyledModalListItem>{t('ModalStopFinal.list1')}</StyledModalListItem>
        <StyledModalListItem>{t('ModalStopFinal.list2')}</StyledModalListItem>
        <StyledModalListItem>{t('ModalStopFinal.list3')}</StyledModalListItem>
      </StyledModalList>
      {/* radios */}
      <StyledRadioContainer>
        <StyledRadioItem onClick={onClickWeek}>
          <Radio label={t('ModalStopFinal.day7')} checked={daysValue === 7} />
        </StyledRadioItem>
        <StyledRadioItem onClick={onClickTwoWeek}>
          <Radio label={t('ModalStopFinal.day14')} checked={daysValue === 14} />
        </StyledRadioItem>
      </StyledRadioContainer>

      <StyledButtonContainer>
        <Button
          buttonStyle={ButtonStyle.FILLED}
          size={ButtonSize.BLOCK}
          onClick={e => handleSubmit(onSubmit)(e)}
          disabled={isSubmitting}
          value='period'
        >
          {t('ModalStopFinal.pauseSubscription')}
        </Button>
      </StyledButtonContainer>
      <StyledCancel
        disabled={isSubmitting}
        onClick={e => handleSubmit(onSubmit)(e)}
        value='complete'
      >
        {t('ModalStopFinal.stopSubscription')}
      </StyledCancel>
    </StyledContainer>
  )
}
const StyledContainer = styled.div`
  background: #ffffff;
  box-shadow: 0 4px 54px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  width: 100%;
  padding: calc(35px + 21px) calc(24px + 15px);
  overflow: hidden;
  max-width: 100%;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    padding: 68px 97px;
    max-width: 555px;
  }
`

const StyledTitle = styled.h4`
  ${({ theme }) => theme.mixins.H4};
  text-align: left;
  margin-bottom: 8px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    ${({ theme }) => theme.mixins.H3};
  }
`

const StyledModalList = styled.ul`
  margin-left: 20px;
  margin-bottom: 29px;
`

const StyledModalListItem = styled.li`
  ${({ theme }) => theme.mixins.textSmall};
  list-style: disc;
`

const StyledButtonContainer = styled.div`
  width: 100%;
  margin-bottom: 22px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    width: 231px;
  }
`

const StyledCancel = styled.button`
  color: ${({ theme }) => theme.colors.primary};
  ${({ theme }) => theme.mixins.textLink};
  text-decoration: underline;
  cursor: pointer;
`

const StyledRadioContainer = styled.div`
  margin-bottom: 35px;
`

const StyledRadioItem = styled.div`
  & + & {
    margin-top: 22px;
  }
`

export default ModalStopFinal
