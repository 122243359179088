export const navigateCourse = {
  pathname: `/courses`,
  query: {
    filter: 'in_progress',
  },
}

export const navigateCourseWithSuccessPayments = {
  ...navigateCourse,
  query: {
    filter: navigateCourse.query.filter,
    subscriptioncard: 'success',
  },
}
