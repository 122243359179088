/**
 * vendor
 */
import React, { useState } from 'react'
import { getSession } from 'next-auth/react'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
/**
 * ui
 */
import { TModal } from '../../components/Modal'
/**
 * api
 */
import {
  ICurrentPayment,
  IPay,
  TInitialPayType,
  useSubscriptionsMutation,
} from '../../api/subscription'
import { TCard, useCardQuery } from '../../api/card'
/**
 * context
 */
import { useAppDispatch } from '../../context/app'
/**
 * utils
 */
import { notify, ToastIcon } from '../../components/Toast'
import { ALERT_CLOSE_WAIT } from '../../constants'
import useHeaders from '../../hooks/useHeaders'
/**
 * constants
 */
import { navigateCourse } from '../../constants/router'

export interface IHandleCard {
  slug: string
  body?: {
    card: string | null
    capture: boolean
    cancelCurrent?: boolean
    type?: TInitialPayType | null
  }
  card?: TCard[] | undefined
  token?: string
}

export interface IHandleAccessType {
  accessType: number
  slug: string
  currentPayment: ICurrentPayment | null
  token?: string
}

export interface IUsePayments {
  slug?: string
}

const usePayments = ({ slug }: IUsePayments = {}) => {
  const { t } = useTranslation()
  const router = useRouter()
  const { headers } = useHeaders()
  const dispatch = useAppDispatch()
  const [, setInitialState] = useState({})
  const [payment, setPayment] = useState<IPay>()
  // queries
  const { data: cardData } = useCardQuery.useGetCards(headers)
  // mutation
  const payMutation = useSubscriptionsMutation.usePostSubscriptionsBySlugPay()
  const captureMutation =
    useSubscriptionsMutation.usePostSubscriptionsCardsCapture()

  const handleAddCard = React.useCallback(
    ({ slug }: { slug: string }) => {
      if (cardData && cardData.length > 0) {
        dispatch({
          type: 'SET_MODAL',
          modalShown: true,
          modalType: TModal.ADDCARD,
        })
        dispatch({
          type: 'SET_SLUG',
          slug,
        })
      }
      if (cardData && cardData.length === 0) {
        captureMutation
          .mutateAsync({
            headers,
            slug,
          })
          .then(async ({ data }) => {
            setPayment(data)
          })
      }
    },
    [cardData, dispatch, captureMutation, headers],
  )

  const handleCards = React.useCallback(
    ({
      slug,
      body = {
        card: null,
        capture: true,
      },
    }: IHandleCard) => {
      if (cardData && cardData.length > 0) {
        if (body.cancelCurrent) {
          dispatch({
            type: 'SET_CANCEL_CURRENT',
            cancelCurrent: true,
          })
        }

        dispatch({
          type: 'SET_MODAL',
          modalShown: true,
          modalType: TModal.PAYMENT,
        })
        dispatch({
          type: 'SET_SLUG',
          slug,
        })
      }
      if (cardData && cardData.length === 0) {
        return payMutation
          .mutateAsync({
            headers,
            slug,
            isAuth: true,
            body,
          })
          .then(async ({ data }) => {
            // setPayment(data)
            await handlePayment(data)
          })
      }
    },
    [cardData, dispatch, payMutation, headers],
  )

  const handleCurrentPayment = React.useCallback(
    async ({
      slug,
      currentPayment,
    }: Pick<IHandleAccessType, 'currentPayment' | 'slug' | 'token'>) => {
      if (currentPayment) {
        dispatch({
          type: 'SET_MODAL',
          modalShown: true,
          modalType: TModal.CANCEL_INSTALLMENT_PAYMENT,
        })
        dispatch({
          type: 'SET_SLUG',
          slug,
        })
      } else {
        await handleCards({ slug })
      }
    },
    [dispatch, handleCards],
  )
  const handleInstallmentCurrentPayment = React.useCallback(
    ({
      slug,
      currentPayment,
    }: Pick<IHandleAccessType, 'currentPayment' | 'slug' | 'token'>) => {
      dispatch({
        type: 'SET_SLUG',
        slug,
      })
      if (currentPayment) {
        dispatch({
          type: 'SET_MODAL',
          modalShown: true,
          modalType: TModal.CANCEL_INSTALLMENT_PAYMENT,
        })
      } else {
        dispatch({
          type: 'SET_MODAL',
          modalShown: true,
          modalType: TModal.SELECT_PAYMENTS_TYPE,
        })
      }
    },
    [],
  )

  const handleCurrentPaymentWebinar = React.useCallback(
    async ({
      slug,
      currentPayment,
    }: Pick<IHandleAccessType, 'currentPayment' | 'slug' | 'token'>) => {
      if (currentPayment) {
        dispatch({
          type: 'SET_MODAL',
          modalShown: true,
          modalType: TModal.CANCEL_PAYMENT_WEBINAR,
        })
        dispatch({
          type: 'SET_SLUG',
          slug,
        })
      } else {
        await handleCards({ slug })
      }
    },
    [dispatch, handleCards],
  )

  // Для авторизованного человека
  const handleAccessType = React.useCallback(
    ({ accessType, slug, currentPayment, token }: IHandleAccessType) => {
      // если продукт не бесплатно
      if (accessType === 1) {
        handleCurrentPayment({ slug, currentPayment, token })
      }
      // если продукт бесплатно
      if (accessType !== 1) {
        notify(
          {
            icon: ToastIcon.SUCCESS,
            autoClose: ALERT_CLOSE_WAIT,
            label: t('notify.purchase.success'),
          },
          () => {
            return router.push(navigateCourse)
          },
        )
      }
    },
    [router, handleCurrentPayment],
  )
  // Для first registration
  const handleAccessTypeFirstRegistration = React.useCallback(
    async ({
      accessType,
      slug,
    }: Omit<IHandleAccessType, 'token' | 'currentPayment'>) => {
      // если продукт не бесплатно
      if (accessType === 1) {
        const session = await getSession()
        if (session) {
          return payMutation
            .mutateAsync({
              headers: {
                'X-Auth-Token': session.token as string,
              },
              slug,
              isAuth: true,
              body: {
                card: null,
                capture: true,
              },
            })
            .then(({ data }) => {
              handlePayment(data)
            })
        }
      }
      // если продукт бесплатно
      if (accessType !== 1) {
        notify(
          {
            icon: ToastIcon.SUCCESS,
            autoClose: ALERT_CLOSE_WAIT,
            label: t('notify.purchase.success'),
          },
          () => {
            return router.push(navigateCourse)
          },
        )
      }
    },
    [router, payMutation],
  )
  // this method execute when has payments data
  const handlePayment = React.useCallback(
    (payment: IPay) => {
      if (
        !payment.redirectTo &&
        !payment.payment &&
        !payment.message &&
        payment.success
      ) {
        return router.push(navigateCourse)
      }
      // если есть рекурентная карта
      if (!payment.redirectTo && payment.payment && payment.success) {
        return router.push({
          pathname: '/payments/check',
          query: {
            code: payment.payment.code,
            asPath: router.asPath,
          },
        })
      }
      if (payment.redirectTo) {
        window.location.href = payment.redirectTo as string
      }
      if (payment.message) {
        notify({
          icon: ToastIcon.ERROR,
          autoClose: ALERT_CLOSE_WAIT,
          label: payment.message,
        })
      }
      if (payment.payment) {
        switch (payment.payment.system) {
          case 'tinkoff':
            if (payment.widgetData && typeof window !== 'undefined') {
              dispatch({
                type: 'SET_MODAL',
                modalShown: false,
                modalType: TModal.BLANK,
              })
              // @ts-ignore
              tinkoff.methods.on(tinkoff.constants.SUCCESS, () => {
                // @ts-ignore
                tinkoff.methods.off(tinkoff.constants.SUCCESS)
                dispatch({
                  type: 'SET_MODAL',
                  modalShown: false,
                  modalType: TModal.BLANK,
                })
                return router.push({
                  pathname: '/payments/check',
                  query: {
                    code: payment.payment!.code ?? '',
                  },
                })
              })
              // @ts-ignore
              tinkoff.methods.on(tinkoff.constants.REJECT, () => {
                // @ts-ignore
                tinkoff.methods.off(tinkoff.constants.REJECT)
                dispatch({
                  type: 'SET_MODAL',
                  modalShown: false,
                  modalType: TModal.BLANK,
                })
                return router.push({
                  pathname: '/payments/check',
                  query: {
                    code: payment.payment!.code ?? '',
                  },
                })
              })
              // @ts-ignore
              tinkoff.methods.on(tinkoff.constants.CANCEL, () => {
                // @ts-ignore
                tinkoff.methods.off(tinkoff.constants.CANCEL)
                dispatch({
                  type: 'SET_MODAL',
                  modalShown: false,
                  modalType: TModal.BLANK,
                })
                return router.push({
                  pathname: '/payments/check',
                  query: {
                    code: payment.payment!.code ?? '',
                  },
                })
              })
              dispatch({
                type: 'SET_MODAL',
                modalShown: true,
                modalType: TModal.TINKOFF,
              })
              if (payment.widgetData.demoFlow) {
                // @ts-ignore
                tinkoff.createDemo(payment.widgetData, {
                  view: 'iframe',
                  target: '#tinkoff-iframe',
                })
              }
              if (!payment.widgetData.demoFlow) {
                // @ts-ignore
                tinkoff.create(payment.widgetData, {
                  view: 'iframe',
                  target: '#tinkoff-iframe',
                })
              }
            }
            break
          case 'yookassa':
            if (payment.widgetData) {
              const checkout =
                //Инициализация виджета. Все параметры обязательные.
                // @ts-ignore
                new window.YooMoneyCheckoutWidget({
                  confirmation_token: payment.widgetData.confirmationToken,
                  customization: {
                    payment_methods: ['bank_card'],
                  },
                  error_callback: function () {},
                })
              dispatch({
                type: 'SET_YKASSA_MODAL',
                ykassaModal: true,
              })
              //Отображение платежной формы в контейнере
              checkout
                .render('payment-form')
                .on('success', async () => {
                  checkout.destroy()
                  return router.push({
                    pathname: '/payments/check',
                    query: {
                      code: payment.payment!.code ?? '',
                    },
                  })
                })
                .on('fail', () => {
                  notify({
                    icon: ToastIcon.ERROR,
                    autoClose: ALERT_CLOSE_WAIT,
                    label: t('notify.purchase.failed'),
                  })
                  dispatch({
                    type: 'SET_MODAL',
                    modalShown: false,
                    modalType: TModal.BLANK,
                  })
                  router.replace({
                    query: { ...router.query, subscriptioncard: 'fail' },
                  })
                })
            }
            break
          case 'cloudPayments':
            if (payment.widgetData && typeof window !== 'undefined') {
              // @ts-ignore
              const widget = new window.cp.CloudPayments()
              widget
                .pay(payment.widgetData.method, payment.widgetData.data, {
                  onFail: function (reason: string) {
                    if (reason === 'User has cancelled') {
                      dispatch({
                        type: 'SET_MODAL',
                        modalShown: false,
                        modalType: TModal.BLANK,
                      })
                      // router.reload()
                      // router.replace(
                      //   {
                      //     query: { ...router.query, subscriptioncard: 'fail' },
                      //   },
                      //   undefined,
                      //   {
                      //     shallow: false,
                      //   },
                      // )
                    }
                  },
                })
                .then((res: any) => {
                  if (res.type === 'payment' && res.status === 'success') {
                    return router.push({
                      pathname: '/payments/check',
                      query: {
                        // @ts-ignore
                        code: payment.payment.code,
                        asPath: router.asPath,
                      },
                    })
                  }
                })
            }
            break
          case 'cloudSoft':
            if (payment.widgetData && typeof window !== 'undefined') {
              // @ts-ignore
              const widget = new window.cp.CloudPayments()
              widget
                .pay(payment.widgetData.method, payment.widgetData.data, {
                  onFail: function (reason: string) {
                    if (reason === 'User has cancelled') {
                      dispatch({
                        type: 'SET_MODAL',
                        modalShown: false,
                        modalType: TModal.BLANK,
                      })
                      // router.reload()
                      // router.replace(
                      //   {
                      //     query: { ...router.query, subscriptioncard: 'fail' },
                      //   },
                      //   undefined,
                      //   {
                      //     shallow: false,
                      //   },
                      // )
                    }
                  },
                })
                .then((res: any) => {
                  if (res.type === 'payment' && res.status === 'success') {
                    return router.push({
                      pathname: '/payments/check',
                      query: {
                        // @ts-ignore
                        code: payment.payment.code,
                        asPath: router.asPath,
                      },
                    })
                  }
                })
            }
            break
          default:
            break
        }
      }
    },
    [router, dispatch],
  )

  React.useEffect(() => {
    if (payment) {
      handlePayment(payment)
    }
  }, [payment, handlePayment])

  React.useEffect(() => {
    if (slug) {
      setInitialState(prev => ({ ...prev, slug }))
    }
  }, [slug])

  return {
    setPayment,
    handleCards,
    handleAccessType,
    handleCurrentPayment,
    handleCurrentPaymentWebinar,
    handleAddCard,
    handleAccessTypeFirstRegistration,
    handlePayment,
    handleInstallmentCurrentPayment,
  }
}

export default usePayments
