import { useQuery } from 'react-query'
import { AxiosError } from 'axios'
import { api } from '../utils/http'
import { IError } from './error'
import { Product2 } from './product'
export type TCategory = {
  title: string
  slug: string
  description: string | null
  imagePath: string | null
}

const categoryApi = {
  getCategories: (): Promise<TCategory[]> => {
    return api.get('/api/categories').then(res => res.data)
  },
  // список продукта
  getProductsByCategorySlugForPage: (
    categorySlug: string = '',
  ): Promise<Product2> => {
    return api
      .get(`/api/categories/${categorySlug}/products`)
      .then(res => res.data)
  },
  // один продукт
  getProductByCategorySlugAndProductSlugForPage: (
    categorySlug: string = '',
    productSlug: string = '',
  ) => {
    return api
      .get(`/api/categories/${categorySlug}/products/${productSlug}`)
      .then(res => res.data)
  },
}

export const useCategoryQuery = {
  useGetCategories: () => {
    return useQuery<TCategory[], AxiosError<IError>>(['getCategories'], () =>
      categoryApi.getCategories(),
    )
  },
  useGetProductByCategorySlugAndProductSlugForPage: (
    categorySlug: string = '',
    productSlug: string = '',
  ) => {
    return useQuery(
      [
        'getProductByCategorySlugAndProductSlugForPage',
        categorySlug,
        productSlug,
      ],
      () =>
        categoryApi.getProductByCategorySlugAndProductSlugForPage(
          categorySlug,
          productSlug,
        ),
      {
        enabled: !!categorySlug && !!productSlug,
      },
    )
  },
}

export default categoryApi
