export const DEBOUNCE_WAIT = 500
export const ALERT_CLOSE_WAIT = 2000

export const ERROR_CODE_EMAIL_ALREADY_USED =
  'd96fd641-8002-4252-a246-1f893858b1f9'

export const INVALID_FORMAT_ERROR = '02f86eef-cff2-440a-8e8e-02f93c3902ed'

export const PRIVACY_POLICY = '/static/common/privacy_policy.pdf'

export const PERSONAL_DATA = '/static/common/personal_data.pdf'

export const OFERTA = '/static/common/oferta.pdf'

export const INFORMATION = '/static/common/anticorruptionpolicy.pdf'

export const LICENSE = '/static/common/license.pdf'
