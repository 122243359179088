import { useQuery } from 'react-query'
import { AxiosError } from 'axios'
import { api } from '../utils/http'
import { IError } from './error'

export interface ITrackers {
  bigoads_pixel?: {
    event: string
    pixel_id: string
  }
  google_ads?: {
    pixel_destination: null | string
    pixel_id: string
  }
  mgid_sensor?: {
    counter_id: string
  }
  yandex_metrika?: {
    metrika_id: string
  }
  google_tag_manager?: {
    tag: string
  }
  vk?: {
    vk_id: string
    event: null | string
  }
}

export interface IAff {
  labels: {
    [key: string]: string
  }
  trackers?: ITrackers
}

const externalApi = {
  getAffRid: (aff_rid: string): Promise<IAff> => {
    return api.get(`/external/aff/click${aff_rid}`).then(res => res.data)
  },
}

export default externalApi

export const useExternalQuery = {
  useGetAffRid: (aff_rid: string) => {
    return useQuery<IAff, AxiosError<IError>>(
      ['getAffRid', aff_rid],
      () => externalApi.getAffRid(aff_rid),
      {
        enabled: !!aff_rid,
      },
    )
  },
}
