import { useLayoutEffect, useState } from 'react'
import useClientSide from '../useClientSide'

export interface IUseWindowSize {
  width: number
  height: number
}

function useWindowSize(): IUseWindowSize {
  const isClientSide = useClientSide()
  const [size, setSize] = useState<IUseWindowSize>({
    width: 0,
    height: 0,
  })
  useLayoutEffect(() => {
    const updateSize = () => {
      isClientSide &&
        setSize(prev => ({
          ...prev,
          width: window.innerWidth,
          height: window.innerHeight,
        }))
    }
    if (isClientSide) {
      window.addEventListener('resize', updateSize)
      updateSize()
    }

    return () => window.removeEventListener('resize', updateSize)
  }, [isClientSide])
  return size
}

export default useWindowSize
