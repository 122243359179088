/**
 * vendor
 */
import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { format } from 'date-fns'
import getConfig from 'next/config'
import { useTranslation, Trans } from 'next-i18next'
import Link from 'next/link'
import Image from 'next/image'
/**
 * components
 */
import StyledContainer from '../Layout/Container'
import Icon from '../Icon'
import Hide from '../Hide'
/**
 * api
 */
import { useCategoryQuery } from '../../api/category'
/**
 * constants
 */
import {
  PRIVACY_POLICY,
  PERSONAL_DATA,
  OFERTA,
  INFORMATION,
  LICENSE,
} from '../../constants'

const {
  publicRuntimeConfig: { API_URL, INTERNALIZATION, STATIC_ASSETS_COMMON_URL },
} = getConfig()

export enum FooterStyle {
  NONE = 'none',
  BLOCK = 'block',
}

interface Style {
  footerStyle: FooterStyle
}

interface Props extends Style {}

const internalization = JSON.parse(INTERNALIZATION) as boolean

const Footer: React.FC<Props> = ({ footerStyle }) => {
  const { t, i18n } = useTranslation('components.footer')
  const [accordion] = useState({
    1: true,
    2: true,
    3: true,
  })
  const date = new Date()
  const { data: categoriesData } = useCategoryQuery.useGetCategories()

  // if (!categoriesData || isLoading) {
  //   return null
  // }
  return (
    <StyledFooter footerStyle={footerStyle}>
      <StyledContainer>
        <StyledFooterWrapper>
          <StyledFooterRow>
            <StyledFooterInner>
              {/* Navigation*/}
              <StyledFooterNav>
                {/* accordion-1*/}
                <StyledFooterAccordion>
                  <StyledFooterAccordionTrigger isOpen={accordion[1]}>
                    {t('column.1.course')}
                  </StyledFooterAccordionTrigger>
                  <StyledFooterAccordionPanel isOpen={accordion[1]}>
                    {categoriesData
                      ? categoriesData.map(slug => (
                          <Link passHref href={`/${slug.slug}`} key={slug.slug}>
                            <StyledFooterItem aria-label={`${slug.title}`}>
                              {slug.title}
                            </StyledFooterItem>
                          </Link>
                        ))
                      : null}
                  </StyledFooterAccordionPanel>
                </StyledFooterAccordion>
                {/* accordion-2*/}
                <StyledFooterAccordion>
                  <StyledFooterAccordionTrigger isOpen={accordion[2]}>
                    {t('column.2.about')}
                  </StyledFooterAccordionTrigger>
                  <StyledFooterAccordionPanel isOpen={accordion[2]}>
                    <Hide hide={!internalization}>
                      <Link passHref href={`/contacts`}>
                        <StyledFooterItem aria-label={t('column.2.requisites')}>
                          {t('column.2.requisites')}
                        </StyledFooterItem>
                      </Link>
                    </Hide>
                    <Link passHref href={`/contacts`}>
                      <StyledFooterItem aria-label={t('column.2.contants')}>
                        {t('column.2.contants')}
                      </StyledFooterItem>
                    </Link>
                    <Link passHref href={`/about`}>
                      <StyledFooterItem aria-label={t('column.2.aboutCompany')}>
                        {t('column.2.aboutCompany')}
                      </StyledFooterItem>
                    </Link>
                    <Hide hide={internalization}>
                      <StyledFooterItem
                        href='mailto:partners@willskill.ru'
                        aria-label={t('column.2.partnerProgram')}
                      >
                        {t('column.2.partnerProgram')}
                      </StyledFooterItem>
                    </Hide>
                    <Link passHref href={`/faq`}>
                      <StyledFooterItem aria-label={t('column.2.help')}>
                        {t('column.2.help')}
                      </StyledFooterItem>
                    </Link>
                    <Hide hide={internalization}>
                      <Link passHref href={`/blog`}>
                        <StyledFooterItem aria-label={t('column.2.articles')}>
                          {t('column.2.articles')}
                        </StyledFooterItem>
                      </Link>
                    </Hide>
                  </StyledFooterAccordionPanel>
                </StyledFooterAccordion>
                {/* accordion-3 */}
                <StyledFooterAccordion>
                  <StyledFooterAccordionTrigger isOpen={accordion[3]}>
                    {t('column.3.information')}
                  </StyledFooterAccordionTrigger>
                  <StyledFooterItem
                    target='_blank'
                    href={
                      internalization
                        ? 'https://willskill.com/static/common/personal_data_en.pdf'
                        : `${API_URL}${PERSONAL_DATA}`
                    }
                    rel='noreferrer'
                    aria-label={t('column.3.PersonalDatarocessingPolicy')}
                  >
                    {t('column.3.PersonalDatarocessingPolicy')}
                  </StyledFooterItem>
                  <StyledFooterItem
                    target='_blank'
                    href={
                      internalization
                        ? 'https://willskill.com/static/common/privacy_policy_en.pdf'
                        : `${API_URL}${PRIVACY_POLICY}`
                    }
                    rel='noreferrer'
                    aria-label={t('column.3.PrivacyPolicy')}
                  >
                    {t('column.3.PrivacyPolicy')}
                  </StyledFooterItem>
                  <Hide hide={internalization}>
                    <StyledFooterItem
                      target='_blank'
                      href={`${API_URL}${INFORMATION}`}
                      rel='noreferrer'
                      aria-label={t('column.3.AntiCorruptionPolicy')}
                    >
                      {t('column.3.AntiCorruptionPolicy')}
                    </StyledFooterItem>
                  </Hide>
                  <StyledFooterItem
                    target='_blank'
                    href={
                      internalization
                        ? 'https://willskill.com/static/common/oferta_en.pdf'
                        : `${API_URL}${OFERTA}`
                    }
                    rel='noreferrer'
                    aria-label={t('column.3.PublicOffer')}
                  >
                    {t('column.3.PublicOffer')}
                  </StyledFooterItem>
                  <Hide hide={internalization}>
                    <Link passHref href='/ndfl'>
                      <StyledFooterItem>Возврат НДФЛ</StyledFooterItem>
                    </Link>
                  </Hide>

                  <Hide hide={internalization}>
                    <Link passHref href='/purchase/installment'>
                      <StyledFooterItem>Покупка в рассрочку</StyledFooterItem>
                    </Link>
                  </Hide>
                </StyledFooterAccordion>
              </StyledFooterNav>
              {/* Info */}
              <StyledFooterInfo>
                {/* brand */}
                <StyledFooterBrand>
                  <StyledFooterLogo>
                    <Icon name='logo2' width='162' height='30' />
                    <Hide hide={internalization}>
                      <StyledFooterHr />

                      <Image
                        alt='ПО'
                        width={149}
                        height={30}
                        src={`${STATIC_ASSETS_COMMON_URL}/assets/images/footer-bird.svg`}
                        unoptimized
                      />
                    </Hide>
                  </StyledFooterLogo>
                </StyledFooterBrand>
                <Hide hide={internalization}>
                  <StyledAccreditationContainer>
                    <StyledAccreditationTitle>
                      WillSkill.ru является аккредитованной IT-компанией -
                      состоящей в едином реестре Российского программного
                      обеспечения
                    </StyledAccreditationTitle>
                    <StyledAccreditationDescription>
                      Запись в реестре от 03.04.2023 №17202 - Произведена на
                      основании поручения Министерства цифрового развития, связи
                      и массовых коммуникаций Российской Федерации от 03.04.2023
                      по протоколу заседания экспертного совета от 27.03.2023
                      №193пр
                    </StyledAccreditationDescription>
                  </StyledAccreditationContainer>
                </Hide>
              </StyledFooterInfo>
            </StyledFooterInner>
          </StyledFooterRow>
          <StyledFooterBottom>
            <Hide hide={!internalization}>
              {i18n.language === 'ru' && (
                <StyledFooterText>
                  ООО ВИЛЛСКИЛЛ {format(date, 'YYY')}
                </StyledFooterText>
              )}
              {i18n.language === 'en' && (
                <StyledFooterText>
                  LLC WILLSKILL {format(date, 'YYY')}
                </StyledFooterText>
              )}
              {i18n.language === 'hy' && (
                <StyledFooterText>
                  ՍՊԸ ՈՒԻԼԼՍՔԻԼԼ {format(date, 'YYY')}
                </StyledFooterText>
              )}
            </Hide>
            <Hide hide={internalization}>
              <StyledFooterInner>
                <StyledFooterText>
                  © OOO “ЦДО” {format(date, 'YYY')}
                </StyledFooterText>

                <StyledLicenseContainer>
                  <StyledFooterItemLicense
                    target='_blank'
                    href={`${API_URL}${LICENSE}`}
                    rel='noreferrer'
                    aria-label={t('bottom.stateLicense')}
                  >
                    <Trans
                      t={t}
                      i18nKey={'bottom.stateLicense'}
                      components={{
                        '1': <div />,
                        '2': <div />,
                      }}
                    />
                  </StyledFooterItemLicense>
                  <StyledFooterItem
                    target='_blank'
                    href={`https://reestr.digital.gov.ru/reestr/1407795/`}
                    rel='noreferrer'
                    aria-label={`программного обеспечения`}
                  >
                    Состоит в реестре отечественного программного обеспечения
                    №17202 от 03.04.2023
                  </StyledFooterItem>
                </StyledLicenseContainer>
              </StyledFooterInner>

              <StyledFooterOtherInner>
                {/* socials */}
                <StyledFooterSocials>
                  <StyledFooterSocial
                    target='_blank'
                    href='https://t.me/willskilltg'
                    rel='noreferrer'
                    aria-label='telegram'
                  >
                    <Icon name='telegram' width='15' height='15' />
                  </StyledFooterSocial>
                  <StyledFooterSocial
                    target='_blank'
                    href='https://vk.com/willskill'
                    rel='noreferrer'
                    aria-label='vk'
                  >
                    <Icon name='vk' width='18' height='18' />
                  </StyledFooterSocial>
                </StyledFooterSocials>

                {/* payments */}
                <StyledFooterPayments>
                  <Image
                    src={`${STATIC_ASSETS_COMMON_URL}/assets/images/components-footer-sbercredit.png`}
                    alt='cbercredit'
                    priority
                    width={24}
                    height={21.94}
                    quality={100}
                  />
                  <Image
                    src={`${STATIC_ASSETS_COMMON_URL}/assets/images/components-footer-visa.svg`}
                    alt='visa'
                    priority
                    width={28.9}
                    height={9.38}
                    quality={100}
                  />
                  <Image
                    src={`${STATIC_ASSETS_COMMON_URL}/assets/images/components-footer-mir.svg`}
                    alt='mir'
                    priority
                    width={32.9}
                    height={9}
                    quality={100}
                  />
                  <Image
                    src={`${STATIC_ASSETS_COMMON_URL}/assets/images/components-footer-master.svg`}
                    alt='master'
                    priority
                    width={35.9}
                    height={28.72}
                    quality={100}
                  />
                  <Image
                    src={`${STATIC_ASSETS_COMMON_URL}/assets/images/components-footer-split-white.svg`}
                    alt='yandex split'
                    priority
                    width={90.3}
                    height={12.97}
                    quality={100}
                  />
                </StyledFooterPayments>
              </StyledFooterOtherInner>
            </Hide>
          </StyledFooterBottom>
        </StyledFooterWrapper>
      </StyledContainer>
    </StyledFooter>
  )
}

export const FooterExport: React.FC<Props> = ({ footerStyle }) => {
  const [accordion] = useState({
    1: true,
    2: true,
    3: true,
  })
  const date = new Date()
  const { data: categoriesData, isLoading } =
    useCategoryQuery.useGetCategories()

  if (!categoriesData || isLoading) {
    return null
  }
  return (
    <StyledFooter footerStyle={footerStyle}>
      <StyledContainer>
        <StyledFooterWrapper>
          <StyledFooterRow>
            <StyledFooterInner>
              {/* Navigation*/}
              <StyledFooterNav>
                {/* accordion-1*/}
                <StyledFooterAccordion>
                  <StyledFooterAccordionTrigger isOpen={accordion[1]}>
                    Курсы
                  </StyledFooterAccordionTrigger>
                  <StyledFooterAccordionPanel isOpen={accordion[1]}>
                    {categoriesData.map(slug => (
                      <StyledFooterItem
                        aria-label={`${slug.title}`}
                        key={slug.slug}
                        href={`${API_URL}/${slug.slug}`}
                      >
                        {slug.title}
                      </StyledFooterItem>
                    ))}
                  </StyledFooterAccordionPanel>
                </StyledFooterAccordion>
                {/* accordion-2*/}
                <StyledFooterAccordion>
                  <StyledFooterAccordionTrigger isOpen={accordion[2]}>
                    О Нас
                  </StyledFooterAccordionTrigger>
                  <StyledFooterAccordionPanel isOpen={accordion[2]}>
                    <StyledFooterItem
                      href={`${API_URL}/contacts`}
                      aria-label='Контакты'
                    >
                      Контакты
                    </StyledFooterItem>
                    <StyledFooterItem
                      href={`${API_URL}/about`}
                      aria-label='О компании'
                    >
                      О компании
                    </StyledFooterItem>
                    <StyledFooterItem
                      href='mailto:partners@willskill.ru'
                      aria-label='Партнерская программа'
                    >
                      Партнерская программа
                    </StyledFooterItem>
                    <StyledFooterItem
                      href={`${API_URL}/faq`}
                      aria-label='Помощь'
                    >
                      Помощь
                    </StyledFooterItem>
                    <StyledFooterItem
                      href={`${API_URL}/blog`}
                      aria-label='Статьи'
                    >
                      Статьи
                    </StyledFooterItem>
                  </StyledFooterAccordionPanel>
                </StyledFooterAccordion>
                {/* accordion-3 */}
                <StyledFooterAccordion>
                  <StyledFooterAccordionTrigger isOpen={accordion[3]}>
                    Информация
                  </StyledFooterAccordionTrigger>
                  <StyledFooterItem
                    target='_blank'
                    href={`${API_URL}${PERSONAL_DATA}`}
                    rel='noreferrer'
                    aria-label='Политика обработки персональных данных'
                  >
                    Политика обработки персональных данных
                  </StyledFooterItem>
                  <StyledFooterItem
                    target='_blank'
                    href={`${API_URL}${PRIVACY_POLICY}`}
                    rel='noreferrer'
                    aria-label='Политика конфиденциальности'
                  >
                    Политика конфиденциальности
                  </StyledFooterItem>
                  <StyledFooterItem
                    target='_blank'
                    href={`${API_URL}${INFORMATION}`}
                    rel='noreferrer'
                    aria-label='Антикоррупционная политика'
                  >
                    Антикоррупционная политика
                  </StyledFooterItem>
                  <StyledFooterItem
                    target='_blank'
                    href={`${API_URL}${OFERTA}`}
                    rel='noreferrer'
                    aria-label='Публичная оферта'
                  >
                    Публичная оферта
                  </StyledFooterItem>
                </StyledFooterAccordion>
              </StyledFooterNav>
              {/* Info */}
              <StyledFooterInfo>
                {/* brand */}
                <StyledFooterBrand>
                  <StyledFooterLogo>
                    <Icon name='logo2' width='162' height='30' />
                    <StyledFooterHr />

                    <Image
                      alt='ПО'
                      width={149}
                      height={30}
                      src={`${STATIC_ASSETS_COMMON_URL}/assets/images/footer-bird.svg`}
                      quality={100}
                    />
                  </StyledFooterLogo>
                </StyledFooterBrand>
                <StyledAccreditationContainer>
                  <StyledAccreditationTitle>
                    WillSkill.ru является аккредитованной IT-компанией -
                    состоящей в едином реестре Российского программного
                    обеспечения
                  </StyledAccreditationTitle>
                  <StyledAccreditationDescription>
                    Запись в реестре от 03.04.2023 №17202 - Произведена на
                    основании поручения Министерства цифрового развития, связи и
                    массовых коммуникаций Российской Федерации от 03.04.2023 по
                    протоколу заседания экспертного совета от 27.03.2023 №193пр
                  </StyledAccreditationDescription>
                </StyledAccreditationContainer>
              </StyledFooterInfo>
            </StyledFooterInner>
          </StyledFooterRow>
          <StyledFooterBottom>
            <Hide hide={!internalization}>
              <StyledFooterText>
                ООО ВИЛЛСКИЛЛ {format(date, 'YYY')}
              </StyledFooterText>
            </Hide>
            <Hide hide={internalization}>
              <StyledFooterInner>
                <StyledFooterText>
                  © OOO “ЦДО” {format(date, 'YYY')}
                </StyledFooterText>

                <StyledLicenseContainer>
                  <StyledFooterItemLicense
                    target='_blank'
                    href={`${API_URL}${LICENSE}`}
                    rel='noreferrer'
                    aria-label='Государственная лицензия'
                  >
                    Лицензия комитета по образованию{' '}
                    <div>№ Л035-01271-78/00176659</div> <div>от 1.04.2022</div>
                  </StyledFooterItemLicense>
                  <StyledFooterItem
                    target='_blank'
                    href={`https://reestr.digital.gov.ru/reestr/1407795/`}
                    rel='noreferrer'
                    aria-label={`программного обеспечения`}
                  >
                    Состоит в реестре отечественного программного обеспечения
                    №17202 от 03.04.2023
                  </StyledFooterItem>
                </StyledLicenseContainer>
              </StyledFooterInner>

              <StyledFooterOtherInner>
                {/* socials */}
                <StyledFooterSocials>
                  <StyledFooterSocial
                    target='_blank'
                    href='https://t.me/willskilltg'
                    rel='noreferrer'
                    aria-label='telegram'
                  >
                    <Icon name='telegram' width='15' height='15' />
                  </StyledFooterSocial>
                  <StyledFooterSocial
                    target='_blank'
                    href='https://vk.com/willskill'
                    rel='noreferrer'
                    aria-label='vk'
                  >
                    <Icon name='vk' width='18' height='18' />
                  </StyledFooterSocial>
                </StyledFooterSocials>

                {/* payments */}
                <StyledFooterPayments>
                  <Image
                    src={`${STATIC_ASSETS_COMMON_URL}/assets/images/components-footer-sbercredit.png`}
                    alt='cbercredit'
                    priority
                    width={24}
                    height={21.94}
                    quality={100}
                  />
                  <Image
                    src={`${STATIC_ASSETS_COMMON_URL}/assets/images/components-footer-visa.svg`}
                    alt='visa'
                    priority
                    width={28.9}
                    height={9.38}
                    quality={100}
                  />
                  <Image
                    src={`${STATIC_ASSETS_COMMON_URL}/assets/images/components-footer-mir.svg`}
                    alt='mir'
                    priority
                    width={32.9}
                    height={9}
                    quality={100}
                  />
                  <Image
                    src={`${STATIC_ASSETS_COMMON_URL}/assets/images/components-footer-master.svg`}
                    alt='master'
                    priority
                    width={35.9}
                    height={28.72}
                    quality={100}
                  />
                  <Image
                    src={`${STATIC_ASSETS_COMMON_URL}/assets/images/components-footer-split-white.svg`}
                    alt='yandex split'
                    priority
                    width={90.3}
                    height={12.97}
                    quality={100}
                  />
                </StyledFooterPayments>
              </StyledFooterOtherInner>
            </Hide>
          </StyledFooterBottom>
        </StyledFooterWrapper>
      </StyledContainer>
    </StyledFooter>
  )
}

export const FooterEnglish: React.FC<Props> = ({ footerStyle }) => {
  const [accordion] = useState({
    1: true,
    2: true,
    3: true,
  })
  const date = new Date()
  const { data: categoriesData, isLoading } =
    useCategoryQuery.useGetCategories()

  if (!categoriesData || isLoading) {
    return null
  }
  return (
    <StyledFooter footerStyle={footerStyle}>
      <StyledContainer>
        <StyledFooterWrapper>
          <StyledFooterRow>
            <StyledFooterInner>
              {/* Navigation*/}
              <StyledFooterNav>
                {/* accordion-1*/}
                <StyledFooterAccordion>
                  <StyledFooterAccordionTrigger isOpen={accordion[1]}>
                    Courses
                  </StyledFooterAccordionTrigger>
                  <StyledFooterAccordionPanel isOpen={accordion[1]}>
                    {categoriesData.map(slug => (
                      <Link passHref href={`/${slug.slug}`} key={slug.slug}>
                        <StyledFooterItem aria-label={`${slug.title}`}>
                          {slug.title}
                        </StyledFooterItem>
                      </Link>
                    ))}
                  </StyledFooterAccordionPanel>
                </StyledFooterAccordion>
                {/* accordion-2*/}
                <StyledFooterAccordion>
                  <StyledFooterAccordionTrigger isOpen={accordion[2]}>
                    About us
                  </StyledFooterAccordionTrigger>
                  <StyledFooterAccordionPanel isOpen={accordion[2]}>
                    <Link passHref href={`/contacts`}>
                      <StyledFooterItem aria-label={'Contacts'}>
                        Contacts
                      </StyledFooterItem>
                    </Link>
                    <Link passHref href={`/about`}>
                      <StyledFooterItem aria-label={'About company'}>
                        About company
                      </StyledFooterItem>
                    </Link>
                    <Link passHref href={`/faq`}>
                      <StyledFooterItem aria-label={'Help'}>
                        Help
                      </StyledFooterItem>
                    </Link>
                  </StyledFooterAccordionPanel>
                </StyledFooterAccordion>
                {/* accordion-3 */}
                <StyledFooterAccordion>
                  <StyledFooterAccordionTrigger isOpen={accordion[3]}>
                    Information
                  </StyledFooterAccordionTrigger>
                  <StyledFooterItem
                    target='_blank'
                    href={
                      'https://willskill.com/static/common/personal_data_en.pdf'
                    }
                    rel='noreferrer'
                    aria-label={'Personal Data Policy'}
                  >
                    Personal Data Policy
                  </StyledFooterItem>
                  <StyledFooterItem
                    target='_blank'
                    href={
                      'https://willskill.com/static/common/privacy_policy_en.pdf'
                    }
                    rel='noreferrer'
                    aria-label={'Privacy Policy'}
                  >
                    Privacy Policy
                  </StyledFooterItem>
                  <StyledFooterItem
                    target='_blank'
                    href={'https://willskill.com/static/common/oferta_en.pdf'}
                    rel='noreferrer'
                    aria-label={'Public Offer'}
                  >
                    Public Offer
                  </StyledFooterItem>
                </StyledFooterAccordion>
              </StyledFooterNav>
              {/* Info */}
              <StyledFooterInfo>
                {/* brand */}
                <StyledFooterBrand>
                  <StyledFooterLogo>
                    <Icon name='logo2' width='162' height='30' />
                  </StyledFooterLogo>
                </StyledFooterBrand>
              </StyledFooterInfo>
            </StyledFooterInner>
          </StyledFooterRow>
          <StyledFooterBottom>
            <StyledFooterText>
              LLC WILLSKILL {format(date, 'YYY')}
            </StyledFooterText>
          </StyledFooterBottom>
        </StyledFooterWrapper>
      </StyledContainer>
    </StyledFooter>
  )
}

export default Footer

const StyledFooter = styled.footer<Style>`
  ${({ footerStyle }) => {
    switch (footerStyle) {
      case FooterStyle.NONE:
        return css`
          display: none;
        `
      case FooterStyle.BLOCK:
        return css`
          background: ${({ theme }) => theme.colors.black};
          color: #fff;
          margin-top: auto;
        `
      default:
        break
    }
  }}
`

const StyledFooterWrapper = styled.div`
  padding: 0;
`

const StyledFooterRow = styled.div``

const StyledFooterInner = styled.div`
  padding: 40px 0 0;
  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.flexDirection('column-reverse')}
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    ${({ theme }) => theme.mixins.flexDirection('column')}
    padding: 47px 0;
    gap: 30px;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    ${({ theme }) => theme.mixins.flexDirection('row')}
    gap: 0;
  }
`
// --------------------
const StyledFooterNav = styled.nav`
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    ${({ theme }) => theme.mixins.flexbox()}
    ${({ theme }) => theme.mixins.justifyContent('space-between')}
    margin-top: 30px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    width: 100%;
    margin-top: 0;
  }
`

const StyledFooterAccordion = styled.div`
  margin-bottom: 34px;
  ${({ theme }) => theme.mixins.transition('0.4s')}
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    width: 150px;
  }
`

const StyledFooterAccordionTrigger = styled.div<{ isOpen: boolean }>`
  font-family: 'Averta PE', sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1rem;
  line-height: 20px;
  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.justifyContent('space-between')}
  margin-bottom: 31px;
  svg {
    cursor: pointer;
    ${({ theme }) => theme.mixins.transition('all 0.1s linear')}

    path {
      stroke: #fff;
    }
    ${({ isOpen }) => {
      if (isOpen) {
        return css`
          transform: rotate(-180deg);
        `
      }
    }}
  }

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    svg {
      display: none;
    }
  }
`
const StyledFooterAccordionPanel = styled.div<{ isOpen: boolean }>``

const StyledFooterItem = styled.a`
  font-family: 'Averta PE', sans-serif !important;
  cursor: pointer;
  ${({ theme }) => theme.mixins.textSmall}
  opacity: 0.75;
  text-decoration: none;
  color: white;
  overflow-wrap: break-word;
  display: block;
  &:visited {
    text-decoration: none;
  }
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
  & + & {
    margin-top: 15px;
    @media ${({ theme }) => theme.devices.mobileLgUp} {
      margin-top: 20px;
    }
  }
`

const StyledFooterItemLicense = styled(StyledFooterItem)`
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    ${({ theme }) => theme.mixins.flexbox()}
    gap: 5px;
  }
`
const StyledLicenseContainer = styled.div`
  width: 50%;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    width: fit-content;
  }
`

const StyledFooterInfo = styled.div`
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    ${({ theme }) => theme.mixins.flexOrder(-1)}

    ${({ theme }) => theme.mixins.flexDirection('column')}
    position: relative;
    border-top: none;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    max-width: 472px;
    margin-right: 110px;
    ${({ theme }) => theme.mixins.flexShrink()}
  }
`

const StyledFooterLogo = styled.div`
  ${({ theme }) => theme.mixins.flexbox()}
`

const StyledFooterHr = styled.span`
  border-left: 1px solid ${({ theme }) => theme.colors.primary};
  height: 26px;
  margin: 0 16px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    height: 30px;
    margin: 0 25px;
  }
`

const StyledAccreditationContainer = styled.div`
  background: #303030;
  border-radius: 15px;
  margin-top: 25px;
  padding: 15px 20px;
  margin-bottom: 35px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    margin-bottom: 0;
  }
`

const StyledFooterText = styled.p`
  ${({ theme }) => theme.mixins.textSmall};
  font-family: 'Averta PE', sans-serif !important;
  & + & {
    margin-top: 10px;
  }
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    text-align: left;
  }
`
const StyledFooterBrand = styled.div`
  display: block;
  text-align: left;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    width: 100%;
    text-align: left;
  }
`

const StyledAccreditationTitle = styled.p`
  ${({ theme }) => theme.mixins.textButton};
  font-family: 'Averta PE', sans-serif !important;
  margin-bottom: 15px;
`

const StyledAccreditationDescription = styled.p`
  ${({ theme }) => theme.mixins.textSmall3};
  font-family: 'Averta PE', sans-serif !important;
  color: #bababa;
`

const StyledFooterSocials = styled.div`
  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.justifyContent('start')}
  ${({ theme }) => theme.mixins.alignItems('center')}
  /* width: 50%; */
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    ${({ theme }) => theme.mixins.justifyContent('start')}
    width: fit-content;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    position: static;
  }
`

const StyledFooterSocial = styled.a`
  text-decoration: none;
  width: 20px;
  cursor: pointer;
  &:visited {
    text-decoration: none;
  }
  & + & {
    margin-left: 30px;
  }
  svg {
    display: block;
    path {
      fill: #fff;
    }
  }
`

const StyledFooterBottom = styled.div`
  padding: 22px 0 30px;
  border-top: 1px solid ${({ theme }) => theme.colors.primary};
  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.justifyContent('space-between')}
  ${({ theme }) => theme.mixins.flexDirection('column')}
  
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    ${({ theme }) => theme.mixins.alignItems('center')}
    ${({ theme }) => theme.mixins.flexDirection('row')}
  }

  ${StyledFooterText} {
    width: 50%;
    margin: 0;
    opacity: 0.75;
    align-self: baseline;

    @media ${({ theme }) => theme.devices.mobileLgUp} {
      align-self: initial;
      width: fit-content;
    }
  }

  ${StyledFooterInner} {
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    margin-top: 15px;
    gap: 30px;
    @media ${({ theme }) => theme.devices.mobileLgUp} {
      width: 60%;
      margin-top: 0;
    }
  }
`

const StyledFooterOtherInner = styled.div`
  width: 100%;
  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.justifyContent('space-between')}
  gap: 30px;
  margin-top: 30px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    ${({ theme }) => theme.mixins.flexDirection('row')}
    ${({ theme }) => theme.mixins.flexDirection('row-reverse')}
    gap: 0;
    margin-top: 0;
    width: fit-content;
  }
`

const StyledFooterPayments = styled.div`
  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.alignItems('center')}
  ${({ theme }) => theme.mixins.justifyContent('space-between')}
  gap: 12.29px;
  /* width: 50%; */
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    width: fit-content;
    margin-right: 30px;
    gap: 28.95px;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    margin-right: 46px;
  }
`
