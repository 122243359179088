import React, { useEffect } from 'react'
import styled from 'styled-components'
import { SubmitHandler, useForm } from 'react-hook-form'
import debounce from 'lodash/debounce'
import { useTranslation } from 'next-i18next'
/**
 * context
 */
import { useAppState, useAppDispatch } from '../../context/app'
/**
 * ui
 */
import Button, { ButtonSize, ButtonStyle } from '../Button'
import Icon from '../Icon'
import Radio from '../Radio'
/**
 * api
 */
import { useCardQuery } from '../../api/card'
import { useSubscriptionsMutation } from '../../api/subscription'
/**
 * hook
 */
import usePayments from '../../hooks/usePayments'
import useHeaders from '../../hooks/useHeaders'
/**
 * constants
 */
import { DEBOUNCE_WAIT } from '../../constants'
import { TModal } from './Modal'

type TFormValue = {
  uuid: null | string
  capture: boolean
}

const ModalPayment: React.FC = () => {
  const { t } = useTranslation('components.modal')
  const dispatch = useAppDispatch()
  const { headers } = useHeaders()
  const { slug, cancelCurrent } = useAppState()
  const { handlePayment } = usePayments()
  const { register, handleSubmit, setValue, watch, getValues } =
    useForm<TFormValue>({
      mode: 'onChange',
      reValidateMode: 'onChange',
      defaultValues: {
        uuid: null,
        capture: false,
      },
    })
  // watch value in react hook form
  const uuidValue = watch('uuid')
  // to register state of useForm
  useEffect(() => {
    register('uuid')
    register('capture')
  }, [register])

  const { data, isLoading } = useCardQuery.useGetCards(headers)

  useEffect(() => {
    if (data && data.length > 0) {
      setValue('uuid', data[0].uuid)
      // setValue('type', data[0].type)
    }
  }, [data, setValue])

  // mutations
  const payMutation = useSubscriptionsMutation.usePostSubscriptionsBySlugPay()

  const onSubmit: SubmitHandler<TFormValue> = data => {
    const body = cancelCurrent
      ? { card: data.uuid, capture: data.capture, cancelCurrent }
      : { card: data.uuid, capture: data.capture }
    payMutation
      .mutateAsync({
        headers,
        slug,
        isAuth: true,
        body,
      })
      .then(({ data }) => handlePayment(data))
      .then(() => {
        dispatch({
          type: 'SET_CANCEL_CURRENT',
          cancelCurrent: false,
        })
        dispatch({
          type: 'SET_MODAL',
          modalShown: false,
          modalType: TModal.BLANK,
        })
      })
  }

  if (!data || isLoading) {
    return null
  }
  const handler = debounce(() => {
    handleSubmit(onSubmit)()
  }, DEBOUNCE_WAIT)

  return (
    <StyledContainer
      onSubmit={e => {
        e.preventDefault()
        handler()
      }}
    >
      {/* title */}
      <StyledTitle>{t('ModalPayment.title')}</StyledTitle>
      {/* subtitle */}
      <StyledSubtitle>{t('ModalPayment.subtitle')}</StyledSubtitle>
      {/* Card list */}
      <StyledCardWrapper>
        {data.map(card => (
          <StyledCardItem
            key={card.uuid}
            onClick={() => {
              setValue('uuid', card.uuid)
              setValue('capture', false)
            }}
          >
            <StyledCardItemIcon>
              <Icon name={`payment-card-${card.type}`} width='43' />
            </StyledCardItemIcon>
            <StyledCardItemText>
              <StyledCardItemTitle>{card.type}</StyledCardItemTitle>
              <StyledCardItemNumber>
                <span>**** **** ****</span> {card.pan4}
              </StyledCardItemNumber>
            </StyledCardItemText>
            <StyledCardItemRadio>
              <Radio checked={uuidValue === card.uuid} />
            </StyledCardItemRadio>
          </StyledCardItem>
        ))}
        {/* last add card */}
        <StyledCardItem
          onClick={() => {
            setValue('uuid', null)
            setValue('capture', true)
          }}
        >
          <StyledCardItemIconAdd>
            <Icon name='add' />
          </StyledCardItemIconAdd>
          <StyledCardItemTextAdd>
            {t('ModalPayment.payWithNewCard')}
          </StyledCardItemTextAdd>
          <StyledCardItemRadio>
            <Radio checked={getValues('capture') === true} />
          </StyledCardItemRadio>
        </StyledCardItem>
      </StyledCardWrapper>
      {/* Button */}
      <StyledButtonWrapper>
        <StyledButtonContainer>
          <Button
            buttonStyle={ButtonStyle.FILLED}
            size={ButtonSize.BLOCK}
            type='submit'
          >
            {t('ModalPayment.pay')}
          </Button>
        </StyledButtonContainer>
      </StyledButtonWrapper>
    </StyledContainer>
  )
}

export default ModalPayment

const StyledContainer = styled.form`
  background: #ffffff;
  box-shadow: 0 4px 54px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  width: 100%;
  padding: calc(35px + 21px) calc(24px + 15px);
  max-width: 100%;
  overflow: hidden;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    padding: 68px 97px;
    width: 555px;
  }
`

const StyledTitle = styled.h4`
  ${({ theme }) => theme.mixins.H4};
  text-align: left;
  margin-bottom: 13px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    ${({ theme }) => theme.mixins.H3};
  }
`

const StyledSubtitle = styled.p`
  ${({ theme }) => theme.mixins.textButton};
  text-align: left;
  margin-bottom: 44px;
`

const StyledCardWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 25px;
`

const StyledCardItem = styled.div`
  display: flex;
  cursor: pointer;
`

const StyledCardItemIcon = styled.div`
  border-radius: 6px;
  width: 68px;
  height: 46px;
  background-color: ${({ theme }) => theme.colors.greySecondary};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`

const StyledCardItemIconAdd = styled(StyledCardItemIcon)`
  path {
    stroke: ${({ theme }) => theme.colors.black};
  }
`

const StyledCardItemText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
  text-transform: capitalize;
  ${({ theme }) => theme.mixins.textNormal};
`

const StyledCardItemTextAdd = styled(StyledCardItemText)`
  text-transform: initial;
`

const StyledCardItemTitle = styled.div``

const StyledCardItemNumber = styled.div`
  color: ${({ theme }) => theme.colors.grey};
  span {
    vertical-align: sub;
  }
`

const StyledCardItemRadio = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`

const StyledButtonWrapper = styled.div`
  width: 100%;
  margin-top: 38.52px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    display: flex;
    justify-content: center;
  }
`

const StyledButtonContainer = styled.div`
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    width: 203px;
  }
`
