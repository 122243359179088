import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'next-i18next'
/**
 * context
 */
import { useAppDispatch } from '../../context/app'
import { TModal } from './Modal'
/**
 * ui
 */
import Button, { ButtonSize, ButtonStyle } from '../Button'

const ModalCourseAgreePayment: React.FC = () => {
  const { t } = useTranslation('components.modal')
  const dispatch = useAppDispatch()

  const setCloseModal = () =>
    dispatch({
      type: 'SET_MODAL',
      modalShown: false,
      modalType: TModal.BLANK,
    })

  const onClickPaymentModal = () => {
    dispatch({
      type: 'SET_MODAL',
      modalShown: true,
      modalType: TModal.PAYMENT,
    })
  }
  return (
    <StyledContainer>
      <StyledTitle>{t('ModalCourseAgreePayment.title')}</StyledTitle>

      <StyledButtonWrapper>
        <StyledButtonContainer>
          <Button
            buttonStyle={ButtonStyle.FILLED}
            size={ButtonSize.BLOCK}
            onClick={onClickPaymentModal}
            type='button'
          >
            {t('ModalCourseAgreePayment.yes')}
          </Button>
        </StyledButtonContainer>
      </StyledButtonWrapper>
      <StyledCancel onClick={setCloseModal}>
        {t('ModalCourseAgreePayment.cancel')}
      </StyledCancel>
    </StyledContainer>
  )
}
const StyledContainer = styled.div`
  background: #ffffff;
  box-shadow: 0 4px 54px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  width: 100%;
  padding: calc(35px + 21px) calc(24px + 15px);
  max-width: 100%;
  overflow: hidden;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    padding: 68px 97px;
    max-width: 555px;
  }
`

const StyledTitle = styled.h4`
  ${({ theme }) => theme.mixins.H4};
  text-align: center;
  margin-bottom: 33px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    ${({ theme }) => theme.mixins.H3};
  }
`

const StyledButtonWrapper = styled.div`
  width: 100%;
  margin-top: 38.52px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    ${({ theme }) => theme.mixins.flexbox()}
    ${({ theme }) => theme.mixins.justifyContent('center')}
  }
`

const StyledButtonContainer = styled.div`
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    width: 203px;
  }
`

const StyledCancel = styled.p`
  margin-top: 22px;
  color: ${({ theme }) => theme.colors.primary};
  ${({ theme }) => theme.mixins.textLink};
  text-decoration: underline;
  cursor: pointer;
  text-align: center;
`
export default ModalCourseAgreePayment
