import React from 'react'
import { toast } from 'react-toastify'
import Icon from '../Icon'
import styled from 'styled-components'

const IconWrapper = styled.div`
  width: 60px;
`

export enum ToastIcon {
  SUCCESS = 'toast-icon-success',
  ERROR = 'toast-icon-error',
  WARN = 'toast-icon-warn',
}

export interface ToastData {
  icon: ToastIcon
  label: string
  autoClose: number
}

/**
 * There are definitions for some toast style In styled/themes/globalStyle.js
 */
export const notify = (data: ToastData, cb?: () => void) =>
  toast.success(data.label, {
    position: 'bottom-right',
    autoClose: data.autoClose,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    icon: () => (
      <IconWrapper>
        <Icon name={data.icon} />
      </IconWrapper>
    ),
    onClose: () => {
      if (cb) {
        cb()
      }
    },
  })
