import { ISubscriptions } from 'src/api/subscription'

interface ISubscription {
  errorSubscriptions: IErrorSubscriptions
  errorSubscriptionsCount: number
  getErrorSubscriptions: () => IErrorSubscriptions
  setErrorSubscriptions: (subscription: ISubscriptions[]) => void
  getErrorSubscriptionsCount: () => number
  reset: () => void
}

interface IErrorSubscriptions {
  [key: string]: ISubscriptions[]
}

class Subscription implements ISubscription {
  errorSubscriptionsCount = 0
  errorSubscriptions: IErrorSubscriptions = {
    '1': [],
    '3': [],
    '4': [],
  }
  getErrorSubscriptionsCount(): number {
    return this.errorSubscriptionsCount
  }
  getErrorSubscriptions(): IErrorSubscriptions {
    return this.errorSubscriptions
  }
  // Status 1 - подписка создана но не оплачена
  // Status 3 - подписка на паузе
  // Status 4 - остановлена с причинами 3, 4, 5
  setErrorSubscriptions(subscription: ISubscriptions[]) {
    subscription.map(sub => {
      if (sub.status === 1) {
        this.errorSubscriptions[sub.status].push(sub)
        this.errorSubscriptionsCount++
      }
      if (sub.status === 3) {
        this.errorSubscriptions[sub.status].push(sub)
        this.errorSubscriptionsCount++
      }
      if (
        sub.status === 4 &&
        (sub.stopReason === 3 || sub.stopReason === 4 || sub.stopReason === 5)
      ) {
        this.errorSubscriptions[sub.status].push(sub)
        this.errorSubscriptionsCount++
      }
    })
    return this
  }
  reset() {
    this.errorSubscriptionsCount = 0
    this.errorSubscriptions = {
      '1': [],
      '3': [],
      '4': [],
    }
  }
}

export default Subscription
