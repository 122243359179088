import getConfig from 'next/config'
import { ITrackers } from 'src/api/external'

const {
  publicRuntimeConfig: { YANDEX_METRIKA = null, VK_PIXEL = null },
} = getConfig()

type TMethos = 'reachGoal'

type TTargetNames =
  | 'new_reg'
  | 'unsub_firststep'
  | 'sub_stop'
  | 'sub_start'
  | 'bill'
  | 'webview30sec'
  | 'webview5min'
  | 'webview30min'
  | 'webviewclick'
  | 'webviewend'
  | 'webendclick'
  | 'web_reg'
  | 'gift-cart_reg'

type TYandex = {
  methos: TMethos
  targetNames: TTargetNames
}

interface IMetrika {
  willskillYandex: (props: TYandex) => void
  offerrmYandex: (props: TYandex) => void
  offerrmVk: (props: TYandex) => void
  willskillVk: (props: TYandex) => void
}

class Metrika implements IMetrika {
  willskillYandex({ methos, targetNames }: TYandex) {
    YANDEX_METRIKA && ym(YANDEX_METRIKA as string, methos, targetNames)
    return this
  }
  offerrmYandex({ methos, targetNames }: TYandex) {
    if (typeof window !== 'undefined') {
      const trackers = sessionStorage.getItem('trackers')
      const parsedTrackers: ITrackers = trackers ? JSON.parse(trackers) : {}

      parsedTrackers['yandex_metrika'] &&
        ym(
          parsedTrackers['yandex_metrika'].metrika_id as string,
          methos,
          targetNames,
        )
    }
    return this
  }
  willskillVk({ methos, targetNames }: TYandex) {
    VK_PIXEL &&
      _tmr.push({
        type: methos,
        id: VK_PIXEL,
        goal: targetNames,
      })
    return this
  }
  offerrmVk({ methos, targetNames }: TYandex) {
    if (typeof window !== 'undefined') {
      const trackers = sessionStorage.getItem('trackers')
      const parsedTrackers: ITrackers = trackers ? JSON.parse(trackers) : {}

      parsedTrackers['vk'] &&
        _tmr.push({
          type: methos,
          id: parsedTrackers['vk'].vk_id,
          goal: targetNames,
        })
    }
    return this
  }
}

export default Metrika
