import { useCallback } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useTranslation } from 'next-i18next'
/**
 * components
 */
import { notify, ToastIcon } from '../../components/Toast'
/**
 * constants
 */
import { ALERT_CLOSE_WAIT } from '../../constants'

const useRecaptcha = () => {
  const { t } = useTranslation()
  const { executeRecaptcha } = useGoogleReCaptcha()

  const handleRecaptcha = useCallback(async (): Promise<string | undefined> => {
    if (!executeRecaptcha) {
      return
    }
    try {
      return await executeRecaptcha()
    } catch {
      notify({
        icon: ToastIcon.ERROR,
        autoClose: ALERT_CLOSE_WAIT,
        label: t('notify.recaptcha.error'),
      })
    }
  }, [executeRecaptcha])

  return { handleRecaptcha }
}

export default useRecaptcha
