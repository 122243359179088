import React from 'react'
import styled from 'styled-components'
import { useMutation, useQueryClient } from 'react-query'
import debounce from 'lodash/debounce'
import { useTranslation } from 'next-i18next'
/**
 * context
 */
import { useAppDispatch } from '../../context/app'
import { TModal } from './Modal'
/**
 * ui
 */
import Button, { ButtonSize, ButtonStyle } from '../Button'
import { notify, ToastIcon } from '../Toast'
/**
 * api
 */
import { premiumApi } from '../../api'
/**
 * utils
 */
import useHeaders from '../../hooks/useHeaders'
/**
 * constants
 */
import { DEBOUNCE_WAIT, ALERT_CLOSE_WAIT } from '../../constants'

const ModalUnsubscribe: React.FC = () => {
  const { t: tc } = useTranslation()
  const { t } = useTranslation('components.modal')
  const queryClient = useQueryClient()
  const dispatch = useAppDispatch()
  const { headers } = useHeaders()

  const setCloseModal = () =>
    dispatch({
      type: 'SET_MODAL',
      modalShown: false,
      modalType: TModal.BLANK,
    })

  const mutation = useMutation(() => premiumApi.postUnsubscribe(headers), {
    onSuccess: async () => {
      notify({
        icon: ToastIcon.SUCCESS,
        autoClose: ALERT_CLOSE_WAIT,
        label: tc('notify.premiumUnsubscribe.success'),
      })
      dispatch({
        type: 'SET_MODAL',
        modalShown: false,
        modalType: TModal.BLANK,
      })
      await queryClient.invalidateQueries(['getProfile'])
      await queryClient.invalidateQueries(['getCards'])
    },
  })
  const onClickUnsubscribe = debounce(() => {
    mutation.mutate()
  }, DEBOUNCE_WAIT)

  return (
    <StyledContainer>
      <StyledTitle>{t('ModalUnsubscribe.title')}</StyledTitle>
      <StyledSubtitle>{t('ModalUnsubscribe.subtitle')}</StyledSubtitle>
      <br />
      <StyledModalList>
        <StyledModalListItem>{t('ModalUnsubscribe.list1')}</StyledModalListItem>
        <StyledModalListItem>{t('ModalUnsubscribe.list2')}</StyledModalListItem>
        <StyledModalListItem>{t('ModalUnsubscribe.list3')}</StyledModalListItem>
      </StyledModalList>
      <StyledButtonContainer>
        <Button
          buttonStyle={ButtonStyle.FILLED}
          size={ButtonSize.BLOCK}
          onClick={setCloseModal}
          type='button'
        >
          {t('ModalUnsubscribe.stopSubscription')}
        </Button>
      </StyledButtonContainer>
      <StyledCancel onClick={onClickUnsubscribe}>
        {t('ModalUnsubscribe.cancelSubscription')}
      </StyledCancel>
    </StyledContainer>
  )
}
const StyledContainer = styled.div`
  background: #ffffff;
  box-shadow: 0 4px 54px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  width: 100%;
  padding: calc(35px + 21px) calc(24px + 15px);
  max-width: 100%;
  overflow: hidden;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    padding: 68px 97px;
    max-width: 555px;
  }
`

const StyledTitle = styled.h4`
  ${({ theme }) => theme.mixins.H4};
  text-align: left;
  margin-bottom: 33px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    ${({ theme }) => theme.mixins.H3};
  }
`

const StyledSubtitle = styled.p`
  ${({ theme }) => theme.mixins.textButton};
  text-align: left;
`

const StyledModalList = styled.ul`
  margin-left: 20px;
  margin-bottom: 33px;
`

const StyledModalListItem = styled.li`
  ${({ theme }) => theme.mixins.textSmall};
  list-style: disc;
`

const StyledButtonContainer = styled.div`
  width: 100%;
  margin-bottom: 22px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    width: 203px;
  }
`

const StyledCancel = styled.p`
  color: ${({ theme }) => theme.colors.primary};
  ${({ theme }) => theme.mixins.textLink};
  text-decoration: underline;
  cursor: pointer;
`
export default ModalUnsubscribe
