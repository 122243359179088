import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
/**
 * api
 */
import { useExternalQuery, ITrackers } from 'src/api/external'
/**
 * hooks and utils
 */
import useClientSide from 'src/hooks/useClientSide'
import { serialize } from 'src/utils/serialize'
import { useLocalStorage, useSessionStorage } from 'usehooks-ts'
import { ILocalStorage } from 'src/hooks/useLocalStorage/useLocalStorage'

function useAffrid() {
  const { asPath } = useRouter()
  const isClientSide = useClientSide()
  const [affRid, setAffRid] = useState('')

  // queries
  const { data: affRidData } = useExternalQuery.useGetAffRid(affRid)
  const [, setLocal] = useLocalStorage<ILocalStorage>('willskill', {
    cookieAgree: false,
    utm: '',
  })
  const [, setTrackerSession] = useSessionStorage<ITrackers>('trackers', {})

  useEffect(() => {
    const query = asPath.split('?')
    if (query.length > 1) {
      setAffRid('?' + query[1])
    }
  }, [asPath])

  useEffect(() => {
    if (affRidData && isClientSide) {
      if (affRidData.labels) {
        const referrer = document.referrer
          ? '&referrer=' + document.referrer
          : ''
        const serialized = serialize(affRidData.labels)
        setLocal(prev => ({
          ...prev,
          utm: encodeURIComponent('?' + serialized + referrer),
        }))
        setAffRid(() => '')
      }

      if (affRidData.trackers) {
        setTrackerSession(affRidData.trackers)
      }
    }
  }, [affRidData, isClientSide])
}

export default useAffrid
