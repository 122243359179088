import React from 'react'
import styled from 'styled-components'
/**
 * context
 */
import { useAppDispatch } from '../../context/app'
/**
 * ui
 */
import Button, { ButtonSize, ButtonStyle } from '../Button'
import { TModal } from './Modal'

const ModalRegistrationSuccess: React.FC = () => {
  const dispatch = useAppDispatch()

  const onClose = () => {
    dispatch({
      type: 'SET_MODAL',
      modalShown: false,
      modalType: TModal.BLANK,
    })
  }
  return (
    <StyledContainer>
      <StyledTitle>
        Спасибо за регистрацию. Письмо с данными для входа и подтверждения
        адреса отправлено на почту, указанную при регистрации.
      </StyledTitle>
      <StyledSubtitle>
        Мы будем отправлять вам на почту информацию о предстоящих бесплатных
        мастер-классах.
      </StyledSubtitle>
      <Button
        buttonStyle={ButtonStyle.FILLED}
        size={ButtonSize.BLOCK}
        onClick={onClose}
      >
        Хорошо
      </Button>
    </StyledContainer>
  )
}

export default ModalRegistrationSuccess

const StyledContainer = styled.div`
  text-align: center;
  background: #ffffff;
  box-shadow: 0 4px 54px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: calc(35px + 35px) calc(24px + 24px) calc(35px + 33px)
    calc(24px + 24px);
  max-width: 100%;
  overflow: hidden;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    padding: 68px 97px;
    max-width: 555px;
  }
`

const StyledTitle = styled.h4`
  ${({ theme }) => theme.mixins.H4};
  text-align: center;
  margin-bottom: 22px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    ${({ theme }) => theme.mixins.H3};
    margin-bottom: 28px;
  }
`

const StyledSubtitle = styled.p`
  margin-bottom: 10px;
`
