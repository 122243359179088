import { FC } from 'react'
import styled, { css } from 'styled-components'
import Icon from '../Icon'
import { IconPackType } from '../Icon/icons'

export enum TagType {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  LIGHT = 'light',
  CUSTOM_1 = 'custom1',
}

export enum TagSize {
  PADDING = 'padding',
}

interface Style {
  tagType: TagType
  errorGapRight?: string
  size?: TagSize
}
interface Props extends Style {
  label: string
  beforeIcon?: IconPackType
}

const Tag: FC<Props> = ({ tagType, label, beforeIcon, size }): JSX.Element => {
  const errorGapRight = beforeIcon ? '15px' : '7px'
  return (
    <StyledWrapper tagType={tagType} errorGapRight={errorGapRight} size={size}>
      {beforeIcon && (
        <StyledIconWrapper>
          <Icon name={beforeIcon} width='12' height='12' />
        </StyledIconWrapper>
      )}
      {label}
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div<Style>`
  border-radius: 100px;
  text-align: center;
  ${({ size }) => {
    switch (size) {
      case TagSize.PADDING:
        return css`
          display: flex;
          width: fit-content;
          align-items: center;
        `
    }
  }}

  ${({ tagType, errorGapRight }) => {
    switch (tagType) {
      case TagType.SUCCESS:
        return css`
          color: #ffffff;
          font-weight: 600;
          font-size: 0.7rem;
          line-height: 15px;
          background-color: ${({ theme }) => theme.colors.primary};
          padding: 5px 15px 6px 7px;
        `
      case TagType.ERROR:
        return css`
          color: #ffffff;
          font-weight: 600;
          font-size: 0.7rem;
          line-height: 15px;
          background-color: ${({ theme }) => theme.colors.error};
          padding: 5px ${errorGapRight} 6px 7px;
        `
      case TagType.LIGHT:
        return css`
          ${({ theme }) => theme.mixins.textLink};
          color: ${({ theme }) => theme.colors.primary};
          background-color: ${({ theme }) => theme.colors.primary};
          background-color: rgba(24, 168, 102, 0.2);
          padding: 9px 24px;
        `
      case TagType.WARNING:
        return css`
          ${({ theme }) => theme.mixins.textLink};
          color: ${({ theme }) => theme.colors.warning};
          background-color: ${({ theme }) => theme.colors.warning};
          background-color: rgba(255, 156, 84, 0.2);
          padding: 9px 24px;
        `
      case TagType.CUSTOM_1:
        return css`
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: #ffffff;
          background: #5ec394;
          padding: 6px 15px 6px 7px;
        `
      default:
        break
    }
  }}
`

const StyledIconWrapper = styled.span`
  margin-right: 10px;
  display: flex;
  align-items: center;
`

export default Tag
