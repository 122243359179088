import React, { createContext, Dispatch, useContext, useReducer } from 'react'
import styled, { css } from 'styled-components'
import { useRouter } from 'next/router'

export enum Type {
  PROGRAMS = 'programs',
  LESSONS = 'lessons',
  MATERIALS = 'materials',
}

type State = {
  current: string
  visible: boolean
}

type Action =
  | { type: 'SET_CURRENT'; payload: string }
  | { type: 'SET_VISIBILITY'; payload: boolean }

type NavigationDispatch = Dispatch<Action>

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_VISIBILITY':
      return {
        ...state,
        visible: action.payload,
      }
    case 'SET_CURRENT':
      return {
        ...state,
        current: action.payload,
      }
    default:
      throw new Error('Unhandled action')
  }
}
/**
 * --------------------------------------------------------
 * Navigation Context
 * --------------------------------------------------------
 */
const NavigationContext = createContext<State | null>(null)
const NavigationDispatchContext = createContext<NavigationDispatch | null>(null)

/**
 * --------------------------------------------------------
 * Navigation.Base
 * --------------------------------------------------------
 */
export type NavigationProps = {
  children?: React.ReactNode
  className?: string
  initial?: string
}
const Navigation: React.FC<NavigationProps> = React.forwardRef<
  HTMLDivElement,
  NavigationProps
>(({ className, children, initial }, ref) => {
  const [state, dispatch] = useReducer(reducer, {
    current: '',
    visible: false,
  })
  const router = useRouter()
  // должен отображать только /courses/modules/lesson or /courses/modules/test
  React.useEffect(() => {
    if (
      router.pathname === '/courses/modules/lesson' ||
      router.pathname === '/courses/modules/test'
    ) {
      dispatch({ type: 'SET_VISIBILITY', payload: true })
    }
  }, [router])

  // дефолтный current
  React.useEffect(() => {
    if (initial) {
      dispatch({ type: 'SET_CURRENT', payload: initial })
    }
  }, [initial])

  return (
    <StyledNaviation className={className} ref={ref} visible={state.visible}>
      <NavigationContext.Provider value={state}>
        <NavigationDispatchContext.Provider value={dispatch}>
          {children}
        </NavigationDispatchContext.Provider>
      </NavigationContext.Provider>
    </StyledNaviation>
  )
})
export const useNavigationState = () => {
  const state = useContext(NavigationContext)
  if (!state) throw new Error('Cannot find NavigationProvider')
  return state
}

export const useNavigationDispatch = () => {
  const dispatch = useContext(NavigationDispatchContext)
  if (!dispatch) throw new Error('Cannot find NavigationDispatchProvider')
  return dispatch
}

Navigation.displayName = 'Navigation'

const StyledNaviation = styled.div<{ visible: boolean }>`
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  right: 0;
  background-color: ${({ theme }) => theme.colors.black};
  height: 58px;
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  padding: 0 40px;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    height: 89px;
    padding: 0 172px;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    display: none;
  }
`
/**
 * --------------------------------------------------------
 * Navigation.Menu
 * --------------------------------------------------------
 */
export type NavigationMenuProps = {
  children?: React.ReactNode
  className?: string
}
const NavigationMenu: React.FC<NavigationMenuProps> = React.forwardRef<
  HTMLUListElement,
  NavigationItemProps
>(({ className, children }, ref) => {
  return (
    <StyledNavigationMenu ref={ref} className={className}>
      {children}
    </StyledNavigationMenu>
  )
})

NavigationMenu.displayName = 'NavigationMenu'

const StyledNavigationMenu = styled.ul`
  list-style: none;
  display: flex;
  justify-content: space-between;
  width: 100%;
`

/**
 * --------------------------------------------------------
 * Navigation.Item
 * --------------------------------------------------------
 */
export type NavigationItemOnClickProps = {
  e: React.MouseEvent<HTMLLIElement>
  name: string
}
export type NavigationItemProps = {
  children?: React.ReactNode
  className?: string
  name?: string
  onClick?: (props: NavigationItemOnClickProps) => void
}
const NavigationItem: React.FC<NavigationItemProps> = React.forwardRef<
  HTMLLIElement,
  NavigationItemProps
>(({ className, children, name = '', onClick }, ref) => {
  const { current } = useNavigationState()
  const dispatch = useNavigationDispatch()

  const isCurrent = name === current
  const onHandleClick = React.useCallback(
    (e: React.MouseEvent<HTMLLIElement>) => {
      dispatch({ type: 'SET_CURRENT', payload: name })

      if (onClick) {
        onClick({ e, name })
      }
    },
    [],
  )

  return (
    <StyledNavigationItem
      ref={ref}
      className={className}
      isCurrent={isCurrent}
      aria-label={name}
      onClick={onHandleClick}
    >
      {children}
    </StyledNavigationItem>
  )
})

NavigationItem.displayName = 'NavigationItem'

const StyledNavigationItem = styled.li<{ isCurrent: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  & .navigation_text {
    ${({ isCurrent }) => {
      if (isCurrent) {
        return css`
          color: ${({ theme }) => theme.colors.primary};
        `
      }
    }}
  }
  svg {
    path {
      ${({ isCurrent }) => {
        if (isCurrent) {
          return css`
            stroke: ${({ theme }) => theme.colors.primary};
          `
        }
      }}
    }
  }
`

/**
 * --------------------------------------------------------
 * Navigation.Text
 * --------------------------------------------------------
 */
export type NavigationTextProps = {
  children?: React.ReactNode
  className?: string
}
const NavigationText: React.FC<NavigationTextProps> = React.forwardRef<
  HTMLDivElement,
  NavigationTextProps
>(({ className, children }, ref) => {
  return (
    <StyledNavigationText ref={ref} className={className}>
      {children}
    </StyledNavigationText>
  )
})

NavigationText.displayName = 'NavigationText'

const StyledNavigationText = styled.div`
  font-weight: 600;
  font-size: 8px;
  line-height: 10px;
  text-align: center;
  color: ${({ theme }) => theme.colors.grey};
  margin-top: 10px;
`
/**
 * --------------------------------------------------------
 * Exports
 * --------------------------------------------------------
 */
const Base = Navigation
const Item = NavigationItem
const Menu = NavigationMenu
const Text = NavigationText

export { Base, Item, Menu, Text }
