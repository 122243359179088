import React, { FC } from 'react'
import styled, { css } from 'styled-components'

type TLabel = {
  label: string
  href?: string
}
export enum LabelSize {
  DEFAULT = 'default',
  MEDIUM = 'medium',
}

interface Style {
  labelSize?: LabelSize
}

interface Props extends Style {
  id?: string
  labels?: TLabel[]
  disabled?: boolean
  value?: string
  name?: string
}

const Checkbox: FC<Props> = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      id,
      labels,
      disabled,
      labelSize = LabelSize.DEFAULT,
      value,
      name,
      ...rest
    },
    ref,
  ) => {
    return (
      <StyledWrapper>
        <StyledInput
          type='checkbox'
          value={value}
          id={id}
          name={name}
          ref={ref}
          disabled={disabled}
          {...rest}
        />
        {labels && (
          <StyledLabel htmlFor={id} labelSize={labelSize}>
            {labels.map(({ label, href }, idx) => {
              if (!href) {
                return label
              }
              if (href) {
                return (
                  <StyledLink
                    target='_blank'
                    href={href}
                    rel='noreferrer'
                    key={idx.toString()}
                  >
                    {label}
                  </StyledLink>
                )
              }
              return null
            })}
          </StyledLabel>
        )}
      </StyledWrapper>
    )
  },
)

Checkbox.displayName = 'Checkbox'
export default Checkbox

const StyledWrapper = styled.div`
  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.alignItems('center')}
`

const StyledInput = styled.input`
  /* Base for label styling */
  &:not(:checked),
  &:checked {
    position: absolute;
    left: 0;
    opacity: 0.01;
  }
  &:not(:checked) + label,
  &:checked + label {
    position: relative;
    padding-left: 38px;
    cursor: pointer;
  }
  /* checkbox aspect */
  &:not(:checked) + label:before,
  &:checked + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    /* box-shadow: 0px 4px 6px rgba(41, 47, 89, 0.06); */
    border: 1px solid ${({ theme }) => theme.colors.primary};
    background: #fff;
    border-radius: 4px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1),
      0 0 0 rgba(24, 168, 102, 0.2);
    ${({ theme }) => theme.mixins.transition('all 0.275s')}
  }
  /* checked mark aspect */
  &:not(:checked) + label:after,
  &:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 4px;
    left: 7px;
    width: 6px;
    height: 9px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    ${({ theme }) => theme.mixins.transition('all 0.3s')}
  }
  /* checked mark aspect changes */
  &:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0) rotate(45deg);
    transform: scale(0) rotate(0deg);
  }

  &:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1) rotate(0);
    transform: scale(1) rotate(45deg);
  }
  &:checked + label::before {
    background: ${({ theme }) => theme.colors.primary};
  }
  /* disabled */
  &:disabled + label::before {
    background: ${({ theme }) => theme.colors.grey};
  }
  &:disabled + label:before {
    border: 1px solid ${({ theme }) => theme.colors.grey};
  }
  &:disabled + label {
    cursor: not-allowed;
    pointer-events: none;
  }
  /* Accessibility */
  &:checked:focus + label:before,
  &:not(:checked):focus + label:before {
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1),
      0 0 0 3px rgba(24, 168, 102, 0.3);
  }
`

const StyledLabel = styled.label<{ labelSize: LabelSize }>`
  width: 100%;
  ${({ labelSize }) => {
    switch (labelSize) {
      case LabelSize.DEFAULT:
        return css`
          font-size: 0.75rem;
          line-height: 18px;
          font-weight: 400;
        `
      case LabelSize.MEDIUM:
        return css`
          font-weight: 400;
          font-size: 18px;
          line-height: 22px;
        `
      default:
        break
    }
  }}
`

const StyledLink = styled.a`
  text-decoration: none;
  width: 100%;
  font-size: 0.75rem;
  line-height: 18px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.black};
  &:visited {
    text-decoration: none;
  }
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`
