import { useSession } from 'next-auth/react'
import React, { useState } from 'react'
import getConfig from 'next/config'
import { useSessionStorage } from 'usehooks-ts'

const {
  publicRuntimeConfig: { EXPORT },
} = getConfig()

const useHeaders =
  EXPORT === 'true'
    ? (): {
        headers: { [key: string]: string }
        isLoggedIn: boolean
        status: 'authenticated' | 'loading' | 'unauthenticated'
      } => {
        return {
          headers: {},
          isLoggedIn: false,
          status: 'unauthenticated',
        }
      }
    : (): {
        headers: { [key: string]: string }
        isLoggedIn: boolean
        status: 'authenticated' | 'loading' | 'unauthenticated'
      } => {
        const { data: session, status } = useSession()
        const [headers, setHeaders] = useState<{
          [key: string]: string
        }>({})
        const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false)
        const [sessionToken] = useSessionStorage('sessionToken', '')

        React.useEffect(() => {
          if (session) {
            setHeaders(prev => ({
              ...prev,
              'X-Auth-Token': session.token as string,
            }))
            setIsLoggedIn(prev => !prev)
          }

          if (sessionToken) {
            setHeaders({ 'X-Auth-Token': sessionToken })
          }
        }, [session])

        return {
          headers,
          isLoggedIn,
          status,
        }
      }

export default useHeaders
