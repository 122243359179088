import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'next-i18next'
/**
 * context
 */
import { useAppDispatch } from '../../context/app'
import { TModal } from './Modal'
/**
 * ui
 */
import Button, { ButtonSize, ButtonStyle } from '../Button'

const ModalStop: React.FC = () => {
  const { t } = useTranslation('components.modal')
  const dispatch = useAppDispatch()

  const setCloseModal = React.useCallback(() => {
    dispatch({
      type: 'SET_MODAL',
      modalShown: false,
      modalType: TModal.BLANK,
    })
    dispatch({
      type: 'SET_SLUG',
      slug: '',
    })
  }, [dispatch])
  return (
    <StyledContainer>
      <StyledTitle>{t('ModalStop.title')}</StyledTitle>

      <StyledButtonContainer>
        <Button
          buttonStyle={ButtonStyle.FILLED}
          size={ButtonSize.BLOCK}
          onClick={setCloseModal}
        >
          {t('ModalStop.yes')}
        </Button>
      </StyledButtonContainer>
    </StyledContainer>
  )
}
const StyledContainer = styled.div`
  background: #ffffff;
  box-shadow: 0 4px 54px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: calc(35px + 35px) calc(24px + 24px) calc(35px + 33px)
    calc(24px + 24px);
  max-width: 100%;
  overflow: hidden;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    padding: 68px 97px;
    max-width: 555px;
  }
`

const StyledTitle = styled.h4`
  width: 80%;
  ${({ theme }) => theme.mixins.H4};
  text-align: center;
  margin-bottom: 22px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    ${({ theme }) => theme.mixins.H3};
    margin-bottom: 28px;
  }
`

const StyledButtonContainer = styled.div`
  width: 100%;
  margin-bottom: 22px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    width: 203px;
  }
`

export default ModalStop
