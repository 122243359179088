import React from 'react'
import styled from 'styled-components'

type Props = {
  blockClickOutside?: boolean
}

const ModalTinkoff = React.forwardRef<HTMLDivElement, Props>(
  ({ blockClickOutside = false }, ref) => {
    return (
      <StyledContainer
        id='tinkoff-iframe'
        ref={blockClickOutside ? null : ref}
      />
    )
  },
)

ModalTinkoff.displayName = 'ModalTinkoff'
export default ModalTinkoff

const StyledContainer = styled.div`
  background: #ffffff;
  box-shadow: 0 4px 54px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 35px 24px;
  width: 100%;
  max-width: 100%;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    padding: 30px;
    width: 600px;
  }
`
