import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'next-i18next'
/**
 * context
 */
import { useAppState } from '../../context/app'
/**
 * ui
 */
import Button, { ButtonSize, ButtonStyle } from '../Button'
/**
 * api
 */
import { useSubscriptions } from '../../api/subscription'
import { useCardQuery } from '../../api/card'
import { useProductQuery } from '../../api/product'
/**
 * utils
 */
import usePayments from '../../hooks/usePayments'
import useHeaders from '../../hooks/useHeaders'

const ModalSelectInitialPayType: React.FC = () => {
  const { t } = useTranslation()
  const { slug } = useAppState()
  const { headers, isLoggedIn } = useHeaders()
  const { handleCards } = usePayments()
  // queries
  const { data, isLoading } = useSubscriptions.useGetSubscriptionBySlug(
    headers,
    slug,
  )
  const { data: cardData, isLoading: cardIsLoading } =
    useCardQuery.useGetCards(headers)
  const { data: productData, isLoading: productIsLoading } =
    useProductQuery.useGetProductsSlug(slug, isLoggedIn, headers)

  if (
    !data ||
    isLoading ||
    !cardData ||
    cardIsLoading ||
    !isLoggedIn ||
    !productData ||
    productIsLoading
  ) {
    return null
  }

  return (
    <StyledContainer>
      <StyledTitle>Выберите пожалуйста</StyledTitle>

      {productData.initialAmounts && (
        <StyledBtnContainer>
          <Button
            size={ButtonSize.BLOCK}
            buttonStyle={ButtonStyle.FILLED}
            onClick={() =>
              handleCards({
                slug,
                body: {
                  card: null,
                  capture: true,
                  type: 'course',
                },
              })
            }
          >
            Купить курс полностью за{' '}
            {t('intlNumber', {
              val: productData.initialAmounts.course / 100,
            })}{' '}
            руб.
          </Button>
        </StyledBtnContainer>
      )}

      <Button
        buttonStyle={ButtonStyle.OUTLINED}
        size={ButtonSize.BLOCK}
        onClick={() =>
          handleCards({
            slug,
            body: {
              card: null,
              capture: true,
              type: 'firstFree',
            },
          })
        }
      >
        Попробовать неделю за 1 руб
      </Button>
    </StyledContainer>
  )
}

export default ModalSelectInitialPayType

const StyledContainer = styled.div`
  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.flexDirection('column')}
  ${({ theme }) => theme.mixins.alignItems('center')}
  background: #ffffff;
  box-shadow: 0 4px 54px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  width: 100%;
  padding: calc(35px + 35px) calc(24px + 24px) calc(35px + 33px)
    calc(24px + 24px);
  max-width: 100%;
  overflow: hidden;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    padding: 68px 97px;
    width: 555px;
  }
`

const StyledTitle = styled.h4`
  ${({ theme }) => theme.mixins.H4};
  text-align: center;
  margin-bottom: 22px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    ${({ theme }) => theme.mixins.H3};
    margin-bottom: 28px;
  }
`

const StyledBtnContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
`
