import React from 'react'
import Image from 'next/image'
import styled from 'styled-components'
/**
 * context
 */
import { useAppDispatch } from '../../context/app'
import { TModal } from './Modal'
/**
 * ui
 */
import Button, { ButtonSize, ButtonStyle } from '../Button'

const ModalFinishCourse: React.FC = () => {
  const dispatch = useAppDispatch()

  const setCloseModal = React.useCallback(() => {
    dispatch({
      type: 'SET_MODAL',
      modalShown: false,
      modalType: TModal.BLANK,
    })
    dispatch({
      type: 'SET_SLUG',
      slug: '',
    })
  }, [dispatch])
  return (
    <StyledContainer>
      <StyledTitle>
        Поздравляем <br />с завершением курса!
      </StyledTitle>
      <StyledImage>
        <Image
          src='/assets/images/congratulations.svg'
          alt='congratulations'
          width={294}
          height={284}
        />
      </StyledImage>
      <br />
      <StyledButtonContainer>
        <Button
          buttonStyle={ButtonStyle.FILLED}
          size={ButtonSize.BLOCK}
          onClick={setCloseModal}
        >
          Получить сертификат
        </Button>
      </StyledButtonContainer>
    </StyledContainer>
  )
}
const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: #ffffff;
  box-shadow: 0 4px 54px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 35px 24px;
  max-width: 100%;
  overflow: hidden;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    padding: 68px 97px;
    width: 555px;
  }
`

const StyledTitle = styled.h4`
  ${({ theme }) => theme.mixins.H4};
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
  margin-bottom: 22px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    ${({ theme }) => theme.mixins.H3};
    margin-bottom: 28px;
  }
`

const StyledImage = styled.div`
  margin-top: 50px;
`

const StyledButtonContainer = styled.div`
  width: 100%;
  margin-bottom: 22px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    width: 203px;
  }
`

export default ModalFinishCourse
