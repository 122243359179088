// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
import { Integrations } from '@sentry/tracing'
import getConfig from 'next/config'

const {
  publicRuntimeConfig: { SENTRY_DSN_CLIENT, ENV_NAME },
} = getConfig()

Sentry.init({
  dsn: SENTRY_DSN_CLIENT,
  ignoreErrors: [
    'Non-Error exception captured',
    'Non-Error promise rejection captured',
  ],
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.0,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  debug: false,
  environment: ENV_NAME,
  initialScope: scope => {
    scope.setTags({ side: 'next-client-side' })
    return scope
  },
  autoSessionTracking: true,
  integrations: [new Integrations.BrowserTracing()],
})
