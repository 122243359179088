import 'react-toastify/dist/ReactToastify.css'
import React, { useState } from 'react'
import { ThemeProvider } from 'styled-components'
import { QueryClient, QueryClientProvider, Hydrate } from 'react-query'
// import { ReactQueryDevtools } from 'react-query/devtools'
import { useRouter } from 'next/router'
import Reset from '../styles/reset'
import Normalize from '../styles/normalize'
import GlobalStyle from '../styles/globalStyle'
import theme from '../styles/themes/theme'
import AppProvider from '../context'
import { SessionProvider, useSession, signIn, signOut } from 'next-auth/react'
import { AppAuthProps } from '../utils/auth'
import { ToastContainer } from 'react-toastify'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import getConfig from 'next/config'
import { appWithTranslation, useTranslation } from 'next-i18next'
/**
 * ui
 */
import LoadingTemplate from 'src/components/Template/LoadingTemplate'
import Scripts from 'src/components/Scripts'
/**
 * utils
 */
import checkCookieName from 'src/utils/checkCookieName'
// import useScrollRestoration from 'src/hooks/useScrollRestoration'
// api
import { useSecureMutation } from 'src/api/secure'
// constants
import { ALERT_CLOSE_WAIT } from 'src/constants'
// components
import { notify, ToastIcon } from 'src/components/Toast'
//context
import { useAppState, useAppDispatch } from 'src/context/app'

const {
  publicRuntimeConfig: { RECAPTCHA_SITE_KEY, EXPORT, INTERNALIZATION, API_URL },
} = getConfig()

const CheckRecaptchaKey = ({ children }: React.PropsWithChildren) => {
  const { recaptcha } = useAppState()
  const { i18n } = useTranslation()

  return recaptcha.length > 0 ? (
    <GoogleReCaptchaProvider reCaptchaKey={recaptcha} language={i18n.language}>
      {children}
    </GoogleReCaptchaProvider>
  ) : (
    <>{children}</>
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Auth = ({ children }: any) => {
  const dispatch = useAppDispatch()
  const router = useRouter()
  const { data: session, status } = useSession()
  const handleMagicLink = useSecureMutation.usePostMagicLink()
  const { t } = useTranslation('common')

  const handle = async (token: string) => {
    const res = await handleMagicLink.mutateAsync({ mtoken: token })
    // @ts-ignore
    const params = new URLSearchParams(router.query)
    params.delete('mtoken')
    await router.replace(
      { pathname: router.pathname, query: params.toString() },
      undefined,
      { shallow: true },
    )
    await signIn('loginwithMagicLink', {
      redirect: false,
      token: res.data.token,
    })
  }
  const handleSignOut = async () => {
    await signOut({ redirect: false })
  }
  const isUser = !!session?.token

  React.useEffect(() => {
    if (status === 'loading') return
    // Если требуется не авторизованног на страницу protect
    // redirect to '/?modal=login'
    // if (!isUser) {
    //   signOut({ callbackUrl: '/?modal=login' })
    // }
    if (status === 'authenticated' && router.query.mtoken) {
      // @ts-ignore
      handleSignOut(router.query.mtoken as string).catch(() => {
        console.log('error mtoken')
      })
    }
    // 만약 로그인이 안되어있고, mtoken이 있다면.
    if (status === 'unauthenticated' && router.query.mtoken) {
      handle(router.query.mtoken as string).catch(() => {
        notify(
          {
            icon: ToastIcon.ERROR,
            autoClose: ALERT_CLOSE_WAIT,
            label: t('notify.accessDenined'),
          },
          () => {
            return router.push('/?modal=login')
          },
        )
      })
    }
  }, [isUser, status, session?.token, router.query.mtoken])

  if (isUser) {
    return children
  }
  if (handleMagicLink.isError) {
    // @ts-ignore
    const url = new URL(window.location)
    const params = new URLSearchParams(url.search)
    params.delete('mtoken')

    router.push('/?modal=login').then(() => {
      dispatch({
        type: 'SET_PREVIOUS_ROUTE',
        previousRoute: `${API_URL}${url.pathname}?${params}`,
      })
      return children
    })
  }
  // Session is being fetched, or no user.
  // If no user, useEffect() will redirect.
  return <LoadingTemplate />
}

const MyApp = ({ Component, pageProps }: AppAuthProps) => {
  const router = useRouter()
  // useScrollRestoration(router)
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
          },
        },
      }),
  )
  // set locale when in cookie has locale
  React.useEffect(() => {
    const { pathname, asPath, query, locale } = router
    if (typeof window !== 'undefined') {
      const match = checkCookieName('NEXT_LOCALE')
      if (match && match !== locale) {
        router.push({ pathname, query }, asPath, { locale: match })
      }
    }
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={pageProps.dehydratedState}>
        <AppProvider
          internalization={JSON.parse(INTERNALIZATION) as boolean}
          exports={JSON.parse(EXPORT) as boolean}
          recaptcha={RECAPTCHA_SITE_KEY}
        >
          <CheckRecaptchaKey>
            <ThemeProvider theme={theme}>
              <Reset />
              <Normalize />
              <GlobalStyle />
              {Component.auth?.require ? (
                <Auth>
                  <Scripts />
                  <Component {...pageProps} />
                  <ToastContainer />
                </Auth>
              ) : (
                <>
                  <Scripts />
                  <Component {...pageProps} />
                  <ToastContainer />
                </>
              )}
            </ThemeProvider>
          </CheckRecaptchaKey>
        </AppProvider>
      </Hydrate>
      {/* <ReactQueryDevtools /> */}
    </QueryClientProvider>
  )
}

const MyAppExport = ({ Component, pageProps }: AppAuthProps) => {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
          },
        },
      }),
  )
  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={pageProps.dehydratedState}>
        <AppProvider
          internalization={JSON.parse(INTERNALIZATION) as boolean}
          exports={JSON.parse(EXPORT) as boolean}
        >
          <ThemeProvider theme={theme}>
            <GlobalStyle />
            <Component {...pageProps} />
          </ThemeProvider>
        </AppProvider>
      </Hydrate>
    </QueryClientProvider>
  )
}

const AppWithI18n = appWithTranslation(MyApp)

const AppWithAuth = (props: AppAuthProps) => (
  <SessionProvider
    session={props.pageProps.session}
    basePath='auth'
    refetchOnWindowFocus={false}
  >
    <AppWithI18n {...props} />
  </SessionProvider>
)

export default EXPORT === 'false' ? AppWithAuth : MyAppExport
