import React from 'react'
import styled from 'styled-components'
import { SubmitHandler, useForm } from 'react-hook-form'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
/**
 * context
 */
import { useAppDispatch } from '../../context/app'
/**
 * ui
 */
import Input, { InputStyle } from '../Input'
import Button, { ButtonSize, ButtonStyle } from '../Button'
import { TModal } from './Modal'

type TFormValue = {
  firstName: string
  lastName: string
  phone: string
  isPhoneValid: boolean
}

const ModalWhatIsYourName: React.FC = () => {
  const { t: tc } = useTranslation()
  const { t } = useTranslation('components.modal')
  const router = useRouter()
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    watch,
    clearErrors,
    formState: { isValid, errors, isSubmitting, isDirty },
  } = useForm<TFormValue>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      firstName: '',
      lastName: '',
      phone: '',
      isPhoneValid: false,
    },
  })
  const dispatch = useAppDispatch()
  const watchPhoneNumber = watch('phone')

  const onSubmit: SubmitHandler<TFormValue> = async data => {
    dispatch({
      type: 'SET_MODAL',
      modalShown: false,
      modalType: TModal.BLANK,
    })
    return router.push({
      pathname: '/settings',
      query: {
        phone: data.phone,
        firstName: data.firstName,
        lastName: data.lastName,
      },
    })
  }

  return (
    <StyledContainer onSubmit={handleSubmit(onSubmit)}>
      {/* title */}
      <StyledTitle>{t('ModalWhatIsYourName.title')}</StyledTitle>
      {/* description */}
      <StyledDescription>
        {t('ModalWhatIsYourName.description')}
      </StyledDescription>
      <StyledInputWrapper>
        <Input
          errorMessage={errors?.firstName?.message}
          inputStyle={InputStyle.DEFAULT}
          type='text'
          label={t('ModalWhatIsYourName.firstName')}
          {...register('firstName', {
            required: {
              value: true,
              message: tc('validations.common.required'),
            },
            maxLength: {
              value: 25,
              message: tc('validations.common.maxLength', { num: 25 }),
            },
            pattern: {
              value: /^[\u0400-\u04FF]+$/,
              message: tc('validations.pattern.onlyRussian'),
            },
          })}
        />
      </StyledInputWrapper>

      <StyledInputWrapper>
        <Input
          errorMessage={errors?.lastName?.message}
          inputStyle={InputStyle.DEFAULT}
          type='text'
          label={t('ModalWhatIsYourName.lastName')}
          {...register('lastName', {
            required: {
              value: true,
              message: tc('validations.common.required'),
            },
            maxLength: {
              value: 25,
              message: tc('validations.common.maxLength', { num: 25 }),
            },
            pattern: {
              value: /^[\u0400-\u04FF]+$/,
              message: tc('validations.pattern.onlyRussian'),
            },
          })}
        />
      </StyledInputWrapper>

      <StyledInputWrapper>
        <StyledLabel>{t('ModalWhatIsYourName.telephone')}</StyledLabel>
        <PhoneInput
          onlyCountries={[
            'ru',
            'az',
            'am',
            'by',
            'kz',
            'kg',
            'md',
            'tj',
            'tm',
            'uz',
            'ua',
          ]}
          country='ru'
          value={watchPhoneNumber}
          onChange={(phone, data) => {
            if (errors?.phone?.message) {
              clearErrors(['phone'])
            }
            if (phone.match(/12345/)) {
              if ('name' in data) {
                setError('phone', {
                  type: 'manual',
                  message: `Invalid value: ${phone} ${data.name}`,
                })
              }
            } else if (phone.match(/1234/)) {
              setValue('isPhoneValid', false)
            } else {
              setValue('isPhoneValid', true)
            }
            setValue('phone', phone)
          }}
        />
        <StyledPhoneWaring>
          {t('ModalWhatIsYourName.warning')}
        </StyledPhoneWaring>
      </StyledInputWrapper>

      <StyledButtonWrapper>
        <Button
          buttonStyle={ButtonStyle.FILLED}
          size={ButtonSize.BLOCK}
          disabled={!isValid || isSubmitting || !isDirty}
          type='submit'
        >
          {t('ModalWhatIsYourName.save')}
        </Button>
      </StyledButtonWrapper>
    </StyledContainer>
  )
}
export default ModalWhatIsYourName

const StyledContainer = styled.form`
  width: 100%;
  background: #ffffff;
  box-shadow: 0 4px 54px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 35px 24px;
  max-width: 100%;
  overflow: hidden;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    padding: 68px 97px;
    max-width: 555px;
  }
`

const StyledTitle = styled.h4`
  ${({ theme }) => theme.mixins.H4}
  text-align: left;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    ${({ theme }) => theme.mixins.H3};
  }
`

const StyledDescription = styled.p`
  margin-top: 16px;
  ${({ theme }) => theme.mixins.textSmall}
`

const StyledInputWrapper = styled.div`
  margin-top: 24px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    margin-bottom: 25px;
  }
`

const StyledLabel = styled.span`
  font-family: ${({ theme }) => theme.mixins.textSmall};
  color: ${({ theme }) => theme.colors.black};
  text-align: left;
  margin-bottom: 12px;
  display: inline-block;
`

const StyledPhoneWaring = styled.span`
  ${({ theme }) => theme.mixins.textXSmall}
  color: #27AE60;
  margin-top: 4px;
`

const StyledButtonWrapper = styled.div`
  width: 100%;
  margin-top: 25px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    width: 203px;
    margin: 0 auto;
  }
`
