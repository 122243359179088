import React from 'react'
import styled from 'styled-components'
import { useQueryClient } from 'react-query'
import debounce from 'lodash/debounce'
import { useTranslation } from 'next-i18next'
/**
 * context
 */
import { useAppDispatch, useAppState } from '../../context/app'
import { TModal } from './Modal'
/**
 * ui
 */
import Button, { ButtonSize, ButtonStyle } from '../Button'
import { notify, ToastIcon } from '../Toast'
/**
 * api
 */
import { useCardMutation } from '../../api/card'
/**
 * constants
 */
import { DEBOUNCE_WAIT, ALERT_CLOSE_WAIT } from '../../constants'
/**
 * hooks
 */
import useHeaders from '../../hooks/useHeaders'

const ModalCardDisable: React.FC = () => {
  const { t: tc } = useTranslation()
  const { t } = useTranslation('components.modal')
  const queryClient = useQueryClient()
  const dispatch = useAppDispatch()
  const { headers } = useHeaders()
  const { cardUUID } = useAppState()

  const disableCardMutation = useCardMutation.usePostDisableCard()

  const setCloseModal = React.useCallback(() => {
    dispatch({
      type: 'SET_CARD_UUID',
      cardUUID: '',
    })
    dispatch({
      type: 'SET_MODAL',
      modalShown: false,
      modalType: TModal.BLANK,
    })
  }, [dispatch])

  const onDisableCard = debounce(() => {
    disableCardMutation
      .mutateAsync({
        headers,
        uuid: cardUUID,
      })
      .then(async () => {
        await queryClient.invalidateQueries(['getCards'])
        setCloseModal()
        notify({
          icon: ToastIcon.SUCCESS,
          autoClose: ALERT_CLOSE_WAIT,
          label: tc('notify.cardRemove.success'),
        })
      })
      .catch(({ response }) => {
        notify({
          icon: ToastIcon.ERROR,
          autoClose: ALERT_CLOSE_WAIT,
          label: response?.data.error.message ?? '',
        })
      })
  }, DEBOUNCE_WAIT)
  return (
    <StyledContainer>
      <StyledTitle>{t('ModalCardDisable.title')}</StyledTitle>
      <StyledButtonContainer>
        <Button
          buttonStyle={ButtonStyle.FILLED}
          size={ButtonSize.BLOCK}
          onClick={setCloseModal}
          type='button'
        >
          {t('ModalCardDisable.leaveCard')}
        </Button>
      </StyledButtonContainer>
      <StyledCancel onClick={onDisableCard}>
        {t('ModalCardDisable.untieCard')}
      </StyledCancel>
    </StyledContainer>
  )
}

export default ModalCardDisable

const StyledContainer = styled.div`
  background: #ffffff;
  box-shadow: 0 4px 54px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  width: 100%;
  padding: calc(35px + 21px) calc(24px + 15px);
  max-width: 100%;
  overflow: hidden;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    padding: 68px 97px;
    max-width: 555px;
  }
  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.flexDirection('column')}
  ${({ theme }) => theme.mixins.alignItems('center')}
`

const StyledTitle = styled.h4`
  ${({ theme }) => theme.mixins.H4};
  text-align: center;
  margin-bottom: 33px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    ${({ theme }) => theme.mixins.H3};
  }
`

const StyledButtonContainer = styled.div`
  width: 100%;
  margin-bottom: 22px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    width: 203px;
  }
`

const StyledCancel = styled.p`
  color: ${({ theme }) => theme.colors.primary};
  ${({ theme }) => theme.mixins.textLink};
  text-decoration: underline;
  cursor: pointer;
`
