import React from 'react'
import styled from 'styled-components'
/**
 * ui
 */
import Layout, { MainStyle } from 'src/components/Layout/Layout'
import { FooterStyle } from 'src/components/Footer'
/**
 * images
 */
import Loading from 'public/assets/images/loading.svg'

const LoadingTemplate: React.FC = () => {
  return (
    <Layout footerStyle={FooterStyle.BLOCK} mainStyle={MainStyle.MAIN}>
      <StyledWrapper>
        <StyledLoadingImage />
      </StyledWrapper>
    </Layout>
  )
}

const StyledWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 60px;
`

const StyledLoadingImage = styled(Loading)`
  animation: rotate_image 10s linear infinite;
  transform-origin: 50% 50%;

  @keyframes rotate_image {
    100% {
      transform: rotate(360deg);
    }
  }
`

export default LoadingTemplate
