import React from 'react'
import styled from 'styled-components'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
/**
 * context
 */
import { useAppDispatch } from '../../context/app'
import { TModal } from './Modal'
/**
 * ui
 */
import Button, { ButtonSize, ButtonStyle } from '../Button'

const ModalFillPersonalDetails: React.FC = () => {
  const { t } = useTranslation('components.modal')
  const router = useRouter()
  const dispatch = useAppDispatch()

  const setCloseModal = React.useCallback(() => {
    dispatch({
      type: 'SET_MODAL',
      modalShown: false,
      modalType: TModal.BLANK,
    })
    return router.push('/settings')
  }, [dispatch, router])

  return (
    <StyledContainer>
      <StyledTitle>{t('ModalFillPersonalDetails.title')}</StyledTitle>
      <StyledDescription>
        {t('ModalFillPersonalDetails.description')}
      </StyledDescription>

      <Button
        buttonStyle={ButtonStyle.FILLED}
        size={ButtonSize.PADDING_XSMALL}
        onClick={setCloseModal}
        afterIcon='arrow-right'
      >
        {t('ModalFillPersonalDetails.fill')}
      </Button>
    </StyledContainer>
  )
}

export default ModalFillPersonalDetails

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  box-shadow: 0 4px 54px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 35px 24px;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    padding: 68px 97px;
    max-width: 555px;
  }
`

const StyledTitle = styled.h4`
  ${({ theme }) => theme.mixins.H4};
  text-align: center;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    ${({ theme }) => theme.mixins.H3};
  }
`

const StyledDescription = styled.div`
  margin-top: 25px;
  text-align: center;
  margin-bottom: 50px;
`
