import React from 'react'
import styled, { css } from 'styled-components'
import Icon from '../Icon'

export enum LabelSize {
  DEFAULT = 'default',
  MEDIUM = 'medium',
}

interface Style {
  checked?: boolean
  labelSize?: LabelSize
}

interface Props extends Style {
  name?: string
  label?: string
  onClick?: () => void
}

const Radio: React.FC<Props> = ({
  checked,
  label = '',
  onClick,
  labelSize = LabelSize.DEFAULT,
}): JSX.Element => {
  return (
    <StyledContainer checked={checked} onClick={onClick}>
      <Icon name={checked ? 'success-tick' : 'radio'} width='18' height='18' />
      {label && <StyledLabel labelSize={labelSize}>{label}</StyledLabel>}
    </StyledContainer>
  )
}

const StyledContainer = styled.div<Style>`
  display: flex;
  align-items: center;
  cursor: pointer;
  ${({ checked }) => {
    if (checked) {
      return css`
        // circle {
        //   stroke: ${({ theme }) => theme.colors.primary};
        // }
        path {
          fill: ${({ theme }) => theme.colors.primary};
        }
      `
    }
    return css``
  }};
`

const StyledLabel = styled.div<{ labelSize: LabelSize }>`
  margin-left: 15px;
  ${({ labelSize }) => {
    switch (labelSize) {
      case LabelSize.DEFAULT:
        return css`
          ${({ theme }) => theme.mixins.textNormal};
        `
      case LabelSize.MEDIUM:
        return css`
          font-weight: 400;
          font-size: 18px;
          line-height: 22px;
        `
      default:
        break
    }
  }}
`
export default Radio
