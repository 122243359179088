import React, { createContext, Dispatch, useContext, useReducer } from 'react'
import { TModal } from '../components/Modal'

type State = {
  exports: boolean
  internalization: boolean
  modalShown: boolean
  modalType: TModal
  code: string
  slug: string
  cookieModal: boolean
  cookieModalHeight: number
  ykassaModal: boolean
  cardUUID: string
  commentVideo: {
    isOpen: boolean
    url: string
  }
  previousRoute: string | null
  cancelCurrent?: boolean
  recaptcha: string
}

type Action =
  | { type: 'SET_MODAL'; modalShown: boolean; modalType: TModal }
  | { type: 'SET_RESET_PASSWORD_CODE'; code: string }
  | { type: 'SET_SLUG'; slug: string }
  | { type: 'SET_COOKIE_MODAL'; cookieModal: boolean }
  | { type: 'SET_COOKIE_MODAL_HEIGHT'; cookieModalHeight: number }
  | { type: 'SET_YKASSA_MODAL'; ykassaModal: boolean }
  | { type: 'SET_CARD_UUID'; cardUUID: string }
  | {
      type: 'SET_COMMENT_VIDEO'
      commentVideo: {
        isOpen: boolean
        url: string
      }
    }
  | { type: 'SET_PREVIOUS_ROUTE'; previousRoute: string | null }
  | { type: 'SET_INTERNALIZATION'; internalization: boolean }
  | { type: 'SET_EXPORT'; exports: boolean }
  | { type: 'SET_CANCEL_CURRENT'; cancelCurrent: boolean }
  | { type: 'SET_RECAPTCHA'; key: string }

type AppDispatch = Dispatch<Action>

const AppContext = createContext<State | null>(null)
const AppDispatchContext = createContext<AppDispatch | null>(null)

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_MODAL':
      return {
        ...state,
        modalShown: action.modalShown,
        modalType: action.modalType,
      }
    case 'SET_RESET_PASSWORD_CODE':
      return {
        ...state,
        code: action.code,
      }
    case 'SET_SLUG':
      return {
        ...state,
        slug: action.slug,
      }
    case 'SET_COOKIE_MODAL':
      return {
        ...state,
        cookieModal: action.cookieModal,
      }
    case 'SET_COOKIE_MODAL_HEIGHT':
      return {
        ...state,
        cookieModalHeight: action.cookieModalHeight,
      }
    case 'SET_YKASSA_MODAL':
      return {
        ...state,
        ykassaModal: action.ykassaModal,
      }
    case 'SET_CARD_UUID':
      return {
        ...state,
        cardUUID: action.cardUUID,
      }
    case 'SET_COMMENT_VIDEO':
      return {
        ...state,
        commentVideo: action.commentVideo,
      }
    case 'SET_PREVIOUS_ROUTE':
      return {
        ...state,
        previousRoute: action.previousRoute,
      }
    case 'SET_INTERNALIZATION':
      return {
        ...state,
        internalization: action.internalization,
      }
    case 'SET_EXPORT':
      return {
        ...state,
        exports: action.exports,
      }
    case 'SET_CANCEL_CURRENT':
      return {
        ...state,
        cancelCurrent: action.cancelCurrent,
      }
    case 'SET_RECAPTCHA':
      return {
        ...state,
        recaptcha: action.key,
      }
    default:
      throw new Error('Unhandled action')
  }
}
interface Props {
  children: React.ReactNode
  internalization: boolean
  exports: boolean
  recaptcha?: string
}
const AppProvider = ({
  children,
  internalization,
  exports,
  recaptcha = '',
}: Props) => {
  const [state, dispatch] = useReducer(reducer, {
    exports,
    internalization,
    modalShown: false,
    modalType: TModal.BLANK,
    code: '',
    slug: '',
    cookieModal: true,
    cookieModalHeight: 0,
    ykassaModal: false,
    cardUUID: '',
    commentVideo: {
      url: '',
      isOpen: false,
    },
    previousRoute: null,
    cancelCurrent: false,
    recaptcha,
  })
  return (
    <AppContext.Provider value={state}>
      <AppDispatchContext.Provider value={dispatch}>
        {children}
      </AppDispatchContext.Provider>
    </AppContext.Provider>
  )
}

export const useAppState = () => {
  const state = useContext(AppContext)
  if (!state) throw new Error('Cannot find AppProvider')
  return state
}

export const useAppDispatch = () => {
  const dispatch = useContext(AppDispatchContext)
  if (!dispatch) throw new Error('Cannot find AppDispatchProvider')
  return dispatch
}

export default AppProvider
