import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'next-i18next'
import { useQueryClient } from 'react-query'
/**
 * context
 */
import { useAppState, useAppDispatch } from '../../context/app'
/**
 * api
 */
import { useProductQuery } from '../../api/product'
import {
  useSubscriptions,
  useSubscriptionsMutation,
} from '../../api/subscription'
/**
 * hooks
 */
import useHeaders from '../../hooks/useHeaders'
import usePayments from '../../hooks/usePayments'
/**
 * ui
 */
import Button, { ButtonSize, ButtonStyle } from '../Button'
import { TModal } from './Modal'
import { usePaymentMutation } from '../../api/payment'

const ModalCancelInstallmentPayment: React.FC = () => {
  const { t } = useTranslation('common')
  const queryClient = useQueryClient()
  const { slug } = useAppState()
  const dispatch = useAppDispatch()
  const { headers, status, isLoggedIn } = useHeaders()
  const { handlePayment } = usePayments()
  // queries
  const { data: productData, isLoading: productIsLoading } =
    useProductQuery.useGetProductsSlug(slug, isLoggedIn, headers, {
      enabled: !!slug && isLoggedIn,
    })
  const { data: subscriptionData, isLoading: subscriptionIsLoading } =
    useSubscriptions.useGetSubscriptionBySlug(headers, slug)
  // mutation
  const installmentMutation =
    useSubscriptionsMutation.usePostSubscriptionsInstallment()
  const paymentCancelMutation = usePaymentMutation.usePostPaymentCancel()
  const payMutation = useSubscriptionsMutation.usePostSubscriptionsBySlugPay()

  const setCloseModal = React.useCallback(
    () =>
      dispatch({
        type: 'SET_MODAL',
        modalShown: false,
        modalType: TModal.BLANK,
      }),
    [],
  )

  const continueInstallment = React.useCallback(() => {
    if (subscriptionData && subscriptionData.currentPayment) {
      if (subscriptionData.currentPayment.type === 4) {
        installmentMutation
          .mutateAsync({
            headers,
            slug,
            body: {
              system: subscriptionData.currentPayment.system,
            },
          })
          .then(response => handlePayment(response.data))
          .catch(() => {
            dispatch({
              type: 'SET_MODAL',
              modalShown: false,
              modalType: TModal.BLANK,
            })
          })
      } else {
        payMutation
          .mutateAsync({
            headers,
            slug,
            isAuth: true,
            body: {
              card: null,
              capture: false,
            },
          })
          .then(({ data }) => handlePayment(data))
          .then(() => {
            dispatch({
              type: 'SET_MODAL',
              modalShown: false,
              modalType: TModal.BLANK,
            })
          })
      }
    }
  }, [subscriptionData, headers, slug])

  const changePaymentType = React.useCallback(() => {
    if (subscriptionData && subscriptionData.currentPayment) {
      paymentCancelMutation
        .mutateAsync({
          headers,
          code: subscriptionData!.currentPayment.code,
        })
        .then(async () => {
          return await queryClient.invalidateQueries(['getSubscriptions'])
        })
        .then(() => {
          dispatch({
            type: 'SET_MODAL',
            modalShown: true,
            modalType: TModal.SELECT_PAYMENTS_TYPE,
          })
        })
    }
  }, [subscriptionData, headers, slug])

  if (
    !productData ||
    productIsLoading ||
    status === 'unauthenticated' ||
    !subscriptionData ||
    subscriptionIsLoading
  ) {
    return null
  }

  return (
    <StyledContainer>
      {subscriptionData.currentPayment && (
        <StyledTitle>
          У вас есть не завершенный платеж:{' '}
          {t('intlNumber', {
            val: subscriptionData.currentPayment.amount / 100,
          })}{' '}
          руб. за курс “{productData.title}”, оплата{' '}
          {subscriptionData.currentPayment.type === 4
            ? 'в рассрочку'
            : 'картой'}
          . Что с ним делать?
        </StyledTitle>
      )}
      <StyledButtonContainer>
        <Button
          buttonStyle={ButtonStyle.FILLED}
          size={ButtonSize.BLOCK}
          onClick={continueInstallment}
        >
          Продолжить
        </Button>
      </StyledButtonContainer>

      <Button
        buttonStyle={ButtonStyle.OUTLINED}
        size={ButtonSize.BLOCK}
        onClick={changePaymentType}
      >
        Сменить способ оплаты
      </Button>

      <StyledCancel onClick={setCloseModal}>Отмена</StyledCancel>
    </StyledContainer>
  )
}

export default ModalCancelInstallmentPayment

const StyledContainer = styled.form`
  background: #ffffff;
  box-shadow: 0 4px 54px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: calc(35px + 21px) calc(24px);
  position: relative;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    padding: 68px 97px;
    width: 555px;
  }
`

const StyledTitle = styled.h4`
  font-weight: 600;
  font-size: 24px;
  line-height: 140%;
  text-align: center;
  margin-bottom: 32px;
`
const StyledButtonContainer = styled.div`
  margin-bottom: 10px;
`
const StyledCancel = styled.button`
  width: 100%;
  margin-top: 10px;
  ${({ theme }) => theme.mixins.textButton};
  color: ${({ theme }) => theme.colors.primary};
  padding: 13px 15px;
  text-align: center;
`
