/**
 * vendor
 */
import React, { useState } from 'react'
import { useRouter } from 'next/router'
import { useQueryClient } from 'react-query'
import { differenceInDays, parseISO } from 'date-fns'
/**
 * utils and hooks
 */
import useClientSide from '../useClientSide'
import { useLocalStorage } from 'usehooks-ts'
import { ILocalStorageLinkCardAlert } from '../useLocalStorage/useLocalStorage'
import Subscription from '../../utils/Subscription'
import usePayments from '../../hooks/usePayments'
import useHeaders from '../../hooks/useHeaders'
/**
 * api
 */
import {
  ISubscriptions,
  useSubscriptions,
  useSubscriptionsMutation,
} from '../../api/subscription'
/**
 * constants
 */
import { navigateCourse } from '../../constants/router'
import { TModal } from '../../components/Modal'
/**
 * context
 */
import { useAppDispatch } from '../../context/app'

export interface IUseLinkCard {
  blockPathName?: string[] // принимается pathname
}

function useLinkCard({ blockPathName = [] }: IUseLinkCard = {}) {
  const queryClient = useQueryClient()
  const dispatch = useAppDispatch()
  const router = useRouter()
  const isClientSide = useClientSide()
  const { headers } = useHeaders()
  const { setPayment, handleCurrentPayment, handleAddCard } = usePayments()
  const [localStorage, setLocal] = useLocalStorage<ILocalStorageLinkCardAlert>(
    'willskill_link_card_alert',
    {
      visible: false,
    },
  )
  const [subscriptionModule] = useState(() => new Subscription())
  const [visible, setVisible] = useState<boolean>(false)
  const [errorSubscription, setErrorSubscription] = useState<ISubscriptions>()
  const [errorCount, setErrorCount] = useState<number>(0)
  // mutation
  const resumeMutation =
    useSubscriptionsMutation.usePostSubscriptionsBySlugResume()
  const captureMutation =
    useSubscriptionsMutation.usePostSubscriptionsCardsCapture()
  // react-query
  const {
    data: subscriptionData,
    isLoading,
    isFetching,
  } = useSubscriptions.useGetSubscriptions(headers)
  // check block pathname
  // if вернется true то нужно заблокировать
  const checkBlockPathName = React.useCallback(() => {
    return blockPathName.some(path => path === router.pathname)
  }, [blockPathName, router.pathname])

  // filter error status subscriptions
  React.useEffect(() => {
    subscriptionModule.reset()
    setErrorCount(0)
    if (subscriptionData && !checkBlockPathName()) {
      const errorSubscriptions = subscriptionModule
        .setErrorSubscriptions(subscriptionData)
        .getErrorSubscriptions()
      const errorSubscriptionsCount =
        subscriptionModule.getErrorSubscriptionsCount()
      setErrorCount(errorSubscriptionsCount)

      if (errorSubscriptionsCount >= 1) {
        if (errorSubscriptions['1'].length > 0) {
          setErrorSubscription(errorSubscriptions['1'][0])
        }

        if (
          errorSubscriptions['1'].length === 0 &&
          errorSubscriptions['4'].length > 0
        ) {
          setErrorSubscription(errorSubscriptions['4'][0])
        }
        if (
          errorSubscriptions['1'].length === 0 &&
          errorSubscriptions['4'].length === 0 &&
          errorSubscriptions['3'].length > 0
        ) {
          setErrorSubscription(errorSubscriptions['3'][0])
        }
        // const result = (
        //   Object.keys(errorSubscriptions) as Array<
        //     keyof typeof errorSubscriptions
        //   >
        // )
        //   // здесь нужно фильтровать
        //   // приоритет статус 1 потом 4 потом 3
        //   .filter(status => {
        //     return errorSubscriptions[status].length === 1
        //   })
        // setErrorSubscription(errorSubscriptions[result[0]][0])
      }
      if (errorSubscriptionsCount === 0) {
        setVisible(false)
      }
    }
  }, [
    subscriptionData,
    subscriptionModule,
    isLoading,
    isFetching,
    checkBlockPathName,
  ])
  // change visible alert state
  // when data length === 1
  // 여기서 로컬 스토리지에 엑스를 누른 시간이 있는지 확인한다.
  // 만약 누른 시간이 없다면, 기존 로직을 띄운다.
  // 만약 시간이 있고, 24시간이 지나지 않다면 띄우지 않는다.
  // 만약 시간이 있는데, 24시간이 지났다면 띄운다.
  React.useEffect(() => {
    if (errorSubscription && isClientSide) {
      if (!localStorage.closeDate) {
        setLocal(prev => ({ ...prev, visible: true }))
        setVisible(() => true)
      }
      if (localStorage.closeDate) {
        const diff = differenceInDays(
          new Date(),
          parseISO(localStorage.closeDate),
        )
        if (diff > 0) {
          setLocal(prev => ({ ...prev, visible: true, closeDate: '' }))
          setVisible(() => true)
        }
      }
    }
  }, [errorSubscription, isClientSide])

  // 스토리지에 닫한 시간을 기록
  const onClose = React.useCallback(() => {
    if (errorSubscription && isClientSide) {
      setLocal(prev => ({
        ...prev,
        visible: false,
        closeDate: JSON.stringify(new Date()),
      }))
      setVisible(() => false)
    }
  }, [errorSubscription, isClientSide])

  const handleSubmit = React.useCallback(() => {
    if (errorSubscription) {
      if (errorSubscription.status === 1) {
        dispatch({
          type: 'SET_SLUG',
          slug: errorSubscription.slug,
        })
        if (errorSubscription.currentPayment) {
          dispatch({
            type: 'SET_MODAL',
            modalShown: true,
            modalType: TModal.CANCEL_INSTALLMENT_PAYMENT,
          })
        } else {
          dispatch({
            type: 'SET_MODAL',
            modalShown: true,
            modalType: TModal.SELECT_CONTINUE_OR_INSTALLMENT_PAYMENT,
          })
        }
        // handleCurrentPayment({
        //   slug: errorSubscription.slug,
        //   currentPayment: errorSubscription.currentPayment,
        // })
      }
      if (errorSubscription.status === 3) {
        resumeMutation
          .mutateAsync({ headers, slug: errorSubscription.slug })
          .then(async () => {
            await queryClient.invalidateQueries(['getSubscriptions'])
            return router.push(navigateCourse)
          })
      }
      if (errorSubscription.canCardBeAttached) {
        handleAddCard({ slug: errorSubscription.slug })
      }
    }
  }, [
    errorSubscription,
    headers,
    resumeMutation,
    router,
    queryClient,
    captureMutation,
    setPayment,
    handleCurrentPayment,
  ])

  const handleErrorAmount = React.useCallback(() => {
    if (errorSubscription) {
      if (errorCount > 1) {
        return router.push(navigateCourse)
      }
      if (errorCount === 1) {
        handleSubmit()
      }
    }
  }, [errorCount, router, errorSubscription, handleSubmit])

  return {
    visible,
    subscription: errorSubscription,
    setVisible: onClose,
    handleSubmit: handleErrorAmount,
  }
}

export default useLinkCard
