import React, { useEffect } from 'react'
import { SubmitHandler, useForm as useHookForm } from 'react-hook-form'
import { useRouter } from 'next/router'
import { signIn } from 'next-auth/react'
import debounce from 'lodash/debounce'
import { useQueryClient } from 'react-query'
import { useTranslation } from 'next-i18next'
import { useLocalStorage } from 'usehooks-ts'
/**
 * ui
 */
import { notify, ToastIcon } from '../../components/Toast'
/**
 * hooks and utils
 */
import usePayments from '../../hooks/usePayments'
import useHeaders from '../../hooks/useHeaders'
import useRecaptcha from '../../hooks/useRecaptcha'
/**
 * api
 */
import {
  TInitialPayType,
  useSubscriptions,
  ISubscriptions,
} from '../../api/subscription'
import { useProductMutation } from '../../api/product'
/**
 * constants
 */
import { ALERT_CLOSE_WAIT, DEBOUNCE_WAIT } from '../../constants'
import { TModal } from '../../components/Modal'
/**
 * context
 */
import { useAppDispatch, useAppState } from '../../context/app'

type TInstallment = 'installment'

export type TType = TInitialPayType | TInstallment

type TFormValue = {
  email: string
  subscriptionType: TType
}

interface Props {
  slug: string
  accessType: number
  subscriptionType?: TType
}

export interface ILocalStorageSelectedPayments {
  [slug: string]: {
    subscriptionType: TType
  }
}
const useForm = ({ slug, accessType, subscriptionType }: Props) => {
  const { recaptcha } = useAppState()
  const [local, setLocal] = useLocalStorage<ILocalStorageSelectedPayments>(
    'willskill_selectedPayments',
    {},
  )
  const { t } = useTranslation('common')
  const router = useRouter()
  const { handleRecaptcha } = useRecaptcha()
  const {
    handleAccessType,
    handleAccessTypeFirstRegistration,
    handleCurrentPayment,
  } = usePayments()
  const queryClient = useQueryClient()
  const dispatch = useAppDispatch()
  const { isLoggedIn, status, headers } = useHeaders()
  const { data: subscriptionsData } =
    useSubscriptions.useGetSubscriptions(headers)

  const [isOrderCoursePage, setIsOrderCoursePage] = React.useState(false)
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    setError,
    formState: { isValid, errors, isSubmitting },
  } = useHookForm<TFormValue>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      email: '',
      subscriptionType: subscriptionType || 'firstFree',
    },
  })
  const subscriptionTypeValue = watch('subscriptionType')

  useEffect(() => {
    register('subscriptionType', {
      required: true,
    })
  }, [register])

  useEffect(() => {
    if (router.pathname === '/[category]/[productSlug]/order/course') {
      setValue('subscriptionType', 'course')
      setIsOrderCoursePage(true)
    }
  }, [router, setValue])

  // mutations
  const productSubscribeMutation =
    useProductMutation.usePostProductSubscribeMutation()
  const productRegistrationMutation =
    useProductMutation.usePostProductRegistrationMutation()

  const handleRegistration = (body: any) => {
    setValue('email', '')
    return productRegistrationMutation
      .mutateAsync(body)
      .then(async response => {
        if (response.data.redirectUrl) {
          // const split = response.data.redirectUrl.split('?')
          // console.log('split', split)
          // const mtoken = split[1].replace('mtoken=', '')
          // console.log('mtoken', mtoken)
          // console.log('router.asPath', router.asPath)
          // return router.replace(
          //   { pathname: router.asPath, query: { mtoken } },
          //   undefined,
          //   { shallow: true },
          // )
          return
        }
        if (response.data.token)
          return await signIn('loginwithsubscription', {
            redirect: false,
            token: response.data.token,
            recaptcha: body.recaptcha,
          })
      })
      .then(async () => {
        await queryClient.invalidateQueries([
          'getProductsSlug',
          slug,
          isLoggedIn,
        ])
        if (body.subscriptionType === 'installment') {
          dispatch({
            type: 'SET_SLUG',
            slug,
          })
          dispatch({
            type: 'SET_MODAL',
            modalShown: true,
            modalType: TModal.INSTALLMENT,
          })
        }
        if (body.subscriptionType !== 'installment') {
          await handleAccessTypeFirstRegistration({
            accessType,
            slug,
          })
        }
      })
      .catch(err => {
        setValue('email', body.email)
        if (err.response?.data?.formValidationErrors?.email) {
          setError('email', {
            type: 'manual',
            message:
              err.response?.data?.formValidationErrors?.email[0].message ?? '',
          })
        }

        if (err.response?.data?.formValidationErrors?.recaptcha) {
          setError('email', {
            type: 'manual',
            message:
              err.response?.data?.formValidationErrors?.recaptcha[0].message ??
              '',
          })
        }
      })
  }

  const onSubmit: SubmitHandler<TFormValue> = async data => {
    setLocal({
      ...local,
      [slug]: {
        subscriptionType: subscriptionTypeValue,
      },
    })
    // авторизированный
    if (subscriptionsData) {
      // Checks что платная подписка есть или нет
      const checkActualSubscription = (subscription: ISubscriptions) =>
        subscription.accessType === 1 && subscription.status === 2
      const canProceed = subscriptionsData.some(checkActualSubscription)
      if (canProceed) {
        notify({
          icon: ToastIcon.ERROR,
          autoClose: ALERT_CLOSE_WAIT,
          label: t('notify.actualSubscription'),
        })
      }
      if (!canProceed) {
        return productSubscribeMutation
          .mutateAsync({
            headers,
            slug,
            type:
              data.subscriptionType === 'installment'
                ? 'course'
                : data.subscriptionType,
            isAuth: true,
          })
          .then(async response => {
            await queryClient.invalidateQueries([
              'getProductsSlug',
              slug,
              isLoggedIn,
            ])
            if (data.subscriptionType === 'installment') {
              dispatch({
                type: 'SET_SLUG',
                slug,
              })
              dispatch({
                type: 'SET_MODAL',
                modalShown: true,
                modalType: TModal.INSTALLMENT,
              })
            }
            if (data.subscriptionType !== 'installment') {
              handleAccessType({
                accessType,
                slug,
                currentPayment: response.data.currentPayment,
              })
            }
          })
          .catch(err => {
            notify({
              icon: ToastIcon.ERROR,
              autoClose: ALERT_CLOSE_WAIT,
              label: err.response?.data?.error?.message ?? '',
            })
          })
      }
    }
    // не авторизованного
    if (!isLoggedIn) {
      if (!recaptcha) {
        const body = {
          email: data.email,
          slug,
          type:
            data.subscriptionType === 'installment'
              ? 'course'
              : data.subscriptionType,
          recaptcha: '',
          subscriptionType: data.subscriptionType,
        }
        return handleRegistration(body)
      }
      if (recaptcha) {
        const token = await handleRecaptcha()
        if (!token) {
          throw new Error('Recaptcha is not valid yet')
        }
        const body = {
          email: data.email,
          slug,
          type:
            data.subscriptionType === 'installment'
              ? 'course'
              : data.subscriptionType,
          recaptcha: token,
          subscriptionType: data.subscriptionType,
        }
        return handleRegistration(body)
      }
    }
  }
  const handler = debounce(() => {
    handleSubmit(onSubmit)()
  }, DEBOUNCE_WAIT)

  return {
    formState: { isValid, errors, isSubmitting },
    register,
    watch,
    handler,
    isOrderCoursePage,
    handleCurrentPayment,
    status,
    setValue,
  }
}

export default useForm
