import React from 'react'
import styled from 'styled-components'
import dynamic from 'next/dynamic'
/**
 * context
 */
import { useAppState, useAppDispatch } from '../../context/app'
/**
 * hooks
 */
import useClickOutside from '../../hooks/useClickOutside'

const KinescopePlayer = dynamic(() => import('../../components/Player'), {
  ssr: false,
})

const ModalCommentVideo = () => {
  const dispatch = useAppDispatch()
  const { commentVideo } = useAppState()
  const modalRef = React.useRef<HTMLIFrameElement>(null)
  const modalFrameRef = React.useRef<HTMLDivElement>(null)

  useClickOutside([modalFrameRef], () => {
    setModal()
  })

  const setModal = () => {
    dispatch({
      type: 'SET_COMMENT_VIDEO',
      commentVideo: {
        url: '',
        isOpen: false,
      },
    })
  }

  React.useEffect(() => {
    return () => {
      dispatch({
        type: 'SET_COMMENT_VIDEO',
        commentVideo: {
          url: '',
          isOpen: false,
        },
      })
    }
  }, [dispatch])

  const onPlaying = React.useCallback(() => {
    if (modalRef.current) {
      // @ts-ignore
      modalRef.current.setVideoQuality(360)
    }
  }, [])

  if (!commentVideo.isOpen) {
    return null
  }

  return (
    <StyledWrapper>
      <StyledModalBody>
        <div ref={modalFrameRef}>
          {!!commentVideo.url && (
            <KinescopePlayer
              forwardRef={modalRef}
              videoId={commentVideo.url}
              controls
              autoPlay
              onPlaying={onPlaying}
            />
          )}
        </div>
      </StyledModalBody>
    </StyledWrapper>
  )
}

export default ModalCommentVideo

const StyledWrapper = styled.div`
  top: 0;
  left: 0;
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgba(237, 242, 237, 0.6);
  backdrop-filter: blur(3px);
  display: flex;
  align-items: center;
  z-index: 9990;
`

const StyledModalBody = styled.div`
  margin: 0 auto;
  iframe {
    width: 100%;
    height: 558px;
  }
`
