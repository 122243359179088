import React from 'react'
import styled from 'styled-components'
import { SubmitHandler, useForm } from 'react-hook-form'
import { signIn } from 'next-auth/react'
import { useRouter } from 'next/router'
import { useTranslation, Trans } from 'next-i18next'
/**
 * context
 */
import { useAppDispatch, useAppState } from '../../context/app'
/**
 * ui
 */
import Input, { InputStyle } from '../Input'
import Button, { ButtonSize, ButtonStyle } from '../Button'
import { TModal } from '../Modal'
/**
 * hook
 * */
import useRecaptcha from '../../hooks/useRecaptcha'

type TFormValue = {
  email: string
  password: string
}

const ModalLogin: React.FC = () => {
  const { previousRoute, recaptcha } = useAppState()
  const { t } = useTranslation('components.modal')
  const { handleRecaptcha } = useRecaptcha()
  const router = useRouter()
  const {
    register,
    handleSubmit,
    reset,
    setError,
    setValue,
    formState: { isValid, errors, isSubmitting, isDirty },
  } = useForm<TFormValue>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
    },
  })
  const dispatch = useAppDispatch()

  const setRegistrationModal = (): void =>
    dispatch({
      type: 'SET_MODAL',
      modalShown: true,
      modalType: TModal.REGISTRATION,
    })
  const setForgotPasswordModal = (): void =>
    dispatch({
      type: 'SET_MODAL',
      modalShown: true,
      modalType: TModal.FORGOT,
    })
  const handleLogin = (data: any) => {
    reset({
      email: '',
      password: '',
    })
    return signIn('commonLogin', {
      redirect: false,
      ...data,
    }).then(res => {
      if (previousRoute && res && !res.error) {
        dispatch({
          type: 'SET_MODAL',
          modalShown: false,
          modalType: TModal.BLANK,
        })
        dispatch({
          type: 'SET_PREVIOUS_ROUTE',
          previousRoute: null,
        })

        if (previousRoute.search(/https:\/\/willskill.ru/gi) > -1) {
          return router.push(previousRoute)
        }
      }
      // @ts-ignore
      if (!previousRoute && res && !res.error) {
        dispatch({
          type: 'SET_MODAL',
          modalShown: false,
          modalType: TModal.BLANK,
        })
        if (router.query.modal) {
          delete router.query.modal
          return router.push(router)
        }
      }
      //@ts-ignore
      if (res && res.error) {
        setValue('email', data.email)
        setValue('password', data.password)
        setError('email', {
          type: 'manual',
          //@ts-ignore
          message: res.error ?? '',
        })
      }
    })
  }
  const onSubmit: SubmitHandler<TFormValue> = async data => {
    if (!recaptcha) {
      return handleLogin({
        ...data,
        recaptcha: '',
      })
    }
    if (recaptcha) {
      const token = await handleRecaptcha()
      if (!token) {
        throw new Error('Recaptcha is not valid yet')
      }

      return handleLogin({
        ...data,
        recaptcha: token,
      })
    }
  }
  return (
    <StyledContainer onSubmit={handleSubmit(onSubmit)}>
      <StyledTitle>{t('ModalLogin.title')}</StyledTitle>
      <StyledInputWrapper>
        <Input
          errorMessage={errors?.email?.message}
          inputStyle={InputStyle.DEFAULT}
          type='email'
          label={t('ModalLogin.email')}
          {...register('email', {
            required: {
              value: true,
              message: 'поле должно быть заполнено',
            },
          })}
        />
      </StyledInputWrapper>
      <StyledInputWrapper>
        <Input
          errorMessage={errors?.password?.message}
          inputStyle={InputStyle.DEFAULT}
          type='password'
          label={t('ModalLogin.password')}
          {...register('password', {
            required: {
              value: true,
              message: 'поле должно быть заполнено',
            },
          })}
        />
      </StyledInputWrapper>
      <StyledForgotPassword onClick={setForgotPasswordModal}>
        {t('ModalLogin.forgotYourPassword')}
      </StyledForgotPassword>

      <Button
        buttonStyle={ButtonStyle.FILLED}
        size={ButtonSize.BLOCK}
        disabled={!isValid || isSubmitting || !isDirty}
        type='submit'
      >
        {t('ModalLogin.login')}
      </Button>

      <StyledHr />
      <StyledRegistration>
        <Trans
          t={t}
          i18nKey='ModalLogin.yet'
          components={{
            '1': <StyledRegistrationLink onClick={setRegistrationModal} />,
          }}
        />
      </StyledRegistration>
    </StyledContainer>
  )
}

export default ModalLogin

const StyledContainer = styled.form`
  background: #ffffff;
  box-shadow: 0 4px 54px rgba(0, 0, 0, 0.05);
  width: 100%;
  border-radius: 5px;
  padding: 35px 24px;
  max-width: 100%;
  overflow: hidden;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    padding: 68px 97px;
    width: 555px;
  }
`

const StyledTitle = styled.h4`
  ${({ theme }) => theme.mixins.H4};
  text-align: center;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    ${({ theme }) => theme.mixins.H3};
  }
`

const StyledInputWrapper = styled.div`
  margin-top: 24px;
`

const StyledForgotPassword = styled.div`
  margin-top: 14px;
  text-align: right;
  margin-bottom: 28px;
  color: ${({ theme }) => theme.colors.primary};
  text-decoration-line: underline;
  cursor: pointer;
  ${({ theme }) => theme.mixins.textSmall};
`

const StyledHr = styled.hr`
  border-top: 1px solid ${({ theme }) => theme.colors.greySecondary};
  margin: 56px 0;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    margin: 31px 0;
  }
`

const StyledRegistration = styled.div`
  text-align: center;
  font-weight: 400;
  line-height: 16.07px;
  font-size: 0.813rem;
`

const StyledRegistrationLink = styled.span`
  cursor: pointer;
  ${({ theme }) => theme.mixins.textButton};
  color: ${({ theme }) => theme.colors.primary};
`
