import Script from 'next/script'
import getConfig from 'next/config'
import { ITrackers } from 'src/api/external'
import { useSessionStorage } from 'usehooks-ts'

const {
  publicRuntimeConfig: {
    YANDEX_METRIKA = null,
    TAG_MANAGER = null,
    VK_PIXEL = null,
  },
} = getConfig()

const Scripts = () => {
  const [trackerSession] = useSessionStorage<ITrackers>('trackers', {})
  return (
    <>
      {/*  Google Tag Manager */}
      {TAG_MANAGER && (
        <Script
          id='willskill-google-tag-manager'
          strategy='afterInteractive'
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                      })(window,document,'script','willskillGTM','${TAG_MANAGER}');`,
          }}
        />
      )}
      {trackerSession['google_tag_manager'] && (
        <Script
          id='offerrum-google-tag-manager'
          strategy='afterInteractive'
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                      })(window,document,'script','offerrumGTM','${trackerSession['google_tag_manager']?.tag}');`,
          }}
        />
      )}
      {/* End Google Tag Manager */}
      {/*  Yandex Metrika */}
      {YANDEX_METRIKA && trackerSession['yandex_metrika'] && (
        <Script
          id='yandex-metrika'
          strategy='afterInteractive'
          dangerouslySetInnerHTML={{
            __html: `(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
            m[i].l=1*new Date();
            for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
            k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
            (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
            ym(${trackerSession['yandex_metrika'].metrika_id}, "init", {});
            ym(${YANDEX_METRIKA}, "init", {clickmap: true, webvisor: true});
            `,
          }}
        />
      )}
      {YANDEX_METRIKA && !trackerSession['yandex_metrika'] && (
        <Script
          id='yandex-metrika'
          strategy='afterInteractive'
          dangerouslySetInnerHTML={{
            __html: `(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
              m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
              (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym"); ym(${YANDEX_METRIKA}, "init", {clickmap: true, webvisor: true});`,
          }}
        />
      )}
      {/*  End Yandex Metrika */}
      {/* vk pixel */}
      {VK_PIXEL && (
        <Script
          id='vk-pixel'
          strategy='afterInteractive'
          dangerouslySetInnerHTML={{
            __html: ` var _tmr = window._tmr || (window._tmr = []); _tmr.push({id: "${VK_PIXEL}", type: "pageView", start: (new Date()).getTime()});
            (function (d, w, id) { if (d.getElementById(id)) return; var ts = d.createElement("script"); 
            ts.type = "text/javascript"; 
            ts.async = true; ts.id = id; ts.src = "https://top-fwz1.mail.ru/js/code.js"; 
            var f = function () {var s = d.getElementsByTagName("script")[0]; s.parentNode.insertBefore(ts, s);}; 
            if (w.opera == "[object Opera]") { d.addEventListener("DOMContentLoaded", f, false); } else { f(); } })(document, window, "tmr-code");
              `,
          }}
        />
      )}
      {trackerSession['vk'] && (
        <Script
          id='vk-pixel-offerrum'
          strategy='afterInteractive'
          dangerouslySetInnerHTML={{
            __html: ` var _tmr = window._tmr || (window._tmr = []); _tmr.push({id: "${trackerSession['vk'].vk_id}", type: "pageView", start: (new Date()).getTime()});
            (function (d, w, id) { if (d.getElementById(id)) return; var ts = d.createElement("script"); 
            ts.type = "text/javascript"; 
            ts.async = true; ts.id = id; ts.src = "https://top-fwz1.mail.ru/js/code.js"; 
            var f = function () {var s = d.getElementsByTagName("script")[0]; s.parentNode.insertBefore(ts, s);}; 
            if (w.opera == "[object Opera]") { d.addEventListener("DOMContentLoaded", f, false); } else { f(); } })(document, window, "tmr-code");
              `,
          }}
        />
      )}
      <Script
        id='cloudpayments.js'
        src='https://widget.cloudpayments.ru/bundles/cloudpayments.js'
        strategy='lazyOnload'
      />
      <Script
        id='checkout-widget.js'
        src='https://yookassa.ru/checkout-widget/v1/checkout-widget.js'
        strategy='lazyOnload'
      />
      <Script
        id='tinkoff.js'
        src='https://forma.tinkoff.ru/static/onlineScript.js'
        strategy='lazyOnload'
      />
    </>
  )
}

export default Scripts
