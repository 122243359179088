import React, { useEffect } from 'react'
import Image from 'next/image'
import styled, { css } from 'styled-components'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'next-i18next'
/**
 * context
 */
import { useAppState } from '../../context/app'
/**
 * ui
 */
import Button, { ButtonSize, ButtonStyle } from '../Button'
import Radio from '../Radio'
/**
 * hook
 */
import useHeaders from '../../hooks/useHeaders'
import useClientSide from '../../hooks/useClientSide'
/**
 * api
 */
import { useSubscriptions } from '../../api/subscription'

type TFormValue = {
  theme: 'white' | 'black' | 'green'
  download: number
}

const ModalSelectCertification: React.FC = () => {
  const { t } = useTranslation('components.modal')
  const { headers } = useHeaders()
  const isClientSide = useClientSide()
  const { slug } = useAppState()
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { isSubmitting },
  } = useForm<TFormValue>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      theme: 'white',
      download: 0,
    },
  })
  // watch value in react hook form
  const themeValue = watch('theme')
  const downloadValue = watch('download')

  // to register state of useForm
  useEffect(() => {
    register('theme')
    register('download')
  }, [register])

  const { refetch } = useSubscriptions.useGetCertification({
    headers,
    variant: themeValue,
    download: downloadValue,
    slug,
  })

  const onSubmit: SubmitHandler<TFormValue> = () => {
    // after response set download data
    return refetch().then(res => {
      if (res.data && res.data.url && isClientSide)
        window.location.href = res.data.url
    })
  }

  return (
    <StyledContainer onSubmit={handleSubmit(onSubmit)}>
      <StyledTitle>{t('ModalSelectCertification.title')}</StyledTitle>
      {/* выборы темы */}
      <StyledSelect>
        <StyledSelectItem>
          <StyledSelectItemImage selected={themeValue === 'white'}>
            <Image
              alt='certificate'
              src='/assets/images/certificate-white-blank.svg'
              width={223}
              height={158}
            />
          </StyledSelectItemImage>
          <Radio
            label={t('ModalSelectCertification.whiteTheme')}
            checked={themeValue === 'white'}
            onClick={() => {
              setValue('theme', 'white')
            }}
          />
        </StyledSelectItem>
        <StyledSelectItem>
          <StyledSelectItemImage selected={themeValue === 'black'}>
            <Image
              alt='certificate'
              src='/assets/images/certificate-black-blank.svg'
              width={223}
              height={158}
            />
          </StyledSelectItemImage>
          <Radio
            label={t('ModalSelectCertification.blackTheme')}
            checked={themeValue === 'black'}
            onClick={() => {
              setValue('theme', 'black')
            }}
          />
        </StyledSelectItem>
        <StyledSelectItem>
          <StyledSelectItemImage selected={themeValue === 'green'}>
            <Image
              alt='certificate'
              src='/assets/images/certificate-green-blank.svg'
              width={223}
              height={158}
            />
          </StyledSelectItemImage>
          <Radio
            label={t('ModalSelectCertification.colorTheme')}
            checked={themeValue === 'green'}
            onClick={() => {
              setValue('theme', 'green')
            }}
          />
        </StyledSelectItem>
      </StyledSelect>

      <Button
        buttonStyle={ButtonStyle.FILLED}
        size={ButtonSize.PADDING}
        afterIcon='download'
        iconSize='15'
        disabled={isSubmitting}
        onClick={() => {
          setValue('download', 1)
          handleSubmit(onSubmit)()
        }}
      >
        {t('ModalSelectCertification.download')}
      </Button>
    </StyledContainer>
  )
}

export default ModalSelectCertification

const StyledContainer = styled.div`
  background: #fff;
  border-radius: 20px;
  width: 100%;
  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.flexDirection('column')}
  ${({ theme }) => theme.mixins.alignItems('center')}
  padding-bottom: 99px;
  overflow: hidden;
  max-width: 300px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    padding-bottom: 74px;
    max-width: 637px;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    max-width: 909px;
  }
`

const StyledTitle = styled.h4`
  padding-top: 65px;
  ${({ theme }) => theme.mixins.H4};
  text-align: center;
  margin-bottom: 50px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    ${({ theme }) => theme.mixins.H3};
  }
`

const StyledSelect = styled.div`
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  padding-left: 24px;
  &::-webkit-scrollbar {
    display: none;
  }
  margin-bottom: 40px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    padding-left: 40px;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    padding-left: 92px;
  }
`

const StyledSelectItem = styled.div`
  display: inline-block;
  width: 166px;
  &:not(:last-child) {
    margin-right: 30px;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    margin-right: 54px;
    width: 223px;
  }
`

const StyledSelectItemImage = styled.div<{ selected: boolean }>`
  position: relative;
  width: 100%;
  filter: drop-shadow(0px 4px 13px rgba(0, 0, 0, 0.15));
  margin-bottom: 26px;
  &:hover {
    filter: drop-shadow(0px 13px 33px rgba(24, 168, 102, 0.15));
  }
  ${({ selected }) => {
    if (selected) {
      return css`
        filter: drop-shadow(0px 13px 33px rgba(24, 168, 102, 0.15));
      `
    }
  }}
`
