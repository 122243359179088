import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'next-i18next'
/**
 * context
 */
import { useAppState, useAppDispatch } from '../../context/app'
/**
 * ui
 */
import Button, { ButtonSize, ButtonStyle } from '../Button'
import { TModal } from './Modal'
/**
 * api
 */
import {
  useSubscriptions,
  useSubscriptionsMutation,
} from '../../api/subscription'
import { usePaymentMutation } from '../../api/payment'
import { useCardQuery } from '../../api/card'
/**
 * utils
 */
import usePayments from '../../hooks/usePayments'
import useHeaders from '../../hooks/useHeaders'

const ModalCancelPayment: React.FC = () => {
  const { slug } = useAppState()
  const dispatch = useAppDispatch()
  const { t } = useTranslation('components.modal')
  const { headers, isLoggedIn, status } = useHeaders()
  const { handlePayment } = usePayments()
  // queries
  const { data, isLoading } = useSubscriptions.useGetSubscriptionBySlug(
    headers,
    slug,
  )
  const { data: cardData, isLoading: cardIsLoading } =
    useCardQuery.useGetCards(headers)
  // mutations
  const paymentCancelMutation = usePaymentMutation.usePostPaymentCancel()
  const payMutation = useSubscriptionsMutation.usePostSubscriptionsBySlugPay()

  if (
    !data ||
    isLoading ||
    !cardData ||
    cardIsLoading ||
    !isLoggedIn ||
    status === 'unauthenticated'
  ) {
    return null
  }
  const onClickCancel = () => {
    if (data.currentPayment) {
      paymentCancelMutation
        .mutateAsync({
          headers,
          code: data!.currentPayment.code,
        })
        .then(() => {
          dispatch({
            type: 'SET_MODAL',
            modalShown: true,
            modalType: TModal.SELECT_PAYMENTS_TYPE,
          })
        })
    }
  }
  const onClickPay = () => {
    payMutation
      .mutateAsync({
        headers,
        slug,
        isAuth: true,
        body: {
          card: null,
          capture: false,
        },
      })
      .then(({ data }) => handlePayment(data))
      .then(() => {
        dispatch({
          type: 'SET_MODAL',
          modalShown: false,
          modalType: TModal.BLANK,
        })
      })
  }
  return (
    <StyledContainer>
      <StyledTitle>{t('ModalCancelPayment.title')}</StyledTitle>

      <StyledButtonContainer>
        <Button
          buttonStyle={ButtonStyle.FILLED}
          size={ButtonSize.BLOCK}
          type='button'
          onClick={onClickPay}
        >
          {t('ModalCancelPayment.yes')}
        </Button>
        {data.currentPayment && data.currentPayment.canBeCancelled && (
          <Button
            buttonStyle={ButtonStyle.OUTLINED}
            size={ButtonSize.BLOCK}
            type='button'
            onClick={onClickCancel}
          >
            {t('ModalCancelPayment.cancel')}
          </Button>
        )}
      </StyledButtonContainer>
    </StyledContainer>
  )
}

export default ModalCancelPayment

const StyledContainer = styled.div`
  background: #ffffff;
  box-shadow: 0 4px 54px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.flexDirection('column')}
  ${({ theme }) => theme.mixins.alignItems('center')}
  width: 100%;
  padding: calc(35px + 35px) calc(24px + 24px) calc(35px + 33px)
    calc(24px + 24px);
  max-width: 100%;
  overflow: hidden;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    padding: 68px 97px;
    max-width: 555px;
  }
`

const StyledTitle = styled.h4`
  ${({ theme }) => theme.mixins.H4};
  text-align: center;
  margin-bottom: 22px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    ${({ theme }) => theme.mixins.H3};
    margin-bottom: 28px;
  }
`

const StyledButtonContainer = styled.div`
  width: 100%;
  ${({ theme }) => theme.mixins.flexbox()}
  gap: 15px;
`
