import React from 'react'
import styled, { css } from 'styled-components'

export enum AvatarSize {
  XSMALL = 'xsmall',
  SMALL = 'small',
  MEDIUM = 'medium',
  MIDDLE = 'middle',
  LARGE = 'large',
  XLARGE = 'xlarge',
}

interface Style {
  size: AvatarSize
  image?: string
  checked?: boolean
}

interface Props extends Style {
  onClick?: (
    e: React.MouseEvent<HTMLImageElement> | React.TouchEvent<HTMLImageElement>,
  ) => void
  children?: React.ReactNode
}

const Avatar = React.forwardRef<HTMLImageElement, Props>(
  ({ size, image, checked, onClick, children }, ref) => {
    return (
      <StyledWrapper
        size={size}
        image={image}
        checked={checked}
        onClick={onClick}
        ref={ref}
        src={image ? image : '/assets/images/avatar.svg'}
        alt='avatar'
      >
        {children}
      </StyledWrapper>
    )
  },
)

const StyledWrapper = styled.img<Style>`
  border-radius: 100%;
  ${({ theme }) => theme.mixins.transition('all 0.275s')}
  ${({ theme }) => theme.mixins.flexShrink()}
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  ${({ size, theme }) => {
    if (size === AvatarSize.XSMALL) {
      return css`
        width: 40px;
        height: 40px;
      `
    }
    if (size === AvatarSize.SMALL) {
      return css`
        width: 46px;
        height: 45px;
      `
    }
    if (size === AvatarSize.MEDIUM) {
      return css`
        width: 75px;
        height: 75px;
      `
    }
    if (size === AvatarSize.MIDDLE) {
      return css`
        width: 150px;
        height: 150px;
      `
    }
    if (size === AvatarSize.LARGE) {
      return css`
        z-index: 1;
        width: 158px;
        height: 158px;

        @media ${theme.devices.mobileLgUp} {
          width: 278px;
          height: 278px;
        }
      `
    }
    if (size === AvatarSize.XLARGE) {
      return css`
        z-index: 1;
        width: 113.54px;
        height: 114px;

        @media ${theme.devices.mobileLgUp} {
          width: 165px;
          height: 165.66px;
        }
      `
    }
  }}
  ${({ checked }) => {
    if (checked) {
      return css`
        transform: scale(1.05);
      `
    }
    return css``
  }}
`
Avatar.displayName = 'Avatar'
export default Avatar
